import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('usuario', 'usuario');

const usuarioActions = {
  ...genericActions(basePath),
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  buscarUsuarios: { method: 'GET', url: `${basePath}/users` },
  ativarRepresentante: { method: 'PUT', url: `${basePath}/representante/{id}/true` },
  desativarRepresentante: { method: 'PUT', url: `${basePath}/representante/{id}/false` },
  listarSuperior: { method: 'GET', url: `${basePath}/superior` },
  listarAtivosPorEmail: { method: 'GET', url: `${basePath}?email={email}&indAtivo=true&colunaOrdenacao=email` },
  listarAtivosPorEmailNaoConfigurados: { method: 'GET', url: `${basePath}?filter={email}&colunaOrdenacao=email&naoConfiguradosTradelinks=true` },
  listarNaoConfigurados: { method: 'GET', url: `${basePath}?filter={filter}&naoConfiguradosTradelinks=true` },
  informacao: { method: 'GET', url: `${basePath}/informacao` },
  tipos: { method: 'GET', url: `${basePath}/tipos` },
  listarConfiguracao: { method: 'GET', url: `${basePath}/configuracao` },
  atualizarConfiguracao: { method: 'PUT', url: `${basePath}/configuracao` },
  redefinirSenha: { method: 'PUT', url: `${basePath}/senha/redefinicao/usuario/{idUsuario}` },
  listarAtivosResumido: { method: 'GET', url: `${basePath}/resumido?nome={nome}&indAtivo=true` },
};

export default (resource) => resource(`${basePath}`, {}, usuarioActions);
