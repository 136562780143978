import { buscarHoldings } from '../../../../../tradelinks_produto/tradelinks_view_core/src/common/resources/liquidacao/pagamento';
import { buscarClientes, buscarContratos } from '../../../common/resources/suzano-planejamento-acao-cadastro';

export default {
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoAcaoResourceSuzano: this.$api.suzanoPlanejamentoAcaoCadastro(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      tipoContratoResource: this.$api.tipoContrato(this.$resource),
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'status_pagamento',
            nomCampoId: 'status_pagamento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$t('status_entidade_pagamento.aguardando_pagamento'),
                  valor: 'AGUARDANDO_PAGAMENTO',
                },
                {
                  texto: this.$tc('status_entidade_pagamento.aprovado', 1),
                  valor: 'APROVADO',
                },
                {
                  texto: this.$tc('status_entidade_pagamento.programado', 1),
                  valor: 'PROGRAMADO',
                },
                {
                  texto: this.$tc('status_entidade_pagamento.liquidado', 1),
                  valor: 'LIQUIDADO',
                },
                {
                  texto: this.$t('status_entidade_pagamento.bloqueado'),
                  valor: 'BLOQUEADO',
                },
                {
                  texto: this.$t('status_entidade_pagamento.cancelado'),
                  valor: 'CANCELADO',
                },
                {
                  texto: this.$t('status_entidade_pagamento.pendente'),
                  valor: 'PENDENTE',
                },
                {
                  texto: this.$t('status_entidade_pagamento.em_cadastro'),
                  valor: 'EM_CADASTRO',
                },
                {
                  texto: this.$t('status_entidade_pagamento.aguardando_aprovacao'),
                  valor: 'AGUARDANDO_APROVACAO',
                },
                {
                  texto: this.$t('status_entidade_pagamento.em_analise'),
                  valor: 'EM_ANALISE',
                },
              ],
            },
          },
          {
            labelCampo: 'mes_criacao_pagamento',
            nomCampo: this.$t('label.mes_criacao_pagamento'),
            tipoCampo: 'DATA',
            desAtributos: {
              tipoSelecao: 'month',
              formatoData: 'YYYY-MM',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            filter: () => true,
            async: {
              fetchFunction: (autocomplete) => buscarHoldings({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'cliente_gerador',
            nomCampoId: 'id_cliente_gerador',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarClientes({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'contrato',
            nomCampoId: 'id_contrato',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarContratos({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomContrato',
            },
          },
          {
            labelCampo: 'cliente_recebedor',
            nomCampoId: 'id_cliente_recebedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResourceSuzano.buscarClientesPagadores({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'tipo_de_verba',
            nomCampoId: 'id_tipo_verba',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.orcamentoTipoVerbaResource
                .buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
        ],
      },
    };
  },
};
