<template>
  <v-row align-left >
    <v-col cols="2" class="pl-0">
      <v-switch
        :id="`item_complemento_informacao_${index}`"
        :disabled="!(planejamentoInterno
         || apuracaoInterno
          || liquidacaoInterno)
           || indHabilitaAprovacaoApuracao"
        v-model="complementoInformacao" />
    </v-col>
    <v-col>
      <v-autocomplete
        :id="`config_acao_etapa_fluxo_${index}`"
        class="config_acao_fluxo_estrutura"
        :no-data-text="$tc('message.nenhum_registro', 1)"
        :label="`${$tc('label.etapa_fluxo', 1)}`"
        v-model="etapasFluxoInterno"
        :items="listaEtapaFluxo"
        item-text="texto"
        item-value="valor"
        :disabled="!complementoInformacao"
        :rules="[() => (!complementoInformacao
          || (complementoInformacao && etapasFluxoInterno && etapasFluxoInterno.length > 0)
          || $t('message.campo_obrigatorio'))]"
        multiple
        chips
        deletable-chips>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import { copyObject } from '@/produto/common/functions/helpers';

export default {
  name: 'ColunaComplementoInformacoes',
  props: {
    planejamento: {
      type: Boolean,
      default: false,
    },
    apuracao: {
      type: Boolean,
      default: false,
    },
    liquidacao: {
      type: Boolean,
      default: false,
    },
    indHabilitaAprovacaoApuracao: {
      type: Boolean,
      default: false,
    },
    etapasFluxo: {
      type: Array,
    },
    index: Number,
  },
  data() {
    return {
      complementoInformacao: false,
      listaEtapaFluxo: [],
      etapasFluxoInterno: [],
      planejamentoInterno: null,
      apuracaoInterno: null,
      liquidacaoInterno: null,
    };
  },
  watch: {
    complementoInformacao(value) {
      if (!value) {
        this.etapasFluxoInterno = [];
      }
    },
    etapasFluxoInterno() {
      this.$emit('ALTERACAO_ETAPAS_FLUXO', this.index, this.etapasFluxoInterno);
    },
  },
  methods: {
    setListaEtapaFluxo(planejamento, apuracao, liquidacao) {
      this.planejamentoInterno = planejamento;
      this.apuracaoInterno = apuracao;
      this.liquidacaoInterno = liquidacao;
      this.listaEtapaFluxo = [];

      if (!this.planejamentoInterno && !this.apuracaoInterno && !this.liquidacaoInterno) {
        this.complementoInformacao = false;
      }

      if (this.planejamentoInterno && !this.indHabilitaAprovacaoApuracao) {
        this.listaEtapaFluxo.push({ texto: this.$t('label.planejamento'), valor: 'PLANEJAMENTO' });
      }
      if (this.apuracaoInterno && !this.indHabilitaAprovacaoApuracao) {
        this.listaEtapaFluxo.push({ texto: this.$tc('label.apuracao', 1), valor: 'APURACAO' });
      }
      if (this.liquidacaoInterno && !this.indHabilitaAprovacaoApuracao) {
        this.listaEtapaFluxo.push({ texto: this.$t('label.liquidacao'), valor: 'LIQUIDACAO' });
      }
    },
  },
  mounted() {
    if (this.etapasFluxo && this.etapasFluxo.length && !this.indHabilitaAprovacaoApuracao) {
      this.complementoInformacao = true;
      this.etapasFluxoInterno = copyObject(this.etapasFluxo);
    }
    this.planejamentoInterno = copyObject(this.planejamento);
    this.apuracaoInterno = copyObject(this.apuracao);
    this.liquidacaoInterno = copyObject(this.liquidacao);
    this.setListaEtapaFluxo(this.planejamentoInterno, this.apuracaoInterno, this.liquidacaoInterno);
  },
};
</script>
