var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('div',{staticClass:"title justify-center",staticStyle:{"text-align":"center","padding":"5px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.titulo))])]),_c('div',{staticStyle:{"text-align":"center","padding":"5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canAccessAprovMass)?_c('v-btn',_vm._g({attrs:{"fab":"","color":"primary","x-small":""},nativeOn:{"click":function($event){return _vm.abrirListagemApuracao()}}},on),[_c('v-icon',[_vm._v("done_all")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.aprovacao_massiva_apuracoes')))])])],1)]),_c('v-container',{staticClass:"DashboardPendencias_Apuracao",attrs:{"fluid":"","grid-list-md":""}},[_c('v-data-iterator',{attrs:{"items":_vm.dados,"server-items-length":_vm.totalPage,"options":_vm.pagination,"hide-default-footer":_vm.escondePaginacao,"footer-props":{
        itemsPerPageOptions: [5, 10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{attrs:{"align":"start","justify":"center"}},_vm._l((props.items),function(item){return _c('v-col',{key:item.idApuracao,attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return _c('v-card',{staticClass:"Card_Pendencia fill-height",class:("elevation-" + (hover ? 5 : 1))},[_c('v-card-title',{staticClass:"Card_Title"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('span',[_vm._v(" "+_vm._s(((item.tipoAcao) + " #" + (item.idAcao) + "-" + (item.idApuracao) + " :: " + (_vm.getMoney(item.valorRecebimento))))+" ")]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.abrirDetalhamento(item)}}},on),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.detalhe', 2)))])])],1)],1)],1),_c('v-card-text',{staticClass:"Card_Content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[(_vm.indModoVarejo)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(item.grupoFornecedor)?_c('span',[_vm._v(_vm._s(item.grupoFornecedor))]):_c('span',[_vm._v(_vm._s(item.fornecedor))]),(item.extensoes)?_c('span',[_vm._v(_vm._s(("- " + (item.extensoes))))]):_vm._e()]):_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(item.holding)?_c('span',[_vm._v(_vm._s(item.holding))]):(item.cliente)?_c('span',[_vm._v(_vm._s(item.cliente))]):_c('span',[_vm._v(_vm._s(item.regional))]),(item.extensoes)?_c('span',[_vm._v(_vm._s(("- " + (item.extensoes))))]):_vm._e()]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"7"}},[_c('span',[_vm._v(_vm._s(((item.dtaInicio) + " a " + (item.dtaFim))))])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('v-spacer'),_c('apuracao-acao-fluxo',{key:item.idApuracao,attrs:{"idApuracao":item.idApuracao,"indJustificativaAnalise":item.indJustificativaAnalise,"indHabilitaFluxo":item.indHabilitaFluxo,"indHabilitaEdicaoAprovacao":item.indHabilitaEdicaoAprovacao,"acao":item.acao,"idAcao":item.idAcao,"status":item.status,"pode-cancelar":item.podeCancelar,"pode-reprovar":item.podeReprovar,"is-fluxo-paralelo":item.isFluxoParalelo,"vlr-conf-apuracao":item.vlrConfApuracao,"exibir-acompanhamento":false},on:{"recarregar":function($event){return _vm.buscarDados()}}})],1)],1)],1)],1)],1)}}],null,true)})],1)}),1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }