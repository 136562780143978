<template>

  <v-tabs
    class="elevation-1"
    @change="filtrar"
    v-model="tabSelecionada">
    <v-tab href="#tab-eventos">
      {{$t('label.extrato_conta_corrente_resumido')}}
    </v-tab>

    <v-tab href="#tab-detalhes">
       {{$t('label.extrato_conta_corrente_detalhado')}}
    </v-tab>

    <v-tabs-items v-model="tabSelecionada">
      <v-tab-item id="tab-eventos" >
         <v-data-table
          :headers="headers"
          :items="eventos"
          :options.sync="options"
          :server-items-length="totalEventos"
          class="elevation-1"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50],
          }">
              <template v-slot:item.dta_criacao="{ item }">
                {{ item.dtaCriacao }}
              </template>
              <template v-slot:item.vlr_evento="{ item }">
                <div v-if="item.isOrcamentoPercentual" :style="item.natureza === 'CREDITO' ? 'color: blue;' : 'color: red;'">
                  {{ getPercent(item.vlrEvento) }}
                </div>
                <div v-else :style="item.natureza === 'CREDITO' ? 'color: blue;' : 'color: red;'">
                  {{ getMoney(item.vlrEvento) }}
                </div>
              </template>
           </v-data-table>
      </v-tab-item>

      <v-tab-item id="tab-detalhes" >
        <v-data-table
          :headers="headersDetalhes"
          :items="detalhesEventos"
          :options.sync="optionsDetalhes"
          :server-items-length="totalDetalhesEventos"
          class="elevation-1"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50],
          }">
              <template v-slot:item.dta_criacao="{ item }">
                {{ item.dtaCriacao }}
              </template>
              <template v-slot:item.vlr_evento="{ item }">
                <div v-if="item.isOrcamentoPercentual" :style="item.natureza === 'CREDITO' ? 'color: blue;' : 'color: red;'" >
                  {{ getPercent(item.vlrEvento) }}
                </div>
                <div v-else :style="item.natureza === 'CREDITO' ? 'color: blue;' : 'color: red;'" >
                  {{ getMoney(item.vlrEvento) }}
                </div>
              </template>
           </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { getMoney, getPercent } from '../../../common/functions/helpers';

export default {
  name: 'ExtratoContaCorrenteTabela',
  props: {
    idsContasCorrentes: {
      Type: Array,
    },
    dataInicio: {
      Type: String,
    },
    dataFim: {
      Type: String,
    },
  },
  data() {
    return {
      extratoContaCorrente: this.$api.extratoContaCorrente(this.$resource),
      eventos: [],
      totalEventos: 0,
      detalhesEventos: [],
      totalDetalhesEventos: 0,
      options: {},
      optionsDetalhes: {},
      esperar: false,
      tabSelecionada: null,
      headers: [
        { text: this.$tc('label.data', 1), value: 'dta_criacao' },
        { text: this.$tc('label.descricao', 1), value: 'descricao' },
        { text: this.$tc('label.valor', 1), value: 'vlr_evento' },
      ],
      headersDetalhes: [
        { text: this.$tc('label.data', 1), value: 'dta_criacao' },
        { text: this.$tc('label.cod_acao', 1), value: 'codAcao' },
        { text: this.$tc('label.descricao', 1), value: 'descricao' },
        { text: this.$tc('label.tipo_acao', 1), value: 'tipo' },
        { text: this.$tc('label.responsavel', 1), value: 'responsavel' },
        { text: this.$tc('label.valor', 1), value: 'vlr_evento' },
      ],
    };
  },
  watch: {
    idContaCorrente() {
      this.filtrar();
    },
    dataInicio() {
      this.filtrar();
    },
    dataFim() {
      this.filtrar();
    },
    options: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    optionsDetalhes: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    getPercent,
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        if (this.isTabSelecionadaEvento()) {
          this.consultarEventos();
        } else {
          this.consultarDetalhesEvento();
        }
      }, 5E2);
    },
    consultarEventos() {
      this.extratoContaCorrente.eventos(this.getParametros())
        .then((response) => {
          this.eventos = response.data.resposta;
          this.totalEventos = response.data.quantidadeRegistrosPagina;
          this.pararEsperar();
        }, (err) => {
          this.pararEsperar();
          this.$error(this, err);
        });
    },
    consultarDetalhesEvento() {
      this.extratoContaCorrente.detalhesEvento(this.getParametros())
        .then((response) => {
          this.detalhesEventos = response.data.resposta;
          this.totalDetalhesEventos = response.data.quantidadeRegistrosPagina;
          this.pararEsperar();
        }, (err) => {
          this.pararEsperar();
          this.$error(this, err);
        });
    },
    getParametros() {
      const isEvento = this.isTabSelecionadaEvento();
      const param = {
        idsContaCorrente: this.idsContasCorrentes,
        dataInicio: this.dataInicio,
        dataFinal: this.dataFim,
        numeroPagina: isEvento ? this.options.page : this.optionsDetalhes.page,
        tamanhoPagina: isEvento ? this.options.itemsPerPage : this.optionsDetalhes.itemsPerPage,
        asc: isEvento ? !this.options.sortDesc[0] : !this.optionsDetalhes.sortDesc[0],
        colunaOrdenacao: isEvento ? this.options.sortBy[0] : this.optionsDetalhes.sortBy[0],
      };
      return param;
    },
    isTabSelecionadaEvento() {
      return this.tabSelecionada === 'tab-eventos';
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
