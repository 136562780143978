import getBasePath from '../functions/api-resource';

const basePath = getBasePath('api', 'notas');
const jobBasePath = getBasePath('job', 'notas');

const notasActions = {
  buscarNota: { methods: 'GET', url: `${basePath}/carregamento/{idNota}` },
  listarNotasAgrupadasPorTipo: { methods: 'GET', url: `${basePath}/por-tipo` },
  listarNotasSellInConsolidado: { methods: 'GET', url: `${basePath}/sell-in/consolidado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasSellInDetalhado: { methods: 'GET', url: `${basePath}/sell-in/detalhado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasDevolucaoConsolidado: { methods: 'GET', url: `${basePath}/devolucao/consolidado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasDevolucaoDetalhado: { methods: 'GET', url: `${basePath}/devolucao/detalhado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasSellOutConsolidado: { methods: 'GET', url: `${basePath}/sell-out/consolidado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasSellOutDetalhado: { methods: 'GET', url: `${basePath}/sell-out/detalhado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasInconsistenciaAgrupado: { methods: 'GET', url: `${basePath}/inconsistencia/consolidado` },
  listarNotasInconsistenciaDetalhado: { methods: 'GET', url: `${basePath}/inconsistencia/detalhado?tamanhoPagina={size}&numeroPagina={page}` },
  reprocessarGeral: { method: 'POST', url: `${basePath}/processamento/geral` },
  reprocessarInconsistencia: { method: 'GET', url: `${basePath}/processamento/reprocessar` },
  verificarDadosExportacao: { methods: 'GET', url: `${jobBasePath}/verificar-dados-exportacao?{&meses*}` },
};

export default (resource) => resource(`${basePath}`, {}, notasActions);
