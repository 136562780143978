<template>
  <v-badge
    :value="filtroSelecionado"
    :color="cor"
    overlap
  >
    <v-tooltip top>
      <template v-slot:activator="{ on }">

      <v-icon @click="toggleFiltros" v-on="on">
        filter_list
      </v-icon>

      </template>
      <span>{{ filtroSelecionado ? $t('label.filtros_aplicados') : $t('label.filtros_nenhum_aplicado') }}</span>
    </v-tooltip>
  </v-badge>
</template>

<script>
import { selecionado } from '../../common/functions/filtro-utils';

export default {
  name: 'IconFiltro',
  props: {
    filtros: {
      type: Object,
    },
    min: {
      type: Number,
      default: 0,
    },
    cor: {
      type: String,
      default: 'yellow',
    },
  },
  computed: {
    filtroSelecionado() {
      return selecionado(this.filtros, this.min);
    },
  },
  methods: {
    toggleFiltros() {
      this.$emit('click');
    },
  },
};
</script>
