<template lang="html">
  <div>
    <v-card-title>
      <h3 class="title mb-0">{{ $t('title.periodo_planejamento') }}</h3>
    </v-card-title>

    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <v-text-field
            ref="descricaoAnoPeriodo"
            id="descricao_ano_periodo"
            name="descricao_ano_periodo"
            v-model="periodo.descricao"
            :label="`${$t('label.descricao')} *`"
            :readonly="somenteLeitura"
            :counter="200"
            maxlength="200"
            :rules="[rules.required]">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="ano_periodo_data_inicio"
            :close-on-content-click="false"
            v-model="menuDataInicio"
            id="ano_periodo_data_inicio"
            name="ano_periodo_data_inicio"
            :nudge-right="40"
            :readonly="somenteLeitura"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                ref="anoPeriodoDataInicio"
                class="Form-text__invalid"
                required
                :return-masked-value="true"
                v-mask="'##-##-####'"
                :rules="[rules.required, rules.validDate]"
                v-model="dataInicioFormatada"
                :label="`${$t('label.data_inicio')} *`"
                prepend-icon="event">
              </v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              color="primary"
              :min="dataInicioPeriodo"
              :show-current="dataInicioPeriodo"
              v-model="dataInicioPicker"
              @input="menuDataInicio = false">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="ano_periodo_data_fim"
            :close-on-content-click="false"
            v-model="menuDataFim"
            id="ano_periodo_data_fim"
            name="ano_periodo_data_fim"
            :nudge-right="40"
            :readonly="somenteLeitura"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                ref="anoPeriodoDataFim"
                class="Form-text__invalid"
                required
                :return-masked-value="true"
                v-mask="'##-##-####'"
                :rules="[rules.required, rules.validDate]"
                v-model="dataFimFormatada"
                :label="`${$t('label.data_fim')} *`"
                prepend-icon="event">
              </v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              color="primary"
              :min="!!periodo.dataInicio ? periodo.dataInicio : dataInicioPeriodo"
              :show-current="periodo.dataInicio"
              v-model="dataFimPicker"
              @input="menuDataFim = false">
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" v-if="!somenteLeitura">
          <v-spacer></v-spacer>
          <v-btn id="incluir_intervalo" @click.native="incluirPeriodo" color="accent">{{ $t('label.incluir') }}</v-btn>
          <v-btn id="excluir_intervalo" class="ml-2" v-if="hasPeriodosSelecionados && !somenteLeitura" @click.native="remover">{{ $t('label.excluir') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="periodos"
      v-model="periodosSelecionados"
      item-key="descricao"
      show-select
      :options.sync="pagination"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      class="elevation-1">
      <template v-slot:item.dataInicio="{ item }">
        {{ formatDateDDMMYYYY(item.dataInicio) }}
      </template>
      <template v-slot:item.dataFim="{ item }">
        {{ formatDateDDMMYYYY(item.dataFim) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import {
  parseDateYYYYMMDD,
  formatDateDDMMYYYY,
} from '../../common/functions/date-utils';

export default {
  name: 'AnoFiscalPeriodos',
  props: {
    periodos: {
      type: Array,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    dataInicio: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      periodosSelecionados: [],
      menuDataInicio: false,
      menuDataFim: false,
      formatoSalvar: 'YYYY-MM-DD',
      dataInicioPeriodo: this.dataInicio,
      pagination: {
        rowsPerPage: 10,
      },
      periodo: {
        descricao: null,
        dataInicio: '',
        dataFim: '',
      },
      headers: [
        { text: this.$t('label.descricao'), value: 'descricao', sortable: false },
        { text: this.$t('label.data_inicio'), value: 'dataInicio', sortable: false },
        { text: this.$t('label.data_fim'), value: 'dataFim', sortable: false },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
    };
  },
  watch: {
    dataInicio(valor) {
      this.dataInicioPeriodo = valor;
    },
  },
  methods: {
    formatDateDDMMYYYY,
    validar() {
      if (this.periodos.length === 0) {
        this.$toast(this.$t('errors.periodo.intervalos.obrigatorios'));
        return false;
      }
      return true;
    },
    getPeriodos() {
      const periodosSalvar = [];
      this.periodos.forEach((el) => {
        periodosSalvar.push(this.prepararPeriodoSalvar(el));
      });
      return periodosSalvar;
    },
    limparForm() {
      this.$refs.descricaoAnoPeriodo.reset();
      this.$refs.anoPeriodoDataInicio.reset();
      this.$refs.anoPeriodoDataFim.reset();
    },
    prepararPeriodoSalvar(periodo) {
      const periodoSalvar = {};
      periodoSalvar.descricao = periodo.descricao;
      periodoSalvar.dataFim = this.alterarFormatoSalvar(periodo.dataFim);
      periodoSalvar.dataInicio = this.alterarFormatoSalvar(periodo.dataInicio);
      periodoSalvar.tipo = 'PLANEJAMENTO_ACAO';

      return periodoSalvar;
    },
    incluirPeriodo() {
      let valid = this.$refs.descricaoAnoPeriodo.validate(true);
      valid = this.$refs.anoPeriodoDataFim.validate(true)
              && this.$refs.anoPeriodoDataInicio.validate(true)
              && valid;
      if (valid) {
        this.periodos.push({
          descricao: this.periodo.descricao,
          dataInicio: this.periodo.dataInicio,
          dataFim: this.periodo.dataFim,
        });

        this.limparPeriodo();
        this.arrumarLista();
        this.emitirNotificacaoAlteracao();
      }
    },
    validarForm() {
      this.$refs.descricaoAnoPeriodo.validate();
      this.$refs.anoPeriodoDataInicio.validate();
      this.$refs.anoPeriodoDataFim.validate();
    },
    emitirNotificacaoAlteracao() {
      if (this.periodos.length) {
        this.$emit('PERIODO_INCLUIDO_OU_EXCLUIDO', this.periodos[this.periodos.length - 1].dataFim);
      } else {
        this.$emit('PERIODO_INCLUIDO_OU_EXCLUIDO', null);
      }
    },
    alterarFormatoSalvar(data) {
      return moment(data, this.formatoSalvar).format(this.formatoSalvar);
    },
    isAfter(data, outra) {
      return moment(data, this.formatoSalvar).isAfter(moment(outra, this.formatoSalvar));
    },
    arrumarLista() {
      this.periodos.sort((a, b) => this.isAfter(a.dataInicio, b.dataInicio));
    },
    limparPeriodo() {
      this.periodo.descricao = '';
      this.periodo.dataInicio = '';
      this.periodo.dataFim = '';
      this.limparForm();
    },
    preencherSelecionado(index) {
      const periodo = this.periodos[index];
      if (periodo) {
        periodo.index = index;
        this.periodosSelecionados.push(periodo);
      }
    },
    remover() {
      const index = [];
      this.periodosSelecionados.forEach((el) => {
        this.periodos.forEach((p, i) => {
          if (p.dataInicio === el.dataInicio
              && p.dataFim === el.dataFim
              && p.descricao === el.descricao) {
            index.push(i);
          }
        });
      });

      index.forEach((el) => this.periodos.splice(el, 1));
      this.periodosSelecionados = [];
      this.arrumarLista();
      this.emitirNotificacaoAlteracao();
    },
  },
  computed: {
    dataInicioFormatada: {
      get() {
        return formatDateDDMMYYYY(this.periodo.dataInicio);
      },
      set(newValue) {
        this.periodo.dataInicio = parseDateYYYYMMDD(newValue);
      },
    },
    dataInicioPicker: {
      get() {
        return this.periodo.dataInicio ? this.periodo.dataInicio : this.dataInicio;
      },
      set(newValue) {
        this.periodo.dataInicio = newValue;
        if (this.isAfter(this.periodo.dataInicio, this.periodo.dataFim)) {
          this.periodo.dataFim = '';
        }
      },
    },
    dataFimFormatada: {
      get() {
        return formatDateDDMMYYYY(this.periodo.dataFim);
      },
      set(newValue) {
        this.periodo.dataFim = parseDateYYYYMMDD(newValue);
      },
    },
    dataFimPicker: {
      get() {
        return this.periodo.dataFim ? this.periodo.dataFim : this.periodo.dataInicio;
      },
      set(newValue) {
        this.periodo.dataFim = newValue;
      },
    },
    isContentAvailable() {
      return this.periodos.length > 0;
    },
    hojeYYYYMMD() {
      return [moment().format('YYYY-MM-D')];
    },
    hasPeriodosSelecionados() {
      return this.periodosSelecionados.length;
    },
  },
};
</script>

<style lang="scss">
  .ANOFISCALPERIODOSINTERVALOS__Boxing {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
  }

  .ANOFISCALPERIODOSINTERVALOS__NoContent {
    text-align: center;
  }
</style>
