<template>
  <div v-if="canAccessPage">
    <dashboard-inicial-filtro
      v-model="abrirFiltro"
      @DashboardInicialFiltro__AplicaFiltros="aplicarFiltros">
    </dashboard-inicial-filtro>

    <v-container fluid grid-list-md pa-0>
      <v-row align="center" justify="end" class="font-weight-black" style="min-height: 50px">
        <v-col cols="12" class="text-right" xs1>
          <icon-filtro v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro" :filtros="filtrosSelecionados" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <liquidacao-cards-totalizadores
            ref="cards_totalizadores"
            :filtros="filtros"
            percentual="quantidade">
          </liquidacao-cards-totalizadores>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="8" class="pt-0">
          <v-row>
            <v-col cols="12" class="px-3">
              <v-card style="width: 100%;">
                <dashboard-varejo-grafico
                  :filtros="filtros"/>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <dashboard-funcionario-varejo-ranking-recebimento
                ref="ranking_grupo_fornecedor"
                origem="grupoFornecedor"
                :filtros="filtros"
                :title="$tc('title.ranking_recebimento', 2)"
                :subtitle="$tc('label.fornecedor', 1)">
              </dashboard-funcionario-varejo-ranking-recebimento>
            </v-col>
            <v-col cols="12" sm="6">
              <dashboard-funcionario-varejo-ranking-recebimento
                ref="ranking_setor"
                origem="setor"
                :filtros="filtros"
                :title="$tc('title.ranking_recebimento', 2)"
                :subtitle="$tc('label.categoria', 1)">
              </dashboard-funcionario-varejo-ranking-recebimento>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="pt-0">
          <v-row>
            <v-col cols="12" d-flex >
              <dashboard-pendencias-planejamento
                :filtros="filtros" />
            </v-col>
            <v-col cols="12" d-flex >
              <dashboard-pendencias-apuracao
                :filtros="filtros" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script type="text/javascript">
import moment from 'moment';
import { mapGetters } from 'vuex';
import ApuracaoCardsTotalizadores from '../../../apuracao-acao/ApuracaoCardsTotalizadores';
import LiquidacaoCardsTotalizadores from '../../../liquidacao-acao/LiquidacaoCardsTotalizadores';

import DashboardFuncionarioVarejoRankingRecebimento from './DashFuncionarioVarejoRankingRecebimento';

import DashboardVarejoGrafico from '../DashVarejoGrafico';

import DashboardPendenciasApuracao from '../../DashboardPendenciasApuracao';
import DashboardPendenciasPlanejamento from '../../DashboardPendenciasPlanejamento';
import DashboardInicialFiltro from '../../DashboardInicialFiltro';
import IconFiltro from '../../../../shared-components/filtros/IconFiltro';

export default {
  name: 'DashboardInicial',
  data() {
    return {
      filtros: {},
      filtrosSelecionados: {},
      abrirFiltro: false,
    };
  },
  components: {
    ApuracaoCardsTotalizadores,
    LiquidacaoCardsTotalizadores,
    DashboardVarejoGrafico,
    DashboardInicialFiltro,
    DashboardPendenciasApuracao,
    DashboardPendenciasPlanejamento,
    DashboardFuncionarioVarejoRankingRecebimento,
    IconFiltro,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'DASH_VAREJO').length;
    },
  },
  methods: {
    aplicarFiltros(filtrosAplicados) {
      this.filtrosSelecionados = { ...filtrosAplicados };
      if (!filtrosAplicados || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (!filtrosAplicados.data_inicio && !filtrosAplicados.data_fim) {
        filtrosAplicados.data_inicio = moment(new Date(new Date().getFullYear(), 0, 1), 'DD-MM-YYYY')
          .format('YYYY-MM-DD');
        filtrosAplicados.data_fim = moment(new Date(new Date().getFullYear(), 11, 31), 'DD-MM-YYYY')
          .format('YYYY-MM-DD');
      }
      this.filtros = {
        ...filtrosAplicados,
      };
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
  },
};
</script>
