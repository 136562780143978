import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

export const countRelatorioInvestimentoCliente = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'investimento-cliente',
  parametros,
).doGet();

export const countRelatorioInvestimentoRegional = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'investimento-regional',
  parametros,
).doGet();

export const countRelatorioStatusAcoes = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'status-acoes',
  parametros,
).doGet();

export const countRelatorioSaldoProvisao = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'saldo-provisao',
  parametros,
).doGet();

export const countRelatorioProvisao = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'provisao',
  parametros,
).doGet();

export const countRelatorioAjusteProvisao = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'ajuste-provisao',
  parametros,
).doGet();

export const countRelatorioCriacaoPagamento = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'criacao-pagamento',
  parametros,
).doGet();

export const countRelatorioCancelamentoZver = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'cancelamento-zver',
  parametros,
).doGet();

export const countRelatorioContrato = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'contrato',
  parametros,
).doGet();

export const countRelatorioInconsistenciaNotas = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'incosistencias-notas',
  parametros,
).doGet();

export const countRelatorioInconsistenciasIntegracao = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('job', 'quantidade-registros-relatorios-suzano'),
  'incosistencias-integracao',
  parametros,
).doGet();
