import coreResources from '@/produto/common/resources/core-resources';
import relatoriosIntegracao from './relatorios-integracao';
import suzanoPlanejamentoAcaoCadastro from './suzano-planejamento-acao-cadastro';
import ajusteVerbaMassivo from './ajuste-verba-massivo';
import pagamentoSuzano from './pagamento-suzano';
import suzanoPlanejamentoAcaoListagemMassiva from './suzano-planejamento-acao-listagem-massiva';
import parametrizacaoAcao from './parametrizacao-acao';
import planejamentoContratoSuzano from './planejamento-contrato-suzano';

function plugin(Vue) {
  Vue.prototype.$api = {
    ...coreResources,
    suzanoPlanejamentoAcaoCadastro,
    relatoriosIntegracao,
    ajusteVerbaMassivo,
    pagamentoSuzano,
    suzanoPlanejamentoAcaoListagemMassiva,
    parametrizacaoAcao,
    planejamentoContratoSuzano,
  };
}

if (typeof window !== 'undefined' && window.Vue) { // eslint-disable-line no-undef
  window.Vue.use(plugin); // eslint-disable-line no-undef
}

export default plugin;
