var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":400,"transition":"scale-transition","offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuFunction = ref.on;
return [(_vm.layoutGrid)?_c('v-list-item',_vm._g({staticClass:"AcaoFluxo__listItem",attrs:{"icon":"","color":_vm.corIcone}},menuFunction),[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(_vm.icone))])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.textoAcao))])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.corIcone}},Object.assign({}, tooltip, menuFunction)),[_c('v-icon',[_vm._v(_vm._s(_vm.icone))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textoAcao))])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-title',[_c('div',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.textoAcao))])])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"textareaJustificativa",attrs:{"id":"Acaofluxo_justificativa","index":"1","name":"Acaofluxo_justificativa","auto-grow":"","autofocus":"","rows":"1","rules":[_vm.required],"disabled":_vm.bloquearCampos,"label":_vm.validarJustificativa ? ((_vm.$t('label.justificativa')) + " *") : _vm.$t('label.justificativa'),"counter":500,"maxlength":"500"},model:{value:(_vm.justificativa),callback:function ($$v) {_vm.justificativa=$$v},expression:"justificativa"}})],1)],1),_c('v-row',[(_vm.mostrarCamposDinamicos)?_c('metadados-container-layout',{ref:"container",attrs:{"metadados-entidade":_vm.metadadosCampos,"layout-class":_vm.layoutClass,"input-layout":_vm.inputLayout,"objeto":_vm.acao,"somenteLeitura":!_vm.mostrarRodape}}):_vm._e()],1),_c('v-row',[(_vm.acaoProrrogacao)?_c('v-col',{attrs:{"cols":"12"}},[_c('metadados-campo-dinamico-data',{attrs:{"metadados":_vm.cfgCampoProrrogacao,"somenteLeitura":_vm.bloquearCampos},model:{value:(_vm.dtaProrrogacao),callback:function ($$v) {_vm.dtaProrrogacao=$$v},expression:"dtaProrrogacao"}})],1):_vm._e()],1)],1)],1),(_vm.mostrarRodape)?_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{staticClass:"mr-3",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.icone !== 'cancel')?_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.bloquearCampos},on:{"click":_vm.cancelar}},on),[_c('v-icon',[_vm._v("cancel")])],1):_vm._e(),(_vm.icone === 'cancel')?_c('v-btn',{attrs:{"color":"primary darken-1","text":"","disabled":_vm.bloquearCampos},on:{"click":_vm.cancelar}},[_vm._v("Não")]):_vm._e()]}}],null,false,3249343967)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.cancelar')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.icone !== 'cancel')?_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.corIcone,"disabled":_vm.bloquearCampos},on:{"click":_vm.executarAcao}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.icone))])],1):_vm._e(),(_vm.icone === 'cancel')?_c('v-btn',{attrs:{"text":"","color":"primary darken-1","disabled":_vm.bloquearCampos},on:{"click":_vm.executarAcao}},[_vm._v("Sim")]):_vm._e()]}}],null,false,1519022196)},[_c('span',[_vm._v(_vm._s(_vm.textoAcao))])])],1):_vm._e()],1),(_vm.exibirPerguntaProximo)?_c('v-card',[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('message.deseja_seguir_proxima_apuracao'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.naoIrProximo}},[_vm._v(" "+_vm._s(_vm.$t('label.nao'))+" ")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.irProximo}},[_vm._v(" "+_vm._s(_vm.$t('label.sim'))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }