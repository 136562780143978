<template>
  <div>
    <v-row align="start" justify="center">
      <div style="text-align: center; padding: 5px" class="title justify-center">
        <p class="mb-0">{{ titulo }}</p>
      </div>
      <div style="text-align: center; padding: 5px" class="title justify-center">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn fab
                   color="primary"
                   x-small
                   v-on="on"
                   v-if="canAccessAprovMass"
                   @click.native="abrirListagemPagamento()">
              <v-icon>done_all</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.aprov_pag_massiva') }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <v-container fluid grid-list-md class="DashboardPendencias_Pagamento">
      <v-data-iterator
        :items="dados"
        :server-items-length="totalPage"
        :options.sync="pagination"
        :hide-default-footer="escondePaginacao"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50],
        }">
        <template v-slot:default="props">
          <v-row align="start" justify="center">
            <v-col
              cols="12"
              v-for="(item) in props.items"
              :key="item.idPagamento">
              <v-hover>
                <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 5 : 1}`"
                        class="Card_Pendencia fill-height">
                  <v-card-title class="Card_Title">
                    <v-container fluid>
                      <v-row justify="space-between">
                        <span v-if="item.idApuracao">
                          {{
                            `${item.tipoAcao} #${item.idAcao}-${item.idApuracao}-${item.idPagamento} :: ${getMoney(item.valorPagamento)}`
                          }}
                        </span>
                        <span v-else>
                          {{
                            `${item.tipoAcao} #${item.idAcao}-${item.idPagamento} :: ${getMoney(item.valorPagamento)}`
                          }}
                        </span>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn text icon
                                   v-on="on"
                                   @click.native="abrirDetalhamento(item)">
                              <v-icon>info</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $tc('label.detalhe', 2) }}</span>
                        </v-tooltip>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text class="Card_Content">
                    <v-container fluid>
                      <v-row justify="center" align="center">
                        <v-col cols="12" class="pa-0">
                          <span v-if="item.holding">{{ item.holding }}</span>
                          <span v-else-if="item.cliente">{{ item.cliente }}</span>
                          <span v-else>{{ item.regional }}</span>
                          <span v-if="item.extensoes">{{ ` - ${item.extensoes}` }}</span>
                        </v-col>
                        <v-col cols="12" md="7" class="pa-0">
                          <span>{{ `${item.dtaInicio} a ${item.dtaFim}` }}</span>
                        </v-col>
                        <v-col cols="12" md="5" class="py-0">
                          <v-spacer></v-spacer>
                          <pagamento-acao-fluxo
                            :key="item.idPagamento"
                            :id-pagamento="item.idPagamento"
                            :ind-habilita-fluxo="item.indHabilitaFluxo"
                            :acao="item.acao"
                            :id-acao="item.idAcao"
                            :status="item.status"
                            :pode-cancelar="item.podeCancelar"
                            :pode-reprovar="item.podeReprovar"
                            :exibir-acompanhamento="false"
                            :observacao-acao="item.observacaoAcao"
                            @recarregar="buscarDados()"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PagamentoAcaoFluxo from '@/produto/spa/liquidacao-acao/pagamento/PagamentoAcaoFluxo';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';

import {
  getMoney,
  getPercent,
} from '@/produto/common/functions/helpers';
import { pendencias } from '@/produto/common/resources/workflow-pagamento-acao';

export default {
  name: 'dash-inicial-pendencias-pagamento',
  components: {
    PagamentoAcaoFluxo,
  },
  props: {
    filtros: Object,
    dashFornecedor: {
      type: Boolean,
      default: false,
    },
    dashCliente: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    titulo() {
      if (this.title) {
        return this.title;
      }
      return this.$tc('title.pendencia_pagamento', 2);
    },
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('PGTO', [
      'canEdit',
    ]),
    canAccessAprovMass() {
      if (!this.getAllRoles) {
        return false;
      }
      return (!!this.getAllRoles
        .filter((el) => el === 'PGTO_LISTA').length)
        && (!!this.getAllRoles
          .filter((el) => el === 'APROV_PAG_MASSIVA').length);
    },
  },
  data() {
    return {
      dados: [],
      escondePaginacao: true,
      pagination: {
        itemsPerPage: 5,
      },
      totalPage: 0,
      filtrosAplicados: this.filtros,

      realizouPrimeiraBusca: false,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscarDados();
        setTimeout(() => {
          this.realizouPrimeiraBusca = true;
        });
      },
    },
    pagination: {
      handler() {
        if (this.realizouPrimeiraBusca) {
          this.buscarDados();
        }
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    getPercent,

    buscarDados() {
      const params = {
        ...this.filtrosAplicados,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      pendencias(params, this.$resource)
        .then((res) => {
          const { data } = res;
          if (!data.resposta.length && this.pagination.page > 1) {
            this.pagination.page -= 1;
            this.buscarDados();
            return;
          }
          this.dados = data.resposta;
          this.dados.forEach((i) => {
            i.acao = {};
            i.acao.idAcao = i.idAcao;
            i.acao.idApuracao = i.idApuracao;
            i.acao.idPagamento = i.idPagamento;
            const desAtributos = i.desAtributos
              ? JSON.parse(i.desAtributos.value) : {};
            i.acao = {
              ...i.acao,
              ...desAtributos,
            };
          });
          this.totalPage = data.quantidadeRegistrosPagina;
          this.escondePaginacao = (this.totalPage / this.pagination.itemsPerPage) <= 1;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abrirDetalhamento(item) {
      this.$router.push({
        name: 'detalharPagamentoOrigem',
        params: {
          id: item.idPagamento,
          origem: 'dashboard-inicial',
          idAcao: item.idAcao,
          from: 'dashboard-inicial',
        },
      });
    },
    abrirListagemPagamento() {
      this.$router.push({
        name: 'liquidacaoEmLista',
        params: {
          rotaAlterada: true,
          descricaoRotaAlterada: this.$tc('label.dashboard_inicial', 1),
          rota: 'dashboard-inicial',
        },
        query: {
          origem: this.$tc('label.visao_em_lista', 1),
        },
      });
    },
  },
};
</script>
<style>
.DashboardPendencias_Pagamento {
  padding-top: 0px;
}
.DashboardPendencias_Pagamento .Card_Pendencia .Card_Title {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_Pagamento .Card_Pendencia .Card_Title span {
  font-weight: 700;
  margin: 0px;
  font-size: 16px;
}
.DashboardPendencias_Pagamento .Card_Pendencia .Card_Content {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_Pagamento .Card_Pendencia .Card_Content span {
  font-size: 14px;
}
.DashboardPendencias_Pagamento .Card_Pendencia .Card_Title button span i,
.DashboardPendencias_Pagamento .Card_Pendencia .Card_Title span,
.DashboardPendencias_Pagamento .Card_Pendencia .Card_Content span,
.DashboardPendencias_Pagamento .Card_Pendencia .Card_Content button span i {
  color: rgba(0,0,0,.70) !important;
}
.DashboardPendencias_Pagamento .Card_Pendencia .Card_Actions {
  padding-top: 0px;
}
</style>
