<template lang="html">
  <div>
    <v-form ref="form" lazy-validation>
      <v-container fluid>
        <v-card-title primary-title class="card-header">
          {{ $t('title.resumo') }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.resumo')}`" />
        </v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              id="config_orcamento_nome"
              v-model="parametrizacao.nome"
              :label="`${$tc('label.nome', 1)} * `"
              :rules="[rules.required]"
              maxlength="70"
              counter=70
              :disabled="somenteLeitura"
              required>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              id="config_orcamento_descricao"
              v-model="parametrizacao.descricao"
              :label="`${$tc('label.descricao', 1)} * `"
              :rules="[rules.required]"
              maxlength="200"
              counter=200
              :disabled="somenteLeitura"
              required>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              id="config_orcamento_icone"
              v-model="parametrizacao.icone"
              :label="`${$tc('label.icone', 1)} * `"
              :rules="[rules.required]"
              maxlength="50"
              counter=50
              :disabled="somenteLeitura"
              required>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              id="config_orcamento_tags"
              v-model="parametrizacao.tags"
              :label="$tc('label.verba', 1)"
              maxlength="200"
              :disabled="somenteLeitura"
              counter=200>
            </v-text-field>
          </v-col>
        </v-row>

        <v-card-title primary-title class="card-header mt-4">
          {{ $t('label.tipo_de_investimento') }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.tipo_investimento')}`" />
        </v-card-title>
        <v-data-table :headers="headers"
          id="config_orcamento_tabela_tipo_investimento"
          :items="tipos"
          hide-default-footer
          disable-pagination
          :items-per-page="-1"
          :show-select="!somenteLeitura"
          v-model="tiposSelecionados"
          item-key="idExterno"
          :no-data-text="$t('message.nenhum_registro')">
          <template v-slot:item.id_externo="{ item }">
              {{ item.idExterno }}
          </template>
          <template v-slot:item.acoes="{ item }">
            <v-tooltip bottom v-if="validarSetemAcaoConfigInvest(item)">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="warning" @click="abrirListaParamAcao(item)">warning</v-icon>
              </template>
              <span>{{ $t('message.tipo_orc_associado_param_acao') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.tag="{ item }">
            <v-combobox
              class="combo_opcoes"
              :key="item.id"
              v-model="item.tag"
              :disabled="!desabilitarTags(item.id) || somenteLeitura"
              multiple
              persistent-hint
              small-chips
              deletable-chips
              :id="`config_orcamento_tabela_tipo_investimento_data_${item.idExterno}`"
            >
            </v-combobox>
          </template>
        </v-data-table>

        <v-card-title primary-title class="card-header mt-4">
          {{ $t('title.comportamento') }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.comportamento')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-radio-group v-model="parametrizacao.comportamento"
              :rules="[rules.required]" id="config_orcamento_comportamento">
              <v-radio :label="$t('message.aporte_manual_verba')" value="MANUAL" :disabled="somenteLeitura"></v-radio>
              <v-radio :label="$t('message.aporte_automatico_sem_projecao')" value="AUTOMATICO" :disabled="somenteLeitura"></v-radio>
              <v-radio :label="$t('message.aporte_automatico_com_projecao')" value="AUTOMATICO_PROJECAO" :disabled="somenteLeitura"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row class="mt-4" justify="end">
          <v-btn id="config_orcamento_passo1_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
          <v-btn id="config_orcamento_passo1_botao_proximo" color="primary" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
        </v-row>
      </v-container>
    </v-form>
    <lista-parametrizacao-acao :parametrizacoes-acao="parametrizacoesAcao" ref="listaParametrizacaoAcao"></lista-parametrizacao-acao>
  </div>
</template>

<script type="text/javascript">
import Passo1 from './Passo1';
import TooltipAjuda from '../TooltipAjuda';
import ListaParametrizacaoAcao from './ListaParametrizacaoAcao';
import { generateComputed } from '../../../common/functions/roles-computed-generator';

export default {
  name: 'ParametrizacaoOrcamentoPasso1',
  props: {
    id: Number,
  },
  components: {
    ListaParametrizacaoAcao,
    TooltipAjuda,
  },
  watch: {
    id() {
      this.prepararPasso();
    },
  },
  data() {
    return {
      passo: 1,
      parametrizacoesAcao: [],
      tipoInvestimentoResources: this.$api.orcamentoTipoInvestimento(this.$resource),
      paramOrcamentoResources: this.$api.parametrizacaoOrcamento(this.$resource),
      orcamentoVerbaInvestimentoResources: this.$api.orcamentoVerbaInvestimento(this.$resource),
      headersTipoInvestimento: [
        { text: this.$tc('label.codigo', 1), value: 'idExterno', width: '35%' },
        { text: this.$tc('label.descricao', 1), value: 'nome', width: '45%' },
        { text: this.$tc('label.tag', 1), value: 'tag', width: '45%' },
      ],
      tipos: [],
      tiposSelecionados: [],
      tiposSelecionadosTags: [],
      valid: true,
      verbas: [
        { text: this.$tc('label.cliente', 1), value: 'CLIENTE' },
        { text: this.$tc('label.divisao', 1), value: 'DIVISAO' },
        { text: this.$tc('label.funcionario', 1), value: 'FUNCIONARIO' },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      parametrizacao: {},
      temAcaoConfigInvest: false,
      tags: {},
      items: {},
    };
  },
  computed: {
    headers() {
      const headers = this.headersTipoInvestimento.slice(0);
      if (this.temAcaoConfigInvest) {
        headers.push({ text: '', value: 'acoes', width: '5%' });
      }
      return headers;
    },
    ...generateComputed('param_orc', [
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    abrirListaParamAcao(item) {
      this.parametrizacoesAcao = item.acaoConfiguracaoInvestimento;
      this.$refs.listaParametrizacaoAcao.open();
    },
    adicionarTiposInvestimento() {
      if (!this.tiposSelecionados.length) {
        this.$toast(this.$t('message.selecione_tipo_investimento'));
        return false;
      }
      this.parametrizacao.setTiposInvestimento(this.tiposSelecionados.map((el) => el.id));
      return true;
    },
    adicionarTiposInvestimentoTags() {
      if (!this.tiposSelecionados.length) {
        this.$toast(this.$t('message.selecione_tipo_investimento'));
        return false;
      }
      this.parametrizacao.setTiposInvestimentoTags(
        this.tiposSelecionadosTags = this.tiposSelecionados.map((el) => ({
          id_tipo_investimento: el.id,
          id_tipo_verba: this.id,
          des_tag: this.setaTags(el.tag),
        })),
      );
      return true;
    },
    buscarTiposInvestimento() {
      this.tipoInvestimentoResources.ativos().then((response) => {
        this.tipos = response.data;
        if (this.tipos.length) {
          const edicao = this.parametrizacao.getTiposInvestimento();
          const edicaoTags = this.parametrizacao.getTipoTags();
          if (edicao.length) {
            this.tipos.forEach((t) => {
              const sel = edicao.filter((el) => Number(el.id) === t.id);
              const et = edicaoTags.filter((el) => Number(el.id_tipo_investimento) === t.id);
              if (sel.length) {
                t.idOrcamentoVerbaInvestimento = sel[0].idorcamento;
                this.verificarUsoParametrizacaoAcao(t);
                if (et.length && et[0].des_tag && et[0].des_tag.length) {
                  t.tag = (et[0].des_tag).split(',');
                }
                this.tiposSelecionados.push(t);
              } else {
                t.selecionado = false;
              }
            });
          }
        }
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsoParametrizacaoAcao(tipo) {
      const id = { id: tipo.idOrcamentoVerbaInvestimento };
      this.orcamentoVerbaInvestimentoResources.acaoConfiguracaoInvestimento(id)
        .then((response) => {
          tipo.acaoConfiguracaoInvestimento = response.data;
          if (this.validarSetemAcaoConfigInvest(tipo)) {
            this.temAcaoConfigInvest = true;
          }
        });
    },
    setaTags(object) {
      if (object !== undefined) {
        return object.join();
      }
      return null;
    },
    validarSetemAcaoConfigInvest(tipo) {
      return !!(tipo.acaoConfiguracaoInvestimento && tipo.acaoConfiguracaoInvestimento.length);
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_CANCELAR');
    },
    desabilitarTags(idLinha) {
      return this.tiposSelecionados.find((el) => el.id === idLinha);
    },
    obter(id) {
      this.paramOrcamentoResources.obter({ id }, { id }).then((response) => {
        this.parametrizacao = new Passo1(response.data);
        this.buscarTiposInvestimento();
      }, (err) => {
        this.$error(this, err);
      });
    },
    prepararPasso() {
      if (this.id) {
        // Editar
        this.obter(this.id);
      } else {
        // Novo
        this.parametrizacao = new Passo1();
        this.buscarTiposInvestimento();
      }
    },
    proximoPasso() {
      if (this.$refs.form.validate() && this.adicionarTiposInvestimento() && this.adicionarTiposInvestimentoTags()) {
        this.$emit('PARAMETRIZACAO_ORCAMENTO_CONTINUAR', this.passo + 1, this.id);
        this.$emit('PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
      }
    },
  },
  mounted() {
    if (!this.id && this.$route.params.id) {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_ID', parseInt(this.$route.params.id, 10));
    }
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
</style>
