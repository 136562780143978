import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', '');

const planejamentoAcaoActions = {
  concluirAcaoMassiva: { method: 'POST', url: `${basePath}listagem/concluir_acao` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoActions);

export const buscarTiposAcao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipo_acao_massivo', parametros).doGet();

export const buscarAcoes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipo_acao/acoes_massivo', parametros).doGet();

export const buscarAcoesInseridas = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/acoes_massivas_inseridas', parametros).doGet();

export const buscarinfoAcaoTradePrevia = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/acao_massiva_info_acao', parametros).doGet();
