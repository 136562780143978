import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('api', 'orcamento-tipo-verba-suzano');

const orcamentoActions = {
};
export default (resource) => resource(`${basePath}`, {}, orcamentoActions);

export const buscarAtivosSuzano = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'ativos', parametros).doGet();
