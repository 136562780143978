<template lang="html">
  <div>
    <v-form ref="formApuracao" v-model="valid" lazy-validation autocomplete="off">
      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.apuracao') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao')}`" />
      </v-card-title>
      <v-row dense class="mx-4">
        <v-col cols="12" sm="6" md="3">
          <v-radio-group v-model="parametrizacao.apuracao.indManual"
            id="config_acao_apuracao_manual" row
            @change="alterarIndManual">
            <v-radio :label="$t('label.manual')" :value="true" :disabled="somenteLeitura"></v-radio>
            <v-radio :label="$t('label.automatico')" :value="false" :disabled="somenteLeitura"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col id="config_acao_apuracao_template_apuracao_div" cols="12" sm="6" md="3">
          <v-select
            :label="$t('label.template')"
            v-if="!parametrizacao.apuracao.indManual"
            id="config_acao_apuracao_template_apuracao"
            class="Form-text__invalid"
            name="config_acao_apuracao_template_apuracao"
            item-text="descricao"
            item-value="template"
            v-model="parametrizacao.apuracao.template"
            :items="templates"
            :rules="[rules.required]"
            required
            clearable
            :disabled="somenteLeitura"
            @change="alterandoTemplate(false)">
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-switch
              id="config_apuracao_habilitar_edicao_pagamento_realizado"
              ref="switchHabilitarPgtoRealizado"
              :label="`${$t('message.habilitar_edicao_pagamento_realizado')}`"
              :disabled="desabilitarFlagEdicaoValorRealizado || somenteLeitura"
              v-model="parametrizacao.apuracao.indEdicaoValorRealizado" />
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ `${$t('title.periodo_apuracao')} *` }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_periodo')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col id="periodo_apuracao" cols="12" sm="6">
          <v-checkbox
              id="periodo_apuracao_unica_vez"
              :rules="[rules.requiredLista]"
              required
              v-model="parametrizacao.apuracao.periodos"
              :label="$tc('label.unica_vez', 1)"
              :disabled="somenteLeitura"
              value="UNICA_VEZ">
          </v-checkbox>
          <v-checkbox
              id="periodo_apuracao_anual"
              :rules="[rules.requiredLista]"
              required
              v-model="parametrizacao.apuracao.periodos"
              :label="$tc('label.anual', 1)"
              :disabled="somenteLeitura"
              value="ANUAL">
          </v-checkbox>
          <v-checkbox
              id="periodo_apuracao_trimestral"
              :rules="[rules.requiredLista]"
              required
              v-model="parametrizacao.apuracao.periodos"
              :label="$tc('label.trimestral', 1)"
              :disabled="somenteLeitura"
              value="TRIMESTRAL">
          </v-checkbox>
          <v-checkbox
              id="periodo_apuracao_mensal"
              :rules="[rules.requiredLista]"
              required
              v-model="parametrizacao.apuracao.periodos"
              :label="$tc('label.mensal', 1)"
              :disabled="somenteLeitura"
              value="MENSAL">
          </v-checkbox>
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ `${$t('title.inicio_processo_apuracao')} *` }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_inicio')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6">
          <v-radio-group v-model="parametrizacao.apuracao.modoApuracao"
            id="config_acao_apuracao_inicio" :rules="[rules.requiredLista]">
            <v-radio :label="$t('label.a_partir_inicio_vigencia')" value='INICIO_VIGENCIA' :disabled="somenteLeitura"></v-radio>
            <v-radio :label="$t('label.a_partir_vigencia_concluida')" value='FIM_VIGENCIA' :disabled="somenteLeitura"></v-radio>
            <v-radio :label="$t('label.a_partir_aprovacao_acao')" value='APROVACAO_ACAO' :disabled="somenteLeitura"></v-radio>
            <v-radio
              v-if="apuracaoParalelaLiquidacao"
              :label="$t('label.a_partir_pgto_liquidado')"
              value='PGTO_LIQUIDADO'></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <div v-if="!parametrizacao.apuracao.indManual && metas && metas.length">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$t('title.metas')} *` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.meta')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-radio-group v-model="parametrizacao.apuracao.meta"
              id="config_acao_apuracao_meta"
              :rules="[rules.requiredLista]">
              <v-radio
                v-for="(m, index) in metas"
                :key="index"
                :disabled="somenteLeitura"
                :label="$t(m.text)" :value="m.value"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>

      <div
          v-if="!parametrizacao.apuracao.indManual
            && calculosApuracao
             && calculosApuracao.length">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('title.calculo_apuracao', 1)} *` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_calculo_apuracao')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-checkbox
                :id="`calculo_apuracao-${m.value}`"
                v-for="(m, index) in calculosApuracao"
                :key="index"
                :rules="[rules.requiredLista]"
                required
                v-model="parametrizacao.apuracao.calculosApuracao"
                :label="$t(m.text)"
                :disabled="somenteLeitura"
                :value="m.value">
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual && habilitaUnidadeMedida">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('label.unidade_medida', 1)}` }}
          <v-spacer />
          <tooltip-ajuda
              :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_unidade_medida')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-autocomplete
              id="apuracao_unidade_medida"
              name="apuracao_unidade_medida"
              v-model="parametrizacao.apuracao.unidadesMedida"
              :items="listaUnidadesMedida"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomUnidade"
              item-value="id"
              return-object
              :label="`${$tc('label.unidade_medida', 1)} *`"
              clearable
              multiple
              chips
              deletable-chips
              :rules="[rules.requiredLista]" required
              :error-messages="!parametrizacao.apuracao.unidadesMedida.length
               && !unidadeMedidaValida ?
                `${$t('message.campo_obrigatorio')}` : null"
              :search-input.sync="buscaListaUnidadesMedida"
              :disabled="somenteLeitura"
              @click:append="triggerUnidadeMedida"
              @click.native="buscarUnidadesMedida"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual && bonificacoes && bonificacoes.length">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('label.forma_bonificacao', 1)} *` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_bonificacao')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-checkbox :id="`forma_bonificacao-${m.value}`" v-for="(m, index) in bonificacoes" :key="index"
              :rules="[rules.requiredLista]" required
              :disabled="somenteLeitura"
              v-model="parametrizacao.apuracao.bonificacoes" :label="$t(m.text)" :value="m.value">
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div
          v-if="!parametrizacao.apuracao.indManual
            && calculosBonificacao
             && calculosBonificacao.length">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('title.calculo_bonificacao', 1)} *` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_calculo_bonificacao')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-checkbox
                :id="`calculo_bonificacao-${m.value}`"
                v-for="(m, index) in calculosBonificacao"
                :key="index"
                :rules="[rules.requiredLista]"
                required
                v-model="parametrizacao.apuracao.calculosBonificacao"
                :label="$t(m.text)"
                :disabled="somenteLeitura"
                :value="m.value">
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual && !this.isVerbaFixo && bases && bases.length">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('title.base_calculo', 1)} *` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_base_calculo')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-checkbox :id="`base_calculo-${m.value}`" v-for="(m, index) in bases" :key="index"
              :rules="[rules.requiredLista]" required
              :disabled="somenteLeitura"
              v-model="parametrizacao.apuracao.bases" :label="$t(m.text)" :value="m.value">
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual && descontos && descontos.length">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('title.desconto_calculo', 2)}` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_desconto_calculo')}`" />
        </v-card-title>
        <div class="py-3">
          <v-row class="mx-4" v-for="(m, index) in descontos" :key="index">
            <v-col cols="12" sm="3" class="py-0">
              <v-checkbox :id="`desconto_calculo-${m.value}`"
                v-model="parametrizacao.apuracao.descontos" :label="$tc(m.text, 1)" :value="m.value" :disabled="somenteLeitura"
                @change="alteradoDesconto">
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="9" class="py-0" v-if="m.exibirObrigatorio">
              <v-checkbox :id="`desconto_calculo-${m.value}-obrig`"
                          v-model="parametrizacao.apuracao.descontosObrigatorios" :label="$tc('label.obrigatorio', 1)" :value="m.value"
                          :disabled="somenteLeitura || !parametrizacao.apuracao.descontos || !parametrizacao.apuracao.descontos.includes(m.value)">
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
      </div>

      <v-card-title primary-title class="card-header mt-4" v-if="habilitaTipoPagamento && fluxoLiquidacao">
        {{ `${$tc('title.tipo_pagamento', 1)} *` }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_pagamento')}`" />
      </v-card-title>
      <v-row class="mx-4" v-if="habilitaTipoPagamento && fluxoLiquidacao">
        <v-col cols="12" sm="6" md="6">
          <v-checkbox :id="`tipo_pagamento-${p.descricao}`" v-for="(p, index) in pagamentos" :key="index"
            :rules="[rules.requiredLista]" required :disabled="somenteLeitura"
            v-model="parametrizacao.apuracao.pagamentos" :label="p.descricao" :value="p.id">
          </v-checkbox>
        </v-col>
      </v-row>

      <div>
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('title.valor_pagamento', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.valor_pagamento')}`" />
        </v-card-title>
        <v-row class="mx-4 mt-4">
          <v-col cols="12" sm="6" md="4">
            <v-switch
              id="config_edicao_apuracao_valor_pagamento"
              :label="`${$t('message.habilitar_alteracao_valor_pagamento')}`"
              @change="mudancaEdicaoCampoValorPagamento"
              v-model="parametrizacao.apuracao.indEdicaoValorPagamento"
              :disabled="liquidacaoManualHabilitada || apuracaoParalelaLiquidacao || somenteLeitura" />
            <v-switch style="padding-left:5%"
              id="justificativa_apuracao_valor_pagamento"
              :label="`${$t('message.habilitar_justificativa_alterar_valor')}`"
              v-model="parametrizacao.apuracao.indJustificativaAlterarValor"
              :disabled="!parametrizacao.apuracao.indEdicaoValorPagamento || somenteLeitura" />
          </v-col>
        </v-row>
      </div>

      <div v-if="!modoVarejo && habilitaInvestimentoSobreVendas">
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('title.investimento_vendas', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_vendas')}`" />
        </v-card-title>
        <v-row class="mx-4 mt-4">
          <v-col cols="12" sm="6" md="4">
            <v-switch
              id="config_acao_apuracao_investimento_vendas"
              :label="`${$t('message.habilitar_calculo_automatico_investimento_vendas')}`"
              :disabled="somenteLeitura"
              v-model="parametrizacao.apuracao.indCalculoAutomaticoVendas" />
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="parametrizacao.apuracao.indCalculoAutomaticoVendas">
            {{ $t('message.informe_periodo_historico_meses_anteriores') }}
          </v-col>
          <v-col cols="12" sm="6" md="2" v-if="parametrizacao.apuracao.indCalculoAutomaticoVendas">
            <v-text-field
              id="config_acao_apuracao_investimento_vendas_hist"
              v-model="parametrizacao.apuracao.periodoHistorico"
              :label="$t('label.historico_meses')"
              type="number"
              min="0"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="parametrizacao.apuracao.indCalculoAutomaticoVendas">
            {{ `${$tc('label.base_calculo', 1)}: *` }}
          </v-col>
          <v-col id="config_acao_apuracao_investimento_vendas_combo" cols="12" sm="6" md="4" v-if="parametrizacao.apuracao.indCalculoAutomaticoVendas">
            <v-checkbox v-for="(m, index) in bases" :key="index"
              :rules="[rules.requiredLista]" required :disabled="somenteLeitura"
              v-model="parametrizacao.apuracao.basesVendas" :label="$t(m.text)" :value="m.value"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div v-if="habilitaUploadEvidencia">
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('title.upload_evidencia', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.apuracao_evidencia')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col class="py-0" cols="12" sm="6" md="4">
            <v-switch
              id="config_acao_apuracao_habilitar_evidencia"
              dense
              :label="`${$t('message.habilitar_upload_evidencia')}`"
              v-model="parametrizacao.apuracao.indHabilitarEvidencia"
              :disabled="somenteLeitura"
              @change="alterarIndHabilitarEvidencia"/>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col class="py-0" cols="12" sm="6" md="4">
            <v-switch
              id="config_acao_apuracao_evidencia_obrigatoria"
              dense
              :disabled="!parametrizacao.apuracao.indHabilitarEvidencia ||
                (parametrizacao.apuracao.indEvidenciaSellout
                 || parametrizacao.apuracao.indEvidenciaSellin) || somenteLeitura"
              :label="`${$t('message.upload_evidencia_obrigatoria')}`"
              v-model="parametrizacao.apuracao.indEvidencia" />
          </v-col>
        </v-row>
        <v-row class="mx-4" v-if="!parametrizacao.apuracao.indManual">
          <v-col class="py-0" cols="12" sm="6" md="4">
            <v-switch
              id="config_acao_apuracao_evidencia_obrigatoria_sellout"
              dense
              :disabled="parametrizacao.apuracao.indHabilitarEvidencia || somenteLeitura"
              :label="`${$t('message.upload_evidencia_obrigatoria_sellout')}`"
              v-model="parametrizacao.apuracao.indEvidenciaSellout" />
          </v-col>
        </v-row>
        <v-row class="mx-4" v-if="!parametrizacao.apuracao.indManual">
          <v-col class="py-0" cols="12" sm="6" md="4">
            <v-switch
              id="config_acao_apuracao_evidencia_obrigatoria_sellin"
              dense
              :disabled="parametrizacao.apuracao.indHabilitarEvidencia || somenteLeitura"
              :label="`${$t('message.upload_evidencia_obrigatoria_sellin')}`"
              v-model="parametrizacao.apuracao.indEvidenciaSellin" />
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col class="py-0" cols="4" sm="6" md="2">
            <v-switch
              id="config_acao_apuracao_notificacao_evidencia"
              dense
              :disabled="desabilitarFlagNotificacaoEvidencia() || somenteLeitura"
              :label="`${$t('message.upload_notificacao_evidencia_apuracao')}`"
              @change="parametrizacao.apuracao.idEstruturaUsuarioNotificacao = undefined"
              v-model="parametrizacao.apuracao.indHabilitarNotificacaoEvidencia" />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              id="config_acao_apuracao_notificacao_evidencia_estrutura_usuario"
              class="config_acao_apuracao_notificacao_evidencia_estrutura_usuario"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.estrutura_usuario', 1)} *`"
              v-model="parametrizacao.apuracao.idEstruturaUsuarioNotificacao"
              :items="listaEstruturaUsuario"
              item-text="nomEstruturaUsuario"
              :disabled="!parametrizacao.apuracao.indHabilitarNotificacaoEvidencia || somenteLeitura"
              item-value="id"
              :rules="[rules.requiredEstrutura]"
              :search-input.sync="estruturaUsuarioAlteracao"
              @click:append="triggerEstruturaUsuario"
              @click.native="buscarEstruturaUsuario">
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual && habilitaValorBaseCrescimento">
        <v-card-title primary-title class="card-header mt-4">
          {{ $t('title.valor_base_crescimento') }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.valor_base_crescimento')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6" md="3">
            <v-radio-group v-model="parametrizacao.apuracao.indCrescimentoManual"
              id="config_acao_apuracao_manual" row>
              <v-radio :label="$t('label.informado_manualmente')" :value="true" :disabled="somenteLeitura"></v-radio>
              <v-radio :label="$t('label.calculado_sistema')" :value="false" :disabled="somenteLeitura"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col id="config_acao_apuracao_template_crescimento_div" cols="12" sm="6" md="3">
            <v-select
              :label="$t('label.template')"
              v-if="!parametrizacao.apuracao.indCrescimentoManual"
              id="config_acao_apuracao_template_crescimento"
              class="Form-text__invalid"
              name="config_acao_apuracao_template_crescimento"
              item-text="text"
              item-value="value"
              v-model="parametrizacao.apuracao.templateCrescimento"
              :items="templatesCrescimento"
              :rules="[rules.required]"
              required
              :disabled="somenteLeitura"
              clearable>
            </v-select>
          </v-col>
        </v-row>
      </div>

      <div>
        <v-card-title primary-title class="card-header mt-4">
          {{ $t('title.vencimento_apuracao_acao') }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.vencimento_apuracao_acao')}`" />
        </v-card-title>
        <v-row class="mx-4 mt-4">
          <v-col cols="12">
            <v-switch
              id="config_acao_apuracao_habilitar_vencimento"
              :label="`${$t('label.habilitar_vencimento_apuracao_acao')}`"
              v-if="parametrizacao.apuracaoVencimento.vencimento"
              v-model="parametrizacao.apuracaoVencimento.vencimento.valor"
              :disabled="somenteLeitura"
              @change="alterandoIndVencimento()"/>
          </v-col>
        </v-row>
        <v-row dense class="mx-8 px-8" v-if="isVencimentoHabilitado">
          <v-col cols="12" sm="6" md="4">
            <v-radio-group
              v-if="parametrizacao.apuracaoVencimento.referenciaVencimento"
              v-model="parametrizacao.apuracaoVencimento.referenciaVencimento.valor"
              :rules="[rules.required]"
              id="config_acao_apuracao_vencimento_data" column>
              <v-radio :label="$t('label.a_partir_inicio_vigencia')" value='INICIO_VIGENCIA' :disabled="somenteLeitura"></v-radio>
              <v-radio :label="$t('label.a_partir_vigencia_concluida')" value='FIM_VIGENCIA' :disabled="somenteLeitura"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="listaTiposVerba.length">
            <v-autocomplete
              id="config_acao_apuracao_vencimento_tipo_verba"
              name="config_acao_apuracao_vencimento_tipo_verba"
              v-model="parametrizacao.apuracaoVencimento.tiposVerba"
              :items="listaTiposVerba"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nome"
              item-value="id"
              :label="`${$tc('label.tipo_verba', 1)}`"
              clearable
              multiple
              chips
              deletable-chips
              return-object
              :disabled="somenteLeitura"
              @click.native="buscarTiposVerba">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense class="mx-8 px-8" v-if="isVencimentoHabilitado">
          <v-col cols="12" sm="4">
            <input-number
              id="config_acao_apuracao_vencimento_prazo"
              :label="`${$t('label.prazo_vencimento_dias')} *`"
              v-if="parametrizacao.apuracaoVencimento.prazoVencimento"
              :disabled="somenteLeitura"
              v-model="parametrizacao.apuracaoVencimento.prazoVencimento.valor"/>
          </v-col>
        </v-row>
        <v-row dense class="mx-8 px-8" v-if="isVencimentoHabilitado">
          <v-col cols="12" sm="12">
            <v-switch
              id="config_acao_apuracao_vencimento_ind_notificacao"
              :label="`${$t('label.habilitar_notificacao_vencimento')}`"
              v-if="parametrizacao.apuracaoVencimento.notificacaoVencimento"
              v-model="parametrizacao.apuracaoVencimento.notificacaoVencimento.valor"
              :disabled="somenteLeitura"
              @change="alterandoIndNotificacaoVencimento()"/>
          </v-col>
        </v-row>
        <v-row dense class="mx-12 px-12" v-if="isVencimentoHabilitado">
          <v-col cols="12" sm="6" md="4" v-if="isNotificacaoVencimentoHabilitada">
            <v-checkbox
              id="config_acao_apuracao_notificacao_funcionarios"
              class="ma-0"
              v-model="parametrizacao.apuracaoVencimento.destinatarios"
              :label="$tc('label.funcionarios_carteira', 1)"
              value="FUNCIONARIO"
              :disabled="somenteLeitura"
              :rules="[rules.requiredLista]">
            </v-checkbox>
            <v-checkbox
              id="config_acao_apuracao_notificacao_clientes"
              class="ma-0"
              v-model="parametrizacao.apuracaoVencimento.destinatarios"
              :label="$tc('label.clientes_carteira', 1)"
              value="CLIENTE"
              :disabled="somenteLeitura"
              :rules="[rules.requiredLista]">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row dense class="mx-12 px-12" v-if="isVencimentoHabilitado">
          <v-col cols="12" sm="6" md="4" v-if="isNotificacaoVencimentoHabilitada">
            <input-number
              id="config_acao_apuracao_notificacao_prazo"
              :label="`${$t('label.prazo_notificacao_dias')} *`"
              v-if="parametrizacao.apuracaoVencimento.prazoNotificacao"
              :disabled="somenteLeitura"
              v-model="parametrizacao.apuracaoVencimento.prazoNotificacao.valor"/>
          </v-col>
        </v-row>
      </div>

      <v-row justify="end" class="my-4">
        <v-btn id="config_acao_passo5_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo5_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo5_botao_proximo" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import Passo5 from './Passo5';
import Passo5Vencimento from './Passo5Vencimento';
import TooltipAjuda from '../TooltipAjuda';
import InputNumber from '../../../shared-components/inputs/InputNumber';
import { generateComputed } from '../../../common/functions/roles-computed-generator';

export default {
  name: 'ParametrizacaoAcaoPasso5',
  props: {
    id: Number,
    modoVarejo: Boolean,
    passo: Number,
    fluxoLiquidacao: Boolean,
  },
  components: {
    TooltipAjuda,
    InputNumber,
  },
  data() {
    return {
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),
      unidadeMedidaResources: this.$api.unidadeMedida(this.$resource),
      templateResources: this.$api.acaoConfiguracaoApuracaoTemplate(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      unidadeMedidaValida: true,
      liquidacaoManualHabilitada: false,

      parametrizacao: {
        apuracao: new Passo5(this.id, ''),
        apuracaoVencimento: new Passo5Vencimento(this.id),
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || '',
        requiredEstrutura: (value) => (!this.parametrizacao
          .apuracao.indHabilitarNotificacaoEvidencia
          || (this.parametrizacao.apuracao.indHabilitarNotificacaoEvidencia
            && (!!value)))
          || this.$t('message.campo_obrigatorio'),
      },
      activeBtn: 0,
      templates: [
        { descricao: this.$t('label.crescimento'), template: 'CRESCIMENTO' },
        { descricao: this.$t('label.meta_nao_se_aplica'), template: 'META_N/A' },
      ],
      templatesCrescimento: [
        { text: `${this.$tc('label.historico_ano_anterior', 1)}`, value: 'HISTORICO_ANO_ANTERIOR' },
      ],
      calculosApuracao: [
        { text: 'label.sellin_valor', value: 'SELLIN_VALOR' },
        { text: 'label.sellin_volume', value: 'SELLIN_VOLUME' },
        { text: 'label.sellout_valor', value: 'SELLOUT_VALOR' },
        { text: 'label.sellout_volume', value: 'SELLOUT_VOLUME' },
      ],
      bases: [
        { text: 'label.valor_liquido', value: 'VALOR_LIQUIDO' },
        { text: 'label.valor_bruto', value: 'VALOR_BRUTO' },
      ],
      bonificacoes: [
        { text: 'label.fixo', value: 'FIXO' },
        { text: 'label.percentual', value: 'PERCENTUAL' },
      ],
      calculosBonificacao: [
        { text: 'label.sellin', value: 'SELLIN' },
        { text: 'label.sellout', value: 'SELLOUT' },
      ],
      descontos: [
        { text: 'label.ipi', value: 'IPI' },
        { text: 'label.icms', value: 'ICMS' },
        { text: 'label.pis_cofins', value: 'PIS_COFINS' },
        { text: 'label.devolucao', value: 'DEVOLUCOES' },
      ],
      metas: [
        { text: 'label.nao_se_aplica', value: 'N/A' },
        { text: 'label.minima', value: 'MINIMA' },
        { text: 'label.intervalo', value: 'INTERVALO' },
      ],
      habilitaUnidadeMedida: true,
      habilitaValorBaseCrescimento: true,
      habilitaUploadEvidencia: true,
      habilitaTipoPagamento: true,
      habilitaInvestimentoSobreVendas: true,
      pagamentos: [],
      valid: true,
      apuracaoBanco: [],
      listaUnidadesMedida: [],
      listaTiposVerba: [],
      buscaListaUnidadesMedida: null,
      fluxoTrade: null,
      investimentos: [],
      msgValidacao: this.$t('errors.campos_obrigatorios.nao_informados'),
      listaEstruturaUsuario: [],
      estruturaUsuarioAlteracao: null,
    };
  },
  computed: {
    isVerbaFixo() {
      return this.parametrizacao && this.parametrizacao.apuracao
        && this.parametrizacao.apuracao.bonificacoes
        && this.parametrizacao.apuracao.bonificacoes.length === 1
        && this.parametrizacao.apuracao.bonificacoes[0] === 'FIXO';
    },
    apuracaoParalelaLiquidacao() {
      return !!this.fluxoTrade
        && this.fluxoTrade.includes('APURACAO_E_LIQUIDACAO');
    },
    contemApuracao() {
      return !!this.fluxoTrade
        && this.fluxoTrade.includes('APURACAO');
    },
    fluxoElegivelParaFlagEdicaoValorRealizado() {
      return !this.contemApuracao || this.apuracaoParalelaLiquidacao;
    },
    desabilitarFlagEdicaoValorRealizado() {
      return this.fluxoElegivelParaFlagEdicaoValorRealizado
        || (this.parametrizacao.apuracao.template === ''
        || this.parametrizacao.apuracao.template === 'META_N/A');
    },
    isVencimentoHabilitado() {
      return this.parametrizacao
        && this.parametrizacao.apuracaoVencimento
        && this.parametrizacao.apuracaoVencimento.vencimento
        && this.parametrizacao.apuracaoVencimento.vencimento.valor;
    },
    isNotificacaoVencimentoHabilitada() {
      return this.parametrizacao
        && this.parametrizacao.apuracaoVencimento
        && this.parametrizacao.apuracaoVencimento.notificacaoVencimento
        && this.parametrizacao.apuracaoVencimento.notificacaoVencimento.valor;
    },
    ...generateComputed('param_acao', [
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  watch: {
    isVerbaFixo(val) {
      if (val) {
        this.parametrizacao.apuracao.calculosApuracao = [];
        this.parametrizacao.apuracao.calculosBonificacao = [];
        this.parametrizacao.apuracao.bases = [];
      }
    },
    estruturaUsuarioAlteracao(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarEstruturaUsuario(val);
      }, 500);
    },
    parametrizacao: {
      handler() {
        this.validarUnidadeMedida();
      },
      deep: true,
    },
  },
  methods: {
    alteradoDesconto(descontos) {
      this.parametrizacao.apuracao.desmarcarDescontoObrigatorio(descontos);
    },
    setFluxos(fluxos) {
      this.fluxoTrade = fluxos.split(',');
      if (this.apuracaoParalelaLiquidacao) {
        this.parametrizacao.apuracao.indEdicaoValorRealizado = false;
        this.parametrizacao.apuracao.indEdicaoValorPagamento = false;
      }
    },
    setInvestimentos(listaInvestimentos) {
      this.investimentos = listaInvestimentos;
      this.buscarTiposVerba();
    },
    alterarIndManual() {
      if (this.parametrizacao.apuracao.indManual) {
        this.reiniciarCamposTemplate();
        this.parametrizacao.apuracao.template = '';
        this.parametrizacao.apuracao.indEdicaoValorRealizado = false;
      } else {
        this.alterandoTemplate();
      }
    },
    alterandoTemplate(naoLimpar) {
      if (!this.fluxoElegivelParaFlagEdicaoValorRealizado && !naoLimpar) {
        this.parametrizacao
          .apuracao.indEdicaoValorRealizado = !this.parametrizacao.apuracao.indManual
            && !(this.parametrizacao.apuracao.template === ''
            || this.parametrizacao.apuracao.template === 'META_N/A');
      }
      this.templates.forEach((template) => {
        if (this.parametrizacao.apuracao.template === template.template) {
          if (template.desAtributos) {
            this.preencherCamposTemplate(template.desAtributos, naoLimpar);
          } else {
            this.reiniciarCamposTemplate();
          }
        }
      });
    },
    reiniciarCamposTemplate() {
      this.preencherCamposTemplate({
        metas: [
          { text: 'label.nao_se_aplica', value: 'N/A' },
          { text: 'label.minima', value: 'MINIMA' },
          { text: 'label.intervalo', value: 'INTERVALO' },
        ],
        calculosApuracao: [
          { text: 'label.sellin_valor', value: 'SELLIN_VALOR' },
          { text: 'label.sellin_volume', value: 'SELLIN_VOLUME' },
          { text: 'label.sellout_valor', value: 'SELLOUT_VALOR' },
          { text: 'label.sellout_volume', value: 'SELLOUT_VOLUME' },
        ],
        calculosBonificacao: [
          { text: 'label.sellin', value: 'SELLIN' },
          { text: 'label.sellout', value: 'SELLOUT' },
        ],
        bonificacoes: [
          { text: 'label.fixo', value: 'FIXO' },
          { text: 'label.percentual', value: 'PERCENTUAL' },
        ],
        bases: [
          { text: 'label.valor_liquido', value: 'VALOR_LIQUIDO' },
          { text: 'label.valor_bruto', value: 'VALOR_BRUTO' },
        ],
        descontos: [
          { text: 'label.ipi', value: 'IPI' },
          { text: 'label.icms', value: 'ICMS' },
          { text: 'label.pis_cofins', value: 'PIS_COFINS' },
          { text: 'label.devolucao', value: 'DEVOLUCOES' },
        ],
        unidadeMedida: true,
        valorBaseCrescimento: true,
        uploadEvidencia: true,
        tipoPagamento: true,
        investimentoSobreVerba: true,
      });
    },
    preencherCamposTemplate(confTemplate, naoLimpar) {
      this.metas = [];
      setTimeout(() => {
        this.metas = confTemplate.metas;
      }, 2E2);
      this.calculosApuracao = confTemplate.calculosApuracao;
      this.calculosBonificacao = confTemplate.calculosBonificacao;
      this.bonificacoes = confTemplate.bonificacoes;
      this.habilitaUnidadeMedida = confTemplate.unidadeMedida;
      this.bases = confTemplate.bases;
      this.habilitaValorBaseCrescimento = confTemplate.valorBaseCrescimento;
      this.habilitaUploadEvidencia = confTemplate.uploadEvidencia;
      this.habilitaTipoPagamento = confTemplate.tipoPagamento;
      this.habilitaInvestimentoSobreVendas = confTemplate.tipoInvestimento;
      this.descontos = confTemplate.descontos;
      if (!naoLimpar) {
        this.parametrizacao.apuracao.limparPassoPeloTemplate(confTemplate);
      }
      this.$refs.formApuracao.resetValidation();
    },
    mudancaEdicaoCampoValorPagamento() {
      if (!this.parametrizacao.apuracao.indEdicaoValorPagamento) {
        this.parametrizacao.apuracao.indJustificativaAlterarValor = false;
      }
    },
    alterarIndHabilitarEvidencia() {
      if (!this.parametrizacao.apuracao.indHabilitarEvidencia) {
        this.parametrizacao.apuracao.indEvidencia = false;
        this.parametrizacao.apuracao.indEvidenciaSellout = false;
        this.parametrizacao.apuracao.indEvidenciaSellin = false;
      }
    },
    alterandoIndVencimento() {
      if (!this.isVencimentoHabilitado) {
        this.parametrizacao.apuracaoVencimento = new Passo5Vencimento(this.id);
      }
    },
    alterandoIndNotificacaoVencimento() {
      if (!this.parametrizacao.apuracaoVencimento.notificacaoVencimento.valor) {
        this.parametrizacao.apuracaoVencimento.prazoNotificacao.valor = 0;
        this.parametrizacao.apuracaoVencimento.destinatarios = [];
      }
    },
    buscarTiposBeneficios() {
      this.tipoBeneficioResources.listarAtivos().then((response) => {
        this.pagamentos = response.data;
      });
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_ACAO_CANCELAR');
    },
    proximoPasso() {
      this.msgValidacao = this.$t('errors.campos_obrigatorios.nao_informados');
      if (this.$refs.formApuracao.validate() && this.validarUnidadeMedida()
        && this.validarPrazoVencimento() && this.validarPrazoNotificacao()) {
        this.$emit('PARAMETRIZACAO_ACAO_CONTINUAR', this.passo + 1, this.id);
        this.$emit('PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
      } else {
        this.$toast(this.msgValidacao);
      }
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_ACAO_VOLTAR', this.passo - 1);
    },
    prepararPasso() {
      if (this.id) {
        this.obter(this.id);
      } else {
        this.buscarTemplates();
      }
    },
    obter(id) {
      this.paramAcaoResources.obterPasso5({ id }, { id }).then((response) => {
        this.apuracaoBanco = response.data.apuracao;
        this.apuracaoVencimentoBanco = response.data.apuracaoVencimento;
        this.preencherDadosBase();
        this.listaUnidadesMedida = [...this.parametrizacao.apuracao.unidadesMedida || []];
        this.listaTiposVerba = [...this.parametrizacao.apuracaoVencimento.tiposVerba || []];
        this.$forceUpdate();
        this.buscarTemplates(true);
      }, (err) => {
        this.$error(this, err);
        this.buscarTemplates();
      });
    },
    preencherDadosBase() {
      this.parametrizacao.apuracao = new Passo5(this.apuracaoBanco.id,
        this.apuracaoBanco.nomeTipoVerba, this.apuracaoBanco.indManual,
        this.apuracaoBanco.template, this.apuracaoBanco.periodos,
        this.apuracaoBanco.indApurarInicio, this.apuracaoBanco.meta,
        this.apuracaoBanco.calculosApuracao, this.apuracaoBanco.bases,
        this.apuracaoBanco.bonificacoes, this.apuracaoBanco.calculosBonificacao,
        this.apuracaoBanco.pagamentos, this.apuracaoBanco.basesVendas,
        this.apuracaoBanco.indEvidencia, this.apuracaoBanco.indCalculoAutomaticoVendas,
        this.apuracaoBanco.indEdicaoValorRealizado,
        this.apuracaoBanco.indEdicaoValorPagamento,
        this.apuracaoBanco.indJustificativaAlterarValor,
        this.apuracaoBanco.periodoHistorico, this.apuracaoBanco.indEvidenciaSellin,
        this.apuracaoBanco.indEvidenciaSellout, this.apuracaoBanco.indCrescimentoManual,
        this.apuracaoBanco.unidadesMedida, this.apuracaoBanco.descontos, this.apuracaoBanco.descontosObrigatorios,
        this.apuracaoBanco.indHabilitarEvidencia, this.apuracaoBanco.idAcaoTipo,
        this.apuracaoBanco.indHabilitarNotificacaoEvidencia,
        this.apuracaoBanco.idEstruturaUsuarioNotificacao,
        this.apuracaoBanco.templateCrescimento, this.apuracaoBanco.modoApuracao);

      this.parametrizacao.apuracaoVencimento = new Passo5Vencimento(this.apuracaoBanco.id,
        this.apuracaoVencimentoBanco.vencimento, this.apuracaoVencimentoBanco.referenciaVencimento,
        this.apuracaoVencimentoBanco.prazoVencimento,
        this.apuracaoVencimentoBanco.notificacaoVencimento,
        this.apuracaoVencimentoBanco.prazoNotificacao, this.apuracaoVencimentoBanco.destinatarios,
        this.apuracaoVencimentoBanco.tiposVerba);

      this.$forceUpdate();
    },
    buscarTiposVerba() {
      return this.orcamentoTipoVerbaResource.buscarAtivos()
        .then((res) => {
          this.listaTiposVerba = [];
          res.data.forEach((item) => {
            const sel = this.investimentos
              .filter((el) => el.idTipoVerba === item.id);
            if (sel.length) {
              const tipoVerba = {
                id: item.id,
                nome: item.nome,
              };
              this.listaTiposVerba.push(tipoVerba);
            }
          }, this);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarUnidadesMedida() {
      const param = {
        nom_unidade: this.buscaListaUnidadesMedida,
        tamanhoPagina: 500,
        numeroPagina: 0,
      };
      return this.unidadeMedidaResources.listarAtivos(param)
        .then((response) => {
          this.listaUnidadesMedida = response.data.resposta;
        });
    },
    triggerUnidadeMedida() {
      document.getElementById('apuracao_unidade_medida').click();
    },
    validarPrazoVencimento() {
      if (this.isVencimentoHabilitado) {
        const prazoVencimento = this.parametrizacao.apuracaoVencimento.prazoVencimento.valor;
        if (prazoVencimento <= 0) {
          this.msgValidacao = this.$t('errors.valor.maior_zero_inteiro');
        }
        return prazoVencimento && prazoVencimento > 0;
      }
      return true;
    },
    validarPrazoNotificacao() {
      if (this.isNotificacaoVencimentoHabilitada) {
        const prazoNotificacao = this.parametrizacao.apuracaoVencimento.prazoNotificacao.valor;
        const prazoVencimento = this.parametrizacao.apuracaoVencimento.prazoVencimento.valor;
        const isPrazoNotificacaoValido = prazoNotificacao && prazoNotificacao > 0
          && prazoNotificacao < prazoVencimento;
        if (prazoNotificacao <= 0) {
          this.msgValidacao = this.$t('errors.valor.maior_zero_inteiro');
        } else if (!isPrazoNotificacaoValido) {
          this.msgValidacao = this.$t('errors.vencimento.prazo_notificacao_maior_vencimento');
        }
        return isPrazoNotificacaoValido;
      }
      return true;
    },
    validarUnidadeMedida() {
      if (this.parametrizacao.apuracao.calculosApuracao.filter((c) => c === 'SELLIN_VOLUME' || c === 'SELLOUT_VOLUME').length) {
        this.habilitaUnidadeMedida = true;
      } else {
        this.habilitaUnidadeMedida = false;
      }
      const volume = this.parametrizacao.apuracao && this.habilitaUnidadeMedida;
      if (volume && volume.length && !this.parametrizacao.apuracao.unidadesMedida.length) {
        this.unidadeMedidaValida = false;
        return false;
      }
      this.unidadeMedidaValida = true;
      return true;
    },
    desabilitarFlagNotificacaoEvidencia() {
      if (this.parametrizacao.apuracao.indManual) {
        return !this.parametrizacao.apuracao.indHabilitarEvidencia;
      }
      return !(this.parametrizacao.apuracao.indHabilitarEvidencia
       || this.parametrizacao.apuracao.indEvidenciaSellout
       || this.parametrizacao.apuracao.indEvidenciaSellin);
    },
    triggerEstruturaUsuario() {
      this.triggerClick('config_acao_fluxo_estrutura');
    },
    buscarEstruturaUsuario(nome) {
      const nomeInput = this.formataInput(nome);
      const params = {
        nome: nomeInput,
        tamanhoPagina: 50000,
      };
      this.estruturaUsuarioResources.listarAtivos(params)
        .then((r) => {
          this.listaEstruturaUsuario = r.data
            .resposta
            .map((estrutura) => (
              {
                id: estrutura.id,
                nomEstruturaUsuario: estrutura.nomEstruturaUsuario,
              }));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formataInput(input) {
      return typeof input === 'string' ? input : null;
    },
    buscarTemplates(naoLimpar) {
      this.templateResources.buscar()
        .then((response) => {
          this.templates = response.data;
          if (this.parametrizacao.apuracao.template) {
            this.alterandoTemplate(naoLimpar);
          }
        });
    },
    setLiquidacaoManual(liqManualHabilitada) {
      this.liquidacaoManualHabilitada = liqManualHabilitada;
      if (liqManualHabilitada) {
        this.parametrizacao.apuracao.indJustificativaAlterarValor = false;
        this.parametrizacao.apuracao.indEdicaoValorPagamento = false;
      }
    },
  },
  mounted() {
    this.parametrizacao.apuracao = new Passo5(this.id, '', true);
    this.parametrizacao.apuracaoVencimento = new Passo5Vencimento(this.id);
    this.buscarTiposBeneficios();
    this.buscarEstruturaUsuario();
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
