import Home from '../../produto/spa/Home';
import NotFound from '../../produto/spa/NotFound';
import coreRoutes from '../../produto/config/router/core-router';

import ajusteVerbaSuzano from './ajuste-verba-suzano';
import planejamentoAcaoMassivo from './planejamento-acao-massivo';
import estornoContaCorrente from './estorno-conta-corrente';
import { hideLoading } from '../../produto/common/functions/loading';
import relatoriosIntegracao from './relatorios-integracao';
import planejamentoContratoSuzano from './planejamento-contrato-suzano';

const configureGuards = (router) => {
  router.afterEach((route) => {
    if (route.params.menu) {
      hideLoading();
    }
  });
};

// sobrescreve a definicao de rota para ajuste de verba
const suzanoRoutes = [
  ...coreRoutes,
  relatoriosIntegracao,
  ajusteVerbaSuzano,
  planejamentoAcaoMassivo,
  estornoContaCorrente,
  planejamentoContratoSuzano,
];

export const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home,
    redirect: '/dashboard-inicial',
    children: suzanoRoutes,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export const VueRouterObject = {
  routes,
  saveScrollPosition: true,
};

export default (VueRouter) => {
  const vueRouter = new VueRouter(VueRouterObject);
  configureGuards(vueRouter);
  return vueRouter;
};
