import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'cadastro');

const planejamentoAcaoActions = {
  buscarClientesPagadores: { method: 'GET', url: `${basePath}/campos-suzano/cliente/pagador` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoActions);

export const buscarClientes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos-suzano/cliente', parametros).doGet();
export const buscarHoldings = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos-suzano/holding', parametros).doGet();
export const buscarContratos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos-suzano/contrato', parametros).doGet();
export const buscarContasCorrentesElegiveis = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'conta_corrente_suzano/elegiveis', parametros).doGet();
