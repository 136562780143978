import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('orcamento', 'extrato');

const contaCorrenteAjusteVerbaActions = {
  indBaseMassiva: { method: 'GET', url: `${basePath}/base-massiva` },
  tiposVerba: { method: 'GET', url: `${basePath}/tipo-verba` },
  periodos: { method: 'GET', url: `${basePath}/tipo-verba/{id}/periodo` },
  orcamentos: { method: 'GET', url: `${basePath}/tipo-verba/{idTipoVerba}/periodo/{idPeriodo}/orcamento` },
  linhasInvestimentos: { method: 'GET', url: `${basePath}/tipo-verba/{idTipoVerba}/periodo/{idPeriodo}/orcamento/{idOrcamento}/linha-investimento` },
  contasCorrentes: { method: 'GET', url: `${basePath}/tipo-verba/{idTipoVerba}/periodo/{idPeriodo}/orcamento/{idOrcamento}/conta-corrente?{&idsLinhaInvestimento*}` },
  eventos: { method: 'GET', url: `${basePath}/conta-corrente/eventos?{&idsContaCorrente*}` },
  detalhesEvento: { method: 'GET', url: `${basePath}/conta-corrente/detalhes?{&idsContaCorrente*}` },
  carregarContaCorrente: { method: 'GET', url: `${basePath}/conta-corrente?{&ids*}` },
};

export default (resource) => resource(`${basePath}`, {}, contaCorrenteAjusteVerbaActions);
