const mutations = {
  registrarContas(state, contasCorrentes) {
    state.contasCorrentes = contasCorrentes
      .reduce((ac, conta) => {
        ac[conta.idContaCorrente] = conta.saldo;
        return ac;
      }, {});
  },
  setContas(state, contasCorrentes) {
    state.contasCorrentes = contasCorrentes;
  },
  alterarSaldoConta(state, conta) {
    const saldoAtual = state.contasCorrentes[conta.idContaCorrente].toFixed(2);
    state.contasCorrentes[conta.idContaCorrente] = saldoAtual - conta.diferenca;
  },
};

const actions = {
  registrarContas({ commit }, contasCorrentes) {
    commit('registrarContas', contasCorrentes);
  },
  setContas({ commit }, contasCorrentes) {
    commit('setContas', contasCorrentes);
  },
  alterarSaldoConta({ commit }, conta) {
    commit('alterarSaldoConta', conta);
  },
};

const getters = {
  getContasCorrentes(state) {
    return state.contasCorrentes;
  },
  existeContaSaldoNegativo(state) {
    return Object.keys(state.contasCorrentes)
      .findIndex((id) => state.contasCorrentes[id] < 0) > 0;
  },
  contasComSaldoNegativo(state) {
    return Object.keys(state.contasCorrentes)
      .filter((id) => state.contasCorrentes[id] < 0)
      .map((id) => parseInt(id, 10));
  },
};

const state = {
  contasCorrentes: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
