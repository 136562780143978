<template>
  <v-card style="margin-bottom:1em">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.relatorio_investimento_cliente', 1) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
                @click="fixaFiltros"
                :class="filtrosFixosClass"
                style="margin-right: 10px">
          fa-thumbtack
        </v-icon>
        <icon-filtro @click="toggleMostrarFiltros" :filtros="filtrosExportacaoPagamento"/>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
            :ordenacao-campos="ordenacaoCampos"
            :campos-formulario="camposFormulario"
            :formulario-filtros="true"
            :ignora-obrigatorios="false"
            :metadados-entidade="metadadosCliente"
            :objeto="filtrosExportacaoPagamento"
            ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
               color="accent"
        >{{ $t('label.limpar_filtros') }}
        </v-btn>
        <v-btn @click="aplicarFiltros"
               color="primary"
        >{{ $t('label.exportar') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { skipLoading } from '@/produto/common/functions/loading';
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import {countRelatorioInvestimentoCliente} from '@/common/resources/quantidade-registro-relatorio-suzano'; // eslint-disable-line
import IconFiltro from '@/produto/shared-components/filtros/IconFiltro';
import RelatorioExportacaoInvestimentoClienteFiltrosCampos from './RelatorioExportacaoInvestimentoClienteFiltrosCampos';

export default {
  name: 'RelatorioExportacaoInvestimentoCliente',
  mixins: [
    RelatorioExportacaoInvestimentoClienteFiltrosCampos,
  ],
  computed: {
    ...mapGetters('metadados', [
      'getClienteMetadado',
    ]),
    metadadosCliente() {
      if (this.getClienteMetadado && this.getClienteMetadado.mapaEntidades) {
        const { mapaEntidades } = this.getClienteMetadado;
        const mapaNaoObrigatorio = {};
        Object.keys(mapaEntidades).forEach((entidade) => {
          const novaEntidade = { ...mapaEntidades[entidade] };
          novaEntidade.depObrigatoria = false;
          mapaNaoObrigatorio[entidade] = novaEntidade;
        });
        const metadado = { ...this.getClienteMetadado };
        metadado.mapaEntidades = mapaNaoObrigatorio;
        return metadado;
      }
      return undefined;
    },
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  data() {
    return {

      ordenacaoCampos: [
        'data_inicio',
        'data_fim',
        'holding',
        'regional',
        'tipo_de_verba',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosExportacaoPagamento: {},

      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  methods: {
    ...mapActions('metadados', [
      'setClienteMetadado',
    ]),
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
            || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }

        const diferencaMeses = dtFinal.diff(dtInicio, 'months');

        if (diferencaMeses > 12) {
          this.$toast(this.$t('errors.data.periodo_excedido12'));
          return false;
        }
        return true;
      }
      return true;
    },
    resetaFiltros() {
      this.filtrosExportacaoPagamento = {};
    },
    aplicarFiltros() {
      let params = {};

      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      if (this.filtrosExportacaoPagamento.regional != null) {
        const idReginal = {
          id_regional: this.filtrosExportacaoPagamento.regional.map((regional) => regional.id),
        };
        params = {
          ...this.$refs.container.getValoresCamposPadrao(),
          ...idReginal,
        };
      } else {
        params = {
          ...this.$refs.container.getValoresCamposPadrao(),
        };
      }
      let quantidadeRegistros = 0;

      countRelatorioInvestimentoCliente(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoInvestimentoCliente__AplicaFiltros',
              params,
              'investimento-cliente',
              'job');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-investimento-cliente';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-investimento-cliente';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosAjusteVerba;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
  beforeMount() {
    if (!this.getClienteMetadado || !this.getClienteMetadado.mapaEntidades) {
      this.setClienteMetadado({
        resource: this.metadadoResource.listarCliente,
        params: null,
      });
    }
  },
};
</script>
