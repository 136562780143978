import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/pagamento-acao');

const workflowPagamentoAcaoActions = {
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idPagamento}` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idPagamento}` },
  aprovador: { method: 'GET', url: `${basePath}/{idPagamento}/passos/aprovador` },
  reprovar: { method: 'PUT', url: `${basePath}/{idPagamento}/passos/reprovar` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idPagamento}/passos/aprovar` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idPagamento}/passos/analise` },
  status: { method: 'GET', url: `${basePath}/{idPagamento}/status` },
  statusPasso: { method: 'GET', url: `${basePath}/{idPagamento}/passos/status` },
  existeFluxoCadastrado: { method: 'GET', url: `${basePath}/{idPagamento}/fluxo-cadastrado` },
  cancelar: { method: 'PUT', url: `${basePath}/{idPagamento}/cancelar` },
  nomeFornecedorAprovador: { method: 'GET', url: `${basePath}/fornecedor-aprovador/{id}` },
};
export default (resource) => resource(`${basePath}`, {}, workflowPagamentoAcaoActions);

export const pendencias = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();
