<template>
  <v-card style="margin-bottom:1em">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.relatorio_inconsistencias_integracao', 2) }}</div>
        <v-spacer></v-spacer>
        <icon-filtro @click="toggleMostrarFiltros" :filtros="filtrosExportacaoInconsistencias" />
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :objeto="filtrosExportacaoInconsistencias"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import { countRelatorioInconsistenciasIntegracao } from '@/common/resources/quantidade-registro-relatorio-suzano'; // eslint-disable-line
import IconFiltro from '@/produto/shared-components/filtros/IconFiltro';
import RelatorioExportacaoInconsistenciasIntegracaoFiltrosCampos from './RelatorioExportacaoInconsistenciasIntegracaoFiltrosCampos';

export default {
  name: 'RelatorioExportacaoInconsistenciasIntegracao',
  mixins: [
    RelatorioExportacaoInconsistenciasIntegracaoFiltrosCampos,
  ],
  computed: {
    ...mapGetters('metadados', [
      'getClienteMetadado',
    ]),
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  data() {
    return {
      ordenacaoCampos: [
        'data_inicio',
        'data_fim',
        'tipo_integracao',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosExportacaoInconsistencias: {},
    };
  },
  methods: {
    ...mapActions('metadados', [
      'setClienteMetadado',
    ]),
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        const diferencaMeses = dtFinal.diff(dtInicio, 'months');

        if (diferencaMeses > 12) {
          this.$toast(this.$t('errors.data.periodo_excedido12'));
          return false;
        }
        return true;
      }
      return true;
    },
    resetaFiltros() {
      this.filtrosExportacaoInconsistencias = {};
    },
    aplicarFiltros() {
      let params = {};
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      let quantidadeRegistros = 0;

      countRelatorioInconsistenciasIntegracao(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoInconsistenciasIntegracao__AplicaFiltros',
              params,
              'inconsistencias-integracao',
              'job');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
};
</script>
