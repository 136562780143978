import { buscarHoldings } from '../../../produto/common/resources/liquidacao/pagamento';
import { buscarClientes } from '../../../common/resources/suzano-planejamento-acao-cadastro';

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      relatoriosResources: this.$api.relatorios(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
            vlrObrigatorio: false,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
            vlrObrigatorio: false,
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'LISTA',
            filter: () => true,
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresCamposPadrao(),
                };
                return buscarHoldings(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'cliente',
            nomCampoId: 'id_cliente',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => buscarClientes({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'hierarquia1',
            nomCampoId: 'id_hierarquia1',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  idNivelExtensao: 8,
                  indiceRecursao: 2,
                  ...this.$refs.container.getValoresCamposPadrao(),
                };
                return this.extensaoResource.listarAtivos(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'codicao_acao',
            nomCampoId: 'codicao_acao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  valor: 'COM_BRUTO',
                  texto: this.$tc('condicao.comercial_bruto'),
                },
                {
                  valor: 'COM_LIQUID',
                  texto: this.$tc('condicao.comercial_liquido'),
                },
                {
                  valor: 'LOG_BRUTO',
                  texto: this.$tc('condicao.logistico_bruto'),
                },
                {
                  valor: 'LOG_LIQUID',
                  texto: this.$tc('condicao.logistico_liquido'),
                },
                {
                  valor: 'CRESC_BRUT',
                  texto: this.$tc('condicao.crescimento_bruto'),
                },
                {
                  valor: 'CRESC_LIQU',
                  texto: this.$tc('condicao.crescimento_liquido'),
                },
              ],
            },
          },
          {
            labelCampo: 'situacao_contrato',
            nomCampoId: 'situacao_contrato',
            tipoCampo: 'LISTA',
            vlrObrigatorio: false,
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  valor: 'VIGENTE',
                  texto: this.$tc('status_entidade.vigente'),
                },
                {
                  valor: 'ENCERRADO',
                  texto: this.$tc('status_entidade.encerrado'),
                },
              ],
            },
          },
        ],
      },
    };
  },
};
