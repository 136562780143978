import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('api', 'relatorios/integracao');

const relatoriosIntegracaoActions = {
  reenviarIntegracaoProvisao: { method: 'PUT', url: `${basePath}/provisao/reenvio/{idProvisao}` },
  reenviarIntegracaoAjusteProvisao: { method: 'PUT', url: `${basePath}/ajuste-provisao/reenvio/{idProvisao}` },
  reenviarIntegracaoAjusteProvisaoFracao: { method: 'PUT', url: `${basePath}/ajuste-provisao/reenvio/{idProvisao}/fracao/{idFracao}` },
  reenviarIntegracaoCriacaoPagamento: { method: 'PUT', url: `${basePath}/criacao-pagamento/{idPagamentoIntegracao}/reenvio` },
  buscarItensCriacaoPagamento: { method: 'GET', url: `${basePath}/criacao-pagamento/{idPagamentoIntegracao}/itens` },
  reenviarIntegracaoCancelamentoZver: { method: 'PUT', url: `${basePath}/cancelamento-zver/{idPagamentoIntegracao}/reenvio` },
  buscarItensCancelamentoZver: { method: 'GET', url: `${basePath}/cancelamento-zver/{idPagamentoIntegracao}/itens` },
};

export default (resource) => resource(`${basePath}`, {}, relatoriosIntegracaoActions);

export const buscaIntegracoesProvisao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'provisao/busca', parametros).doGet();
export const buscaIntegracoesAjusteProvisao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'ajuste-provisao/busca', parametros).doGet();
export const buscaIntegracoesCriacaoPagamento = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'criacao-pagamento/busca', parametros).doGet();
export const buscaIntegracoesCancelamentoZver = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'cancelamento-zver/busca', parametros).doGet();

export const reenviarIntegracaoProvisaoEmMassa = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'provisao/reenvio/em-massa', parametros).doPut();
export const reenviarIntegracaoAjusteProvisaoEmMassa = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'ajuste-provisao/reenviar', parametros).doPut();
export const reenviarIntegracaoCriacaoPagamentoEmMassa = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'criacao-pagamento/reenvio/em-massa', parametros).doPut();
export const reenviarIntegracaoCancelamentoZverEmMassa = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'cancelamento-zver/reenvio/em-massa', parametros).doPut();
