var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{staticClass:"elevation-1",on:{"change":_vm.filtrar},model:{value:(_vm.tabSelecionada),callback:function ($$v) {_vm.tabSelecionada=$$v},expression:"tabSelecionada"}},[_c('v-tab',{attrs:{"href":"#tab-eventos"}},[_vm._v(" "+_vm._s(_vm.$t('label.extrato_conta_corrente_resumido'))+" ")]),_c('v-tab',{attrs:{"href":"#tab-detalhes"}},[_vm._v(" "+_vm._s(_vm.$t('label.extrato_conta_corrente_detalhado'))+" ")]),_c('v-tabs-items',{model:{value:(_vm.tabSelecionada),callback:function ($$v) {_vm.tabSelecionada=$$v},expression:"tabSelecionada"}},[_c('v-tab-item',{attrs:{"id":"tab-eventos"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.eventos,"options":_vm.options,"server-items-length":_vm.totalEventos,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
          itemsPerPageOptions: [10, 25, 50],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dta_criacao",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.dtaCriacao)+" ")]}},{key:"item.vlr_evento",fn:function(ref){
        var item = ref.item;
return [(item.isOrcamentoPercentual)?_c('div',{style:(item.natureza === 'CREDITO' ? 'color: blue;' : 'color: red;')},[_vm._v(" "+_vm._s(_vm.getPercent(item.vlrEvento))+" ")]):_c('div',{style:(item.natureza === 'CREDITO' ? 'color: blue;' : 'color: red;')},[_vm._v(" "+_vm._s(_vm.getMoney(item.vlrEvento))+" ")])]}}])})],1),_c('v-tab-item',{attrs:{"id":"tab-detalhes"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDetalhes,"items":_vm.detalhesEventos,"options":_vm.optionsDetalhes,"server-items-length":_vm.totalDetalhesEventos,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
          itemsPerPageOptions: [10, 25, 50],
        }},on:{"update:options":function($event){_vm.optionsDetalhes=$event}},scopedSlots:_vm._u([{key:"item.dta_criacao",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.dtaCriacao)+" ")]}},{key:"item.vlr_evento",fn:function(ref){
        var item = ref.item;
return [(item.isOrcamentoPercentual)?_c('div',{style:(item.natureza === 'CREDITO' ? 'color: blue;' : 'color: red;')},[_vm._v(" "+_vm._s(_vm.getPercent(item.vlrEvento))+" ")]):_c('div',{style:(item.natureza === 'CREDITO' ? 'color: blue;' : 'color: red;')},[_vm._v(" "+_vm._s(_vm.getMoney(item.vlrEvento))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }