export default class Passo5 {
  constructor(id, nomeTipoVerba, indManual = true, template = '', periodos = [], indApurarInicio = true, meta = null,
    calculosApuracao = [], bases = [], bonificacoes = [], calculosBonificacao = [], pagamentos = [],
    basesVendas = [], indEvidencia = false, indCalculoAutomaticoVendas = false,
    indEdicaoValorRealizado = false, indEdicaoValorPagamento = false,
    indJustificativaAlterarValor = false, periodoHistorico = null,
    indEvidenciaSellin = false, indEvidenciaSellout = false,
    indCrescimentoManual = true, unidadesMedida = [], descontos = [], descontosObrigatorios = [],
    indHabilitarEvidencia = false, idAcaoTipo = null, indHabilitarNotificacaoEvidencia = false,
    idEstruturaUsuarioNotificacao = null, templateCrescimento = null,
    modoApuracao = null, indHabilitarHerancaConfig = false, idTipoAcaoAgrupadora = null) {
    this.id = id;
    this.nomeTipoVerba = nomeTipoVerba;
    this.indManual = indManual;
    this.template = template;
    this.indApurarInicio = indApurarInicio;
    this.indEvidencia = indEvidencia;
    this.indCalculoAutomaticoVendas = indCalculoAutomaticoVendas;
    this.indEdicaoValorRealizado = indEdicaoValorRealizado;
    this.indEdicaoValorPagamento = indEdicaoValorPagamento;
    this.indJustificativaAlterarValor = indJustificativaAlterarValor;
    this.periodoHistorico = periodoHistorico;
    this.indEvidenciaSellin = indEvidenciaSellin;
    this.indEvidenciaSellout = indEvidenciaSellout;
    this.indHabilitarEvidencia = indHabilitarEvidencia;
    this.periodos = periodos;
    this.meta = meta;
    this.calculosApuracao = calculosApuracao;
    this.bases = bases;
    this.bonificacoes = bonificacoes;
    this.calculosBonificacao = calculosBonificacao;
    this.pagamentos = pagamentos;
    this.basesVendas = basesVendas;
    this.indCrescimentoManual = indCrescimentoManual;
    this.unidadesMedida = unidadesMedida;
    this.descontos = descontos;
    this.descontosObrigatorios = descontosObrigatorios;
    this.idAcaoTipo = idAcaoTipo;
    this.indEvidencia = indEvidencia;
    this.templateCrescimento = templateCrescimento;
    this.modoApuracao = modoApuracao;
    this.indHabilitarNotificacaoEvidencia = indHabilitarNotificacaoEvidencia;
    this.idEstruturaUsuarioNotificacao = idEstruturaUsuarioNotificacao;
    this.indHabilitarHerancaConfig = indHabilitarHerancaConfig;
    this.idTipoAcaoAgrupadora = idTipoAcaoAgrupadora;
  }

  limparPassoPeloTemplate(confTemplate) {
    if (confTemplate.metas && confTemplate.metas.length === 1) {
      this.meta = confTemplate.metas[0].value;
    } else {
      this.meta = null;
    }

    if (confTemplate.calculosApuracao && confTemplate.calculosApuracao.length === 1) {
      this.calculosApuracao = [confTemplate.calculosApuracao[0].value];
    } else {
      this.calculosApuracao = [];
    }

    if (confTemplate.calculosBonificacao && confTemplate.calculosBonificacao.length === 1) {
      this.calculosBonificacao = [confTemplate.calculosBonificacao[0].value];
    } else {
      this.calculosBonificacao = [];
    }

    if (confTemplate.bonificacoes && confTemplate.bonificacoes.length === 1) {
      this.bonificacoes = [confTemplate.bonificacoes[0].value];
    } else {
      this.bonificacoes = [];
    }

    if (confTemplate.bases && confTemplate.bases.length === 1) {
      this.bases = [confTemplate.bases[0].value];
    } else {
      this.bases = [];
    }

    if (confTemplate.descontos && confTemplate.descontos.length === 1) {
      this.descontos = [confTemplate.descontos[0].value];
    } else {
      this.descontos = [];
    }

    if (!confTemplate.unidadeMedida) {
      this.unidadesMedida = [];
    }

    if (!confTemplate.valorBaseCrescimento) {
      this.indCrescimentoManual = false;
      this.templateCrescimento = null;
    }

    this.indEvidenciaSellin = false;
    this.indEvidenciaSellout = false;
    this.indHabilitarEvidencia = false;
    this.indEvidencia = false;
    this.descontosObrigatorios = [];

    if (!confTemplate.tipoPagamento) {
      this.pagamentos = [];
    }
  }

  desmarcarDescontoObrigatorio(descontos) {
    this.descontosObrigatorios.forEach((desconto, index, descontosObrigatorios) => {
      if (!descontos.includes(desconto)) {
        descontosObrigatorios.splice(index, 1);
      }
    });
  }
}
