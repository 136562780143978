<template>
  <v-card style="margin-bottom:1em" class="RelatorioExportacoesContrato">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.relatorio_contrato', 2) }}</div>
        <v-spacer></v-spacer>
        <icon-filtro @click="toggleMostrarFiltros" :filtros="filtros" />
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :objeto="filtros"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoContratoCampos from './RelatorioExportacaoContratoCampos';
import { countRelatorioContrato } from '@/common/resources/quantidade-registro-relatorio-suzano'; // eslint-disable-line
import IconFiltro from '../../../produto/shared-components/filtros/IconFiltro';

export default {
  name: 'RelatorioExportacaoContrato',
  mixins: [
    RelatorioExportacaoContratoCampos,
  ],
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  data() {
    return {
      filtros: {},
      ordenacaoCampos: [
        'data_inicio',
        'data_fim',
        'tipo_de_verba',
        'divisao',
        'holding',
        'cliente',
        'hierarquia1',
        'codicao_acao',
        'situacao_contrato',
      ],
      mostrarFiltros: false,
    };
  },
  methods: {
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio && filtrosAplicados.data_fim) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_maior_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        if (dtFinal.diff(dtInicio, 'days') > 365) {
          this.$toast(this.$t('message.data_intervalo_invalido_ano'));
          return false;
        }

        return true;
      }
      return true;
    },
    resetaFiltros() {
      this.filtros = {};
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      let quantidadeRegistros = 0;
      countRelatorioContrato(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data.count;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoContrato__AplicaFiltros',
              params,
              'contrato',
              'planejamento_acao');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
  },
};
</script>
<style>
  .RelatorioExportacoesContrato__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioExportacoesContrato__Opcoes div.v-input__slot,
  .RelatorioExportacoesContrato__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioExportacoesContrato__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioExportacoesContrato__Opcoes label {
    font-size: 14px;
  }
</style>
