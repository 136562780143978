<template>
  <v-navigation-drawer
    v-model="abrirFiltro"
    right
    width="300"
    clipped
    app
    :temporary="temporary"
    :hide-overlay="hideOverlay">
    <v-container fluid fill-height class="pa-0 FiltroRapido__container" v-resize="preencherAlturaContainer">
      <v-row :style="{ height: `${alturaContainer}px`}" class="mx-4">
        <div class="text-right px-3 pt-6 FiltroRapido__header" style="height: 60px; width: 100%">
          <v-icon @click="limpar" class="mr-3 font-awesome_font_20">fa-eraser</v-icon>
          <v-icon @click="fixar"  v-if="mostrarFixarFiltros" :class="filtrosFixosClass">fa-thumbtack</v-icon>
          <icon-filtro @click="fechar" v-if="value" :filtros="filtros" />
        </div>
        <div style="overflow-y: auto; overflow-x: hidden;"  :style="{ height: `${alturaMetadadosContainer}px`}">
          <metadados-container-filtro-layout
            v-show="value"
            v-if="metadadosCopia != null && carregouCampos"
            :metadados-entidade="metadadosCopia"
            :ordenacao-campos="ordenacaoCampos"
            :campos-formulario="camposFormulario"
            :grupo-expandido="grupoExpandido"
            :explode-hierarquia="true"
            :formulario-filtros="formularioFiltros"
            :ignora-obrigatorios="ignoraObrigatorios"
            :layout-class="layoutClass"
            :input-layout="inputLayout"
            :objeto="filtros"
            ref="container"
            @MetadadosContainerFiltroLayout__gruposCarregados="conteinerCarregado">
          </metadados-container-filtro-layout>
        </div>

        <v-row justify="end" class="px-3 FiltroRapido__footer" style="height: 60px">
          <v-btn @click="fechar" class="mr-3">{{ $t('label.fechar') }}</v-btn>
          <v-btn color="secondary" @click="aplicar">{{ $t('label.aplicar') }}</v-btn>
        </v-row>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import moment from 'moment';
import { skipLoading } from '../../common/functions/loading';
import { copyObject } from '../../common/functions/helpers';
import MetadadosContainerFiltroLayout from '../metadados/MetadadosContainerFiltroLayout';
import IconFiltro from '../filtros/IconFiltro';

export default {
  name: 'FiltroRapido',
  components: {
    MetadadosContainerFiltroLayout,
    IconFiltro,
  },
  props: {
    value: false,
    camposFormulario: {},
    metadados: {},
    ordenacaoCampos: {
      type: Array,
      default: () => ([]),
    },
    entidadeWorkspace: null,
    filtroPadrao: {
      type: Object,
      default: () => ({}),
    },
    substituirComPadrao: {
      type: Boolean,
      default: false,
    },
    grupoExpandido: {
      type: String,
      default: null,
    },
    ignoraObrigatorios: {
      type: Boolean,
      default: true,
    },
    aplicarFiltroNoCarregamento: {
      type: Boolean,
      default: true,
    },
    setarFiltroPadraoAoLimpar: {
      type: Boolean,
      default: false,
    },
    mostrarFixarFiltros: {
      type: Boolean,
      default: true,
    },
    anoAtualPeriodoDefault: {
      type: Boolean,
      default: false,
    },
    formularioFiltros: {
      type: Boolean,
      default: true,
    },
    temporary: {
      type: Boolean,
      default: false,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),

      drawer: false,
      filtrosFixados: false,
      carregouCampos: false,
      metadadosCopia: null,

      layoutClass: {
        wrap: true,
      },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: false,
        md3: false,
        md4: false,
        md6: false,
        md9: false,
      },

      filtros: {},
      workspace: {},

      alturaContainer: 0,
    };
  },
  computed: {
    alturaMetadadosContainer() {
      return this.alturaContainer - 120;
    },
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-3': true,
        'font-awesome_font_20': true,
      };
    },
    abrirFiltro: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    md() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  watch: {
    metadados() {
      this.copiarMetadados();
    },
    value() {
      this.$emit('input', this.value);
    },
  },
  methods: {
    preencherAlturaContainer() {
      const filtroContainer = document.getElementsByClassName('FiltroRapido__container')[0];
      if (filtroContainer !== undefined) {
        this.alturaContainer = filtroContainer.offsetHeight;
      }
    },
    conteinerCarregado() {
      this.$emit('FiltroRapido_conteinerCarregado');
      if (this.aplicarFiltroNoCarregamento) {
        this.preencherAlturaContainer();
        this.restaurarFiltros();
      }
    },
    copiarMetadados() {
      this.metadadosCopia = copyObject(this.metadados);
    },
    restaurarFiltros() {
      if (this.$refs && this.$refs.container) {
        const parametros = this.getValoresDependencias();
        this.$emit('FiltroRapido__AplicaFiltros', parametros);
        if (this.mdDown) {
          this.fechar();
        }
      }
    },
    getValoresDependencias() {
      return this.$refs.container.getValoresDependencias() || {};
    },
    aplicar() {
      this.restaurarFiltros();
      this.salvarFiltrosWorkspace();
    },
    limpar() {
      if (this.setarFiltroPadraoAoLimpar) {
        this.filtros = ({ ...this.filtroPadrao }) || {};
      } else {
        this.filtros = {};
      }
      this.$refs.container.refresh();
    },
    fechar() {
      this.$emit('input', !this.value);
    },
    fixar() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    carregarWorkspaceFiltros() {
      if (!this.entidadeWorkspace) {
        this.carregouCampos = true;
        this.filtros = { ...this.filtroPadrao };
        return;
      }
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.carregouCampos = true;
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtros = this.workspace.filtros || ({ ...this.filtroPadrao });
          if (this.workspace.filtros && this.substituirComPadrao) {
            Object.keys(this.filtroPadrao)
              .forEach((k) => {
                this.filtros[k] = this.filtroPadrao[k];
              });
          }
          if (this.anoAtualPeriodoDefault && !this.filtros.data_inicio) {
            this.setarAnoAtualFiltroPeriodo();
          }
          setTimeout(() => this.$emit('input', this.filtrosFixados));
        }).catch((err) => {
          this.carregouCampos = true;
          this.$error(this, err);
        });
    },
    setarAnoAtualFiltroPeriodo() {
      this.filtros.data_inicio = moment(new Date(new Date().getFullYear(), 0, 1), 'DD-MM-YYYY')
        .format('YYYY-MM-DD');
      this.filtros.data_fim = moment(new Date(new Date().getFullYear(), 11, 31), 'DD-MM-YYYY')
        .format('YYYY-MM-DD');
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtros;

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      if (!this.entidadeWorkspace) return;
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      if (!this.entidadeWorkspace) return;
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.copiarMetadados();
    this.carregarWorkspaceFiltros();
    setTimeout(() => {
      this.preencherAlturaContainer();
    }, 2E2);
  },
};
</script>
