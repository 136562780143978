var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":"","autocomplete":"off"}},[_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$t('label.vigencia'))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_acao.vigencia')))}})],1),_c('v-container',{staticClass:"pl-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-switch',{attrs:{"id":"config_acao_vigencia_automatica","label":_vm.$tc('label.preenchimento_automatico', 1),"disabled":_vm.somenteLeitura},model:{value:(_vm.parametrizacao.indVigenciaAutomatica),callback:function ($$v) {_vm.$set(_vm.parametrizacao, "indVigenciaAutomatica", $$v)},expression:"parametrizacao.indVigenciaAutomatica"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-switch',{attrs:{"id":"config_acao_vigencia_trimestral","label":_vm.$tc('label.trimestral_ano_calendario', 1),"disabled":_vm.somenteLeitura},model:{value:(_vm.parametrizacao.indVigenciaTrimestral),callback:function ($$v) {_vm.$set(_vm.parametrizacao, "indVigenciaTrimestral", $$v)},expression:"parametrizacao.indVigenciaTrimestral"}})],1)],1)],1),(!_vm.modoVarejo)?_c('div',[_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('title.vinculo_acao_tipo_orcamento', 1))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_acao.vinculo')))}})],1),_c('v-data-table',{attrs:{"id":"config_acao_tabela_vinculo","headers":_vm.headers,"items":_vm.parametrizacao.listaVinculo,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.vinculosCampos",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"Form-text__invalid",attrs:{"id":'config_acao_valo_template_' + item.idTipoVerba,"name":"config_acao_valo_template","item-text":"nome","item-value":"label","return-object":"","multiple":"","chips":"","items":item.lista,"disabled":_vm.somenteLeitura,"clearable":""},on:{"input":function($event){return _vm.habilitarVinculoPlanejamento(item)}},model:{value:(item.vinculosCampos),callback:function ($$v) {_vm.$set(item, "vinculosCampos", $$v)},expression:"item.vinculosCampos"}})]}}],null,false,1373431149)})],1):_vm._e(),_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('title.visibilidade_configuracao_campos', 1))+" ")]),_c('v-data-table',{attrs:{"align-center":"","id":"config_acao_tabela_campos","headers":_vm.headersCamposFluxos,"items":_vm.camposConfiguracao,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:(index + "_" + (item.label))},[_c('td',[_c('span',[_vm._v(_vm._s(item.nome))]),(item.label === 'divisao')?_c('tooltip-ajuda',{attrs:{"color":"accent","mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_acao.campo_divisao')))}}):(item.label === 'regional')?_c('tooltip-ajuda',{attrs:{"color":"accent","mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_acao.campo_regional')))}}):_vm._e()],1),_vm._l((_vm.fluxos),function(fluxo){return _c('td',{key:(fluxo + "_" + index),staticStyle:{"height":"'2em'"}},[(fluxo === 'PLANEJAMENTO' && _vm.recarregarPlanejamento)?_c('div',[(item[fluxo])?_c('span',[_vm._v(_vm._s(_vm.$tc('label.sim', 1)))]):_c('span',[_vm._v(_vm._s(_vm.$tc('label.nao', 1)))])]):_c('div',[(item.isCampoPadraoLiquidacao && fluxo !== 'LIQUIDACAO')?_c('span',[_vm._v(_vm._s(_vm.$tc('label.nao', 1)))]):(_vm.campoDinamicoPlanejamentoEditavel(fluxo, item) &&
                  _vm.campoProdutoPlanejamentoEditavel(fluxo, item) &&
                  _vm.campoPlanejamentoEditavel(fluxo, item) &&
                  _vm.campoCarteiraClienteEditavel(fluxo, item) &&
                  _vm.campoVinculoEditavel(fluxo, item))?_c('v-switch',{attrs:{"disabled":_vm.somenteLeitura},on:{"change":function($event){return _vm.alteracaoFluxos(index)}},model:{value:(item[fluxo]),callback:function ($$v) {_vm.$set(item, fluxo, $$v)},expression:"item[fluxo]"}}):_c('span',[_vm._v(_vm._s(_vm.$tc('label.sim', 1)))])],1)])}),_c('td',[(_vm.campoHabilitadoEdicaoAprovado(item.label))?_c('v-switch',{attrs:{"disabled":_vm.somenteLeitura},model:{value:(item.edicaoAprovado),callback:function ($$v) {_vm.$set(item, "edicaoAprovado", $$v)},expression:"item.edicaoAprovado"}}):_c('span',[_vm._v(_vm._s(_vm.$tc('label.nao', 1)))])],1),_c('td',[(!_vm.campoHabilitadoComplementoInformacao(item.label))?_c('span',[_vm._v(_vm._s(_vm.$tc('label.nao', 1)))]):_c('coluna-complemento-informacoes',{ref:("complementoInformacoes_" + index),refInFor:true,attrs:{"planejamento":item.PLANEJAMENTO,"apuracao":item.APURACAO,"liquidacao":item.LIQUIDACAO,"etapasFluxo":item.etapasFluxo,"index":index,"indHabilitaAprovacaoApuracao":item.indHabilitaAprovacaoApuracao},on:{"ALTERACAO_ETAPAS_FLUXO":_vm.alteracaoEtapasFluxo}})],1)],2)}),0)]}}])}),_c('div',[_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('title.fluxo_aprovacao', 1))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_acao.fluxo_aprovacao')))}})],1),_c('v-data-table',{attrs:{"align-center":"","id":"config_acao_tabela_fluxo_aprovacao","headers":_vm.headersFluxoAprovacao,"items":_vm.parametrizacao.fluxoAprovacao,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.fluxoAprovacao",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.fluxoAprovacao)+" ")])]}},{key:"item.indHabilitaFluxo",fn:function(ref){
                  var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":_vm.somenteLeitura},on:{"change":function($event){return _vm.tipoDeFluxoAlterado(item)}},model:{value:(item.indHabilitaFluxo),callback:function ($$v) {_vm.$set(item, "indHabilitaFluxo", $$v)},expression:"item.indHabilitaFluxo"}})]}},{key:"item.idEstruturaUsuario",fn:function(ref){
                  var item = ref.item;
return [(_vm.isFluxoApuracao(item) || _vm.isFluxoPlanejamento(item))?_c('div',[(item.indHabilitaFluxo)?_c('v-autocomplete',{staticClass:"config_acao_fluxo_estrutura",attrs:{"id":"config_acao_fluxo_estrutura","no-data-text":_vm.$tc('message.nenhum_registro', 1),"label":!item.indHabilitaFluxo ? ((_vm.$tc('label.estrutura_usuario', 1)) + " *") : ("" + (_vm.$tc('label.estrutura_usuario', 1))),"items":_vm.listaEstruturaUsuario,"disabled":true,"item-text":"nomEstruturaUsuario","item-value":"id","return-object":"","multiple":"","chips":"","deletable-chips":""},on:{"click:append":_vm.triggerEstruturaUsuario},model:{value:(item.estruturasUsuario),callback:function ($$v) {_vm.$set(item, "estruturasUsuario", $$v)},expression:"item.estruturasUsuario"}}):_c('v-autocomplete',{staticClass:"config_acao_fluxo_estrutura",attrs:{"id":"config_acao_fluxo_estrutura","no-data-text":_vm.$tc('message.nenhum_registro', 1),"label":!item.indHabilitaFluxo ? ((_vm.$tc('label.estrutura_usuario', 1)) + " *") : ("" + (_vm.$tc('label.estrutura_usuario', 1))),"items":_vm.listaEstruturaUsuario,"disabled":false,"item-text":"nomEstruturaUsuario","item-value":"id","return-object":"","rules":[_vm.rules.requiredLista],"multiple":"","chips":"","deletable-chips":""},on:{"click:append":_vm.triggerEstruturaUsuario},model:{value:(item.estruturasUsuario),callback:function ($$v) {_vm.$set(item, "estruturasUsuario", $$v)},expression:"item.estruturasUsuario"}})],1):_vm._e()]}},{key:"item.idTipoVerba",fn:function(ref){
                  var item = ref.item;
return [(_vm.isFluxoApuracao(item) || _vm.isFluxoPlanejamento(item))?_c('div',[(item.indHabilitaFluxo)?_c('v-autocomplete',{staticClass:"tipo_verba",attrs:{"id":"tipo_verba","no-data-text":_vm.$tc('message.nenhum_registro', 1),"label":!item.indHabilitaFluxo ? ((_vm.$tc('label.tipo_verba', 1)) + " *") : ("" + (_vm.$tc('label.tipo_verba', 1))),"items":_vm.listaTiposVerba,"disabled":true,"item-text":"nome","item-value":"id","return-object":"","multiple":"","chips":"","deletable-chips":""},on:{"click:append":_vm.triggerTipoVerba},model:{value:(item.orcamentosTipoVerba),callback:function ($$v) {_vm.$set(item, "orcamentosTipoVerba", $$v)},expression:"item.orcamentosTipoVerba"}}):_c('v-autocomplete',{staticClass:"tipo_verba",attrs:{"id":"tipo_verba","no-data-text":_vm.$tc('message.nenhum_registro', 1),"label":!item.indHabilitaFluxo ? ((_vm.$tc('label.tipo_verba', 1)) + " *") : ("" + (_vm.$tc('label.tipo_verba', 1))),"items":_vm.listaTiposVerba,"disabled":false,"item-text":"nome","item-value":"id","return-object":"","rules":[_vm.rules.requiredLista],"multiple":"","chips":"","deletable-chips":""},on:{"click:append":_vm.triggerTipoVerba},model:{value:(item.orcamentosTipoVerba),callback:function ($$v) {_vm.$set(item, "orcamentosTipoVerba", $$v)},expression:"item.orcamentosTipoVerba"}})],1):_vm._e()]}},{key:"item.indJustificativaAnalise",fn:function(ref){
                  var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":!item.indHabilitaFluxo || _vm.somenteLeitura},model:{value:(item.indJustificativaAnalise),callback:function ($$v) {_vm.$set(item, "indJustificativaAnalise", $$v)},expression:"item.indJustificativaAnalise"}})]}},{key:"item.indHabilitaEdicaoAprovacao",fn:function(ref){
                  var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":!item.indHabilitaFluxo || _vm.somenteLeitura},model:{value:(item.indHabilitaEdicaoAprovacao),callback:function ($$v) {_vm.$set(item, "indHabilitaEdicaoAprovacao", $$v)},expression:"item.indHabilitaEdicaoAprovacao"}})]}}])})],1),_c('v-row',{staticClass:"my-4",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"id":"config_acao_passo4_botao_cancelar"},on:{"click":_vm.cancelar}},[_vm._v(_vm._s(_vm.$t('label.cancelar')))]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","id":"config_acao_passo4_botao_voltar"},on:{"click":_vm.voltarPasso}},[_vm._v(_vm._s(_vm.$t('label.voltar_passo')))]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","id":"config_acao_passo4_botao_proximo"},on:{"click":_vm.proximoPasso}},[_vm._v(_vm._s(_vm.$t('label.proximo_passo')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }