import getBasePath from '../functions/api-resource';

const basePath = getBasePath('orcamento', 'orcamento/rateio');

const rateioActions = {
  buscaRateio: { method: 'GET', url: `${basePath}/{idOrcamento}` },
  atualizarRateio: { method: 'PUT', url: `${basePath}/{idOrcamento}` },
  buscarDivisoes: { method: 'GET', url: `${basePath}/{idOrcamento}/divisoes` },
  buscarLinhas: { method: 'GET', url: `${basePath}/{idOrcamento}/linhas?{&idsLinhasInvestimento*}{&offset}` },
  buscarTodasAsLinhas: { method: 'GET', url: `${basePath}/{idOrcamento}/linhas` },
  buscarNovasDivisoes: { method: 'GET', url: `${basePath}/{idOrcamento}/novas-divisoes` },
  adicionarNovasDivisoes: { method: 'PUT', url: `${basePath}/{idOrcamento}/novas-divisoes` },
  exportarLinhasOrcamentoExcel: { method: 'POST', url: `${basePath}/exportar/rateio/orcamento` },
};

export default (resource) => resource(`${basePath}`, {}, rateioActions);
