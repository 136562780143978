var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","sm":"6"}},[_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('label.grid_filtros', 1))+" - "+_vm._s(_vm.$tc('label.planejamento', 1))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_acao.grid_filtros')))}})],1),_c('v-data-table',{attrs:{"id":"config_acao_grid_filtros_planejamento","headers":_vm.headers,"items":_vm.parametrizacao.listaPlanejamento,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nome))]),(item.label === 'divisao')?_c('tooltip-ajuda',{attrs:{"color":"accent","mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_acao.campo_divisao')))}}):(item.label === 'regional')?_c('tooltip-ajuda',{attrs:{"color":"accent","mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_acao.campo_regional')))}}):_vm._e()]}},{key:"item.habilitaGrid",fn:function(ref){
var item = ref.item;
return [(!item.desativaGrid)?_c('v-switch',{attrs:{"color":"primary","id":"config_acao_passo8_grid","disabled":item.desativaGrid || _vm.somenteLeitura,"label":("" + (_vm.$t('label.grid'))),"hide-details":""},model:{value:(item.habilitaGrid),callback:function ($$v) {_vm.$set(item, "habilitaGrid", $$v)},expression:"item.habilitaGrid"}}):_vm._e(),(item.desativaGrid)?_c('v-label',[_vm._v(_vm._s(item.habilitaGrid ? _vm.$t('label.sim') : _vm.$t('label.nao')))]):_vm._e()]}},{key:"item.habilitaFiltro",fn:function(ref){
var item = ref.item;
return [(!item.desativaFiltro)?_c('v-switch',{attrs:{"color":"primary","id":"config_acao_passo8_filtro","disabled":item.desativaFiltro || _vm.somenteLeitura,"label":("" + (_vm.$tc('label.filtro', 1))),"hide-details":""},model:{value:(item.habilitaFiltro),callback:function ($$v) {_vm.$set(item, "habilitaFiltro", $$v)},expression:"item.habilitaFiltro"}}):_vm._e(),(item.desativaFiltro)?_c('v-label',[_vm._v(_vm._s(item.habilitaFiltro ? _vm.$t('label.sim') : _vm.$t('label.nao')))]):_vm._e()]}}])})],1),(_vm.parametrizacao.listaLiquidacaoManual && _vm.parametrizacao.listaLiquidacaoManual.length)?_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","sm":"4"}},[_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('label.grid_filtros', 1))+" - "+_vm._s(_vm.$tc('label.liquidacao_manual', 1))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_acao.grid_filtros')))}})],1),_c('v-data-table',{attrs:{"id":"config_acao_grid_filtros_liquidacao_manual","headers":_vm.headersLiquidacaoManual,"items":_vm.parametrizacao.listaLiquidacaoManual,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.habilitaGrid",fn:function(ref){
var item = ref.item;
return [(!item.desativaGrid)?_c('v-switch',{attrs:{"color":"primary","id":"config_acao_passo8_grid","disabled":item.desativaGrid,"label":("" + (_vm.$t('label.grid'))),"hide-details":""},model:{value:(item.habilitaGrid),callback:function ($$v) {_vm.$set(item, "habilitaGrid", $$v)},expression:"item.habilitaGrid"}}):_vm._e(),(item.desativaGrid)?_c('v-label',[_vm._v(_vm._s(item.habilitaGrid ? _vm.$t('label.sim') : _vm.$t('label.nao')))]):_vm._e()]}}],null,false,230082160)})],1):_vm._e()],1),_c('v-row',{staticClass:"my-4",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"id":"config_acao_passo8_botao_cancelar"},on:{"click":_vm.cancelar}},[_vm._v(_vm._s(_vm.$t('label.cancelar')))]),_c('v-btn',{staticClass:"mr-3",attrs:{"id":"config_acao_passo8_botao_voltar","color":"primary"},on:{"click":_vm.voltarPasso}},[_vm._v(_vm._s(_vm.$t('label.voltar_passo')))]),_c('v-btn',{staticClass:"mr-3",attrs:{"id":"config_acao_passo8_botao_proximo","color":"error","disabled":_vm.somenteLeitura},on:{"click":_vm.concluir}},[_vm._v(_vm._s(_vm.$t('label.concluir')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }