import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'relatorios');

const suzanoRelatorioActions = {};

export const countRelatorioPagamentoSuzano = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pagamento/count', parametros).doGet();

export default (resource) => resource(`${basePath}`, {}, suzanoRelatorioActions);
