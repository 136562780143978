import getBasePath from '../functions/api-resource';

const basePath = getBasePath('orcamento', 'orcamento');

const orcamentoActions = {
  salvar: { method: 'POST', url: `${basePath}/cadastro` },
  atualizar: { method: 'PUT', url: `${basePath}/cadastro` },
  concluir: { method: 'PUT', url: `${basePath}/conclusao/{idOrcamento}` },
  cancelar: { method: 'PUT', url: `${basePath}/cadastro/{idOrcamento}/cancelar` },
  executarAnalise: { method: 'PUT', url: `${basePath}/cadastro/{idOrcamento}/analise` },
  salvarImportacao: { method: 'POST', url: `${basePath}/cadastro/salvar-importacao` },
  buscarIdOrcamentoPorIdImportacao: { method: 'GET', url: `${basePath}/cadastro/buscar-id-orcamento/{idImportacao}` },
};

export default (resource) => resource(`${basePath}`, {}, orcamentoActions);
