const EstornoSaldoContaCorrente = () => import('@/spa/estorno-conta-corrente/EstornoSaldoContaCorrente');

export default {
  path: 'estorno-conta-corrente',
  component: EstornoSaldoContaCorrente,
  children: [
    {
      path: '',
      name: 'estorno-conta-corrente',
      component: EstornoSaldoContaCorrente,
    },
  ],
};
