<template lang="html">
  <div>
    <v-form ref="formLiquidacao" lazy-validation autocomplete="off">
      <v-card-title primary-title class="card-header">
        {{ $tc('title.campo_padrao', 2) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.campo_padrao')}`" />
      </v-card-title>
      <v-data-table id="config_acao_tabela_lista_padrao"
        :headers="headers"
        :items="parametrizacao.listaPadrao"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.obrigatorio="{ item }">
          {{ item.obrigatorio ? $t('label.sim') : $t('label.nao')}}
        </template>
        <template v-slot:item.opcoes="{ item }">
          <v-switch
            id="config_acao_desc_automatica"
            :label="$tc('label.preenchimento_automatico', 1)"
            v-model="parametrizacao.indDescricaoAutomatica"
            :disabled="somenteLeitura"
            v-if="item.label === 'descricao'"/>
        </template>
      </v-data-table>

      <div v-if="!modoVarejo">
        <v-card-title primary-title class="card-header">
          {{ $tc('title.foco_da_acao', 1) }} *
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.cliente')}`" />
        </v-card-title>
        <v-row class="mx-4" v-for="(c, index)  in parametrizacao.listaCliente"
               :key="`passo3-lista-cliente-${index}`">
          <v-col class="py-1" cols="12" sm="6" md="3">
            <v-switch
              dense
              hide-details
              :label="$t(`label.habilitar_acao_${c.label}`)"
              v-model="c.habilitado"
              v-if="c.label !== 'cliente_pagador'"
              :rules="[rules.requiredFocoAcao]"
              :disabled="somenteLeitura"
              @change="resetarValidacaoFocoAcao" />
          </v-col>
          <v-col class="py-3" cols="12" sm="6" md="3"
            v-if="c.habilitado && c.label === 'campo_cliente'">
            <v-select
              dense
              hide-details
              id="config_acao_campo_cliente_tipo"
              class="Form-text__invalid"
              item-text="descricao"
              item-value="id"
              v-model="c.campoCliente"
              :items="camposCliente"
              :label="`${$tc('label.campo', 1)} *`"
              required
              return-object
              @change="invalidarCampoCliente(c)"/>
          </v-col>
        </v-row>
        <v-subheader  class="mt-3">{{ $tc('label.vinculo_industria', 1) }}</v-subheader>
        <v-divider></v-divider>
        <v-row class="mx-4">
          <v-col class="py-0" cols="12" sm="4"
                 v-for="(c, index) in parametrizacao.listaCarteiraCliente"
                 :key="`passo3-unidade-negocio-${index}`">
            <v-switch
              :disabled="c.mnemonico === 'UNIDADE_NEGOCIO'"
              v-model="c.habilitado"
              :label="c.descricao"/>
          </v-col>
        </v-row>

      </div>

      <div v-if="modoVarejo">
        <v-card-title primary-title class="card-header">
          {{ $tc('title.origem_da_acao', 1) }} *
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.origem_acao')}`" />
        </v-card-title>
        <v-row class="mx-4" v-for="(c, index) in parametrizacao.listaFornecedor"
               :key="`passo3-lista-fornecedor-${index}`">
          <v-col class="py-0" cols="12" sm="6">
            <v-switch
              dense
              hide-details
              :label="$t(`label.habilitar_acao_${c.label}`)"
              v-model="c.habilitado"
              :rules="c.label === 'fornecedor_pagador' ? [] : [rules.requiredOrigemAcao]"
              :disabled="somenteLeitura"
              @change="resetarValidacaoOrigemAcao" />
          </v-col>
        </v-row>
      </div>

      <div v-if="!modoVarejo">
        <v-card-title primary-title class="card-header">
          {{ $tc('title.pagamento_acao', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.pagamento_acao')}`" />
        </v-card-title>
        <v-row class="mx-4"
               v-for="(c, index) in camposPagamentoAcao"
               :key="`passo3-pagamento_acao-${index}`">
          <v-col class="py-0" cols="6" sm="2">
            <v-switch
              dense
              hide-details
              :label="$t(`label.habilitar_acao_${c.label}`)"
              :disabled="somenteLeitura"
              v-model="c.habilitado"
              @change="alteraPagamentoAcao(c)"/>
          </v-col>
          <v-col class="py-0" cols="6" sm="2"
            v-if="c.value === 'cliente_pagador'">
            <v-switch
              :disabled="!c.habilitado"
              dense
              hide-details
              :label="$t('label.exibir_todos_clientes_ativos')"
              v-model="c.indExibirTodosClientesAtivos"/>
            </v-col>
        </v-row>
      </div>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.produto', 1) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.produto')}`" />
      </v-card-title>
      <v-data-table id="config_acao_tabela_produto"
        :headers="headersProduto"
        :items="parametrizacao.listaProduto"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.aplicar_se_a="{ item }">
          {{ item.aplicarse ? item.aplicarse : ' - ' }}
        </template>
        <template v-slot:item.obrigatorio="{ item }">
          <v-checkbox v-model="item.obrigatorio" hide-details :disabled="item.excecao || somenteLeitura" @change="alteraValidadeNoPasso4(item)" class="ma-0 pa-0"></v-checkbox>
        </template>
        <template v-slot:item.excecao="{ item }">
          <v-checkbox v-model="item.excecao" hide-details :disabled="item.obrigatorio || somenteLeitura" @change="alteraValidadeNoPasso4(item)" class="ma-0 pa-0"></v-checkbox>
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header mt-4">
        {{ `${$tc('title.valor_acao', 1)} *` }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.valor')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="3">
          <v-radio-group v-model="parametrizacao.valor"
            id="config_acao_valor" :rules="[rules.requiredLista]">
            <v-radio :label="$t('label.nao_se_aplica')" value="N/A" v-if="!indInvetimentoDebito" :disabled="somenteLeitura"></v-radio>
            <v-radio :label="$t('label.manual')" value="MANUAL" :disabled="somenteLeitura"></v-radio>
            <v-radio :label="$t('label.calculado_automaticamente')" value="AUTOMATICO" :disabled="somenteLeitura"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-if="parametrizacao.valor === 'AUTOMATICO'"
            id="config_acao_valo_template"
            class="Form-text__invalid"
            name="config_acao_valo_template"
            item-text="descricao"
            item-value="nome"
            v-model="parametrizacao.template"
            :items="templates"
            :rules="[rules.required]"
            :label="$t(`label.template_calculo`)"
            required
            :disabled="somenteLeitura"
            clearable>
          </v-select>
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('label.campo_customizado', 2) }}
        <v-spacer />
        <div style="cursor: pointer" class="mr-2">
          <span v-html="`${$t('label.nao_encontrou_campo_clique_aqui')}`" @click="novoCampoCustomizado"></span>
        </div>
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.campos_customizados')}`" />
      </v-card-title>
      <v-data-table
        v-if="render"
        id="config_acao_tabela_campos_customizados"
        :headers="headersCamposDinamicos"
        :items="parametrizacao.listaDinamica"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.obrigatorio="{ item }">
          <v-checkbox
              v-model="item.obrigatorio"
              :disabled="somenteLeitura"
              hide-details @change="alteraValidadeNoPasso4(item)">
          </v-checkbox>
        </template>
      </v-data-table>

      <v-row justify="end" class="my-4">
        <v-btn id="config_acao_passo3_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo3_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo3_botao_proximo" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
    <v-dialog v-model="dialogCampoCustomizado" persistent max-width="800px">
      <campos-customizados-form
        ref="campoCustomizado"
        :desabilitarSelecaoEntidade="true"
        @atualizarGrid="atualizarGridCamposCustomizados"
        @cancelar="cancelarCamposCustomizados">
        <div slot="button">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="cancelarCamposCustomizados">{{ $t('label.cancelar') }}</v-btn>
            <v-btn @click.native="validarSalvarCamposCustomizados" color="primary">{{ $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </div>
      </campos-customizados-form>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import CamposCustomizadosForm from '../../campos-customizados/CamposCustomizadosForm';
import listaPadraoList from './listaPadrao';
import Passo3 from './Passo3';
import TooltipAjuda from '../TooltipAjuda';
import { generateComputed } from '../../../common/functions/roles-computed-generator';

export default {
  name: 'ParametrizacaoAcaoPasso3',
  props: {
    id: Number,
    modoVarejo: Boolean,
    indInvetimentoDebito: Boolean,
  },
  components: {
    CamposCustomizadosForm,
    TooltipAjuda,
  },
  computed: {
    camposPagamentoAcao() {
      return [
        this.listaCliente.filter((c) => c.value === 'cliente_pagador')[0],
        this.listaFornecedor.filter((c) => c.value === 'fornecedor_pagador')[0],
      ];
    },
    ...mapGetters('metadados', [
      'getClienteMetadado',
    ]),
    camposCliente() {
      const { mapaEntidades } = this.getClienteMetadado;
      return Object.values(mapaEntidades)
        .map((c) => ({
          id: c.idNivelExtensao,
          descricao: c.entidadeEstrangeira,
          label: c.label,
          mnemonico: c.mnemonico,
          indiceRecursao: c.indiceRecursao,
        })).filter((c) => c.label !== 'unidadenegocio');
    },
    ...generateComputed('param_acao', [
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  data() {
    return {
      passo: 3,
      render: false,
      camposCustomizadosResources: this.$api.camposCustomizados(this.$resource),
      nivelProdutoResources: this.$api.nivelProduto(this.$resource),
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),

      vinculoCarteira: [],

      dialogCampoCustomizado: false,

      headers: [
        { text: this.$tc('label.campo', 1), value: 'nome', width: '40%' },
        {
          text: this.$tc('label.obrigatorio', 1), value: 'obrigatorio', width: '25%', sortable: false,
        },
        {
          text: '', value: 'opcoes', width: '40%', sortable: false,
        },
      ],
      headersProduto: [
        {
          text: this.$tc('label.campo', 1), value: 'nome', width: '15%', sortable: false,
        },
        {
          text: this.$tc('label.aplicar_se_a', 1), value: 'aplicar_se_a', width: '15%', sortable: false,
        },
        {
          text: this.$tc('label.obrigatorio', 1), value: 'obrigatorio', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.excecao', 1), value: 'excecao', sortable: false, width: '10%',
        },
      ],
      headersCamposDinamicos: [
        { text: this.$tc('label.campo', 1), value: 'nomCampo', width: '30%' },
        { text: this.$tc('label.descricao', 1), value: 'descricao' },
        { text: this.$tc('label.tipo', 1), value: 'tipoCampo', width: '10%' },
        {
          text: this.$tc('label.obrigatorio', 1), value: 'obrigatorio', sortable: false, width: '5%',
        },
      ],
      listaCliente: [
        {
          nome: this.$tc('label.cliente', 1), value: 'cliente', label: 'cliente', obrigatorio: true, habilitado: false,
        },
        {
          nome: this.$tc('label.holding', 1), value: 'holding', label: 'holding', obrigatorio: true, habilitado: false,
        },
        {
          nome: this.$tc('label.regional', 1), value: 'regional', label: 'regional', obrigatorio: true, habilitado: false,
        },
        {
          nome: this.$tc('label.cliente_pagador', 1), value: 'cliente_pagador', label: 'cliente_pagador', obrigatorio: false, habilitado: false, indExibirTodosClientesAtivos: false,
        },
        {
          nome: this.$tc('label.campo_cliente', 1), value: 'campo_cliente', label: 'campo_cliente', obrigatorio: true, habilitado: false,
        },
      ],
      listaFornecedor: [
        {
          nome: this.$tc('label.grupo_fornecedor', 1), value: 'grupo_fornecedor', label: 'grupo_fornecedor', obrigatorio: true, habilitado: false,
        },
        {
          nome: this.$tc('label.fornecedor', 1), value: 'fornecedor', label: 'fornecedor', obrigatorio: true, habilitado: false,
        },
        {
          nome: this.$tc('label.fornecedor_pagador', 1), value: 'fornecedor_pagador', label: 'fornecedor_pagador', obrigatorio: false, habilitado: this.modoVarejo,
        },
      ],
      listaProduto: [
        {
          nome: this.$tc('label.produto', 1), value: 'produto', obrigatorio: false, label: 'produto', indCampoProduto: true, excecao: false,
        },
      ],
      listaPadraoLiquidacao: [
        {
          nome: this.$tc('label.numero_documento', 1), obrigatorio: false, label: 'numero_documento', habilitaGrid: true, habilitaFiltro: true, desativaFiltro: true, desativaGrid: true, habilitaEdicao: true, edicaoAprovado: false, isCampoPadraoLiquidacao: true,
        },
        {
          nome: this.$tc('label.data_liquidacao', 1), obrigatorio: false, label: 'data_liquidacao', habilitaGrid: true, habilitaFiltro: true, desativaFiltro: true, desativaGrid: true, habilitaEdicao: true, edicaoAprovado: false, isCampoPadraoLiquidacao: true,
        },
      ],
      listaLiquidacaoManual: [
        {
          nome: this.$tc('label.evidencia_apuracao', 1), obrigatorio: false, label: 'evidencia', habilitaGrid: true, desativaGrid: false, habilitaEdicao: true, edicaoAprovado: false, isCampoPadraoLiquidacao: true,
        },
        {
          nome: this.$tc('label.status_pagamento', 1), obrigatorio: false, label: 'status_pagamento', habilitaGrid: true, desativaGrid: true, habilitaEdicao: true, edicaoAprovado: false, isCampoPadraoLiquidacao: true,
        },
        {
          nome: this.$tc('label.valor_acao', 1), obrigatorio: false, label: 'valor_acao', habilitaGrid: true, desativaGrid: true, habilitaEdicao: true, edicaoAprovado: false, isCampoPadraoLiquidacao: true,
        },
        {
          nome: this.$tc('label.total_pagamento', 2), obrigatorio: false, label: 'total_pagamento', habilitaGrid: false, desativaGrid: true, habilitaEdicao: true, edicaoAprovado: false, isCampoPadraoLiquidacao: true,
        },
        {
          nome: this.$tc('label.pendente_pagamento', 1), obrigatorio: false, label: 'pendente_pagamento', habilitaGrid: false, desativaGrid: true, habilitaEdicao: true, edicaoAprovado: false, isCampoPadraoLiquidacao: true,
        },
        {
          nome: this.$tc('label.valor_pagamento', 1), obrigatorio: false, label: 'valor_pagamento', habilitaGrid: true, desativaGrid: false, habilitaEdicao: true, edicaoAprovado: false, isCampoPadraoLiquidacao: true,
        },
      ],
      listaPadrao: [...listaPadraoList(this)],
      parametrizacao: new Passo3(),
      tiposNivelProduto: [],
      fluxos: [],
      templates: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || '',
        requiredFocoAcao: () => (!!this.parametrizacao.listaCliente
          && this.parametrizacao.listaCliente.filter((c) => c.habilitado === true && c.label !== 'cliente_pagador').length > 0)
          || '',
        requiredOrigemAcao: () => (!!this.parametrizacao.listaFornecedor
          && this.parametrizacao.listaFornecedor.filter((f) => f.habilitado === true && f.label !== 'fornecedor_pagador').length > 0)
          || '',
      },
      campoValorBanco: {},
      indExibirTodosClientesAtivos: false,
    };
  },
  methods: {
    ...mapActions('metadados', [
      'setClienteMetadado',
    ]),
    invalidarCampoCliente(campo) {
      if (!campo.habilitado) {
        campo.campoCliente = null;
      }
    },
    camposPagamentoHabilitados() {
      return this.camposPagamentoAcao.filter((c) => c.habilitado);
    },
    setFluxos(fluxo) {
      this.fluxos = fluxo.split(',');
    },
    adicionaSeForNovo(objeto, item) {
      let hasItem = false;
      objeto.forEach((itemObj) => {
        if (itemObj.label === item.label) {
          hasItem = true;
        }
      });
      if (!hasItem) objeto.push(item);
    },
    atualizarGridCamposCustomizados(id) {
      this.carregarCampoDinamicoEspecifico(id)
        .then(() => {
          this.dialogCampoCustomizado = false;
        });
    },
    buscarMetadadosNivelProduto() {
      return this.nivelProdutoResources.acao().then((resultado) => {
        this.tiposNivelProduto = resultado.data;
        this.maiorIndice = Math.max(...this.tiposNivelProduto.map((tipo) => tipo.indice));

        this.tiposNivelProduto.forEach((nivel) => {
          const item = {
            nome: nivel.descricao,
            obrigatorio: false,
            excecao: false,
            label: nivel.label,
            aplicarse: this.exibirPai(nivel),
            indCampoProduto: true,
          };

          this.adicionaSeForNovo(this.listaProduto, item);
        });
      });
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_ACAO_CANCELAR');
    },
    cancelarCamposCustomizados() {
      this.$refs.campoCustomizado.limparFormulario();
      this.dialogCampoCustomizado = false;
    },
    carregarCamposDinamicos() {
      return this.camposCustomizadosResources.listarPorEntidadeAcao()
        .then((response) => {
          this.preencherListaDinamica(response);
        }, (err) => {
          this.$error(this, err);
        });
    },
    preencherListaDinamica(response) {
      if (!this.id) {
        this.parametrizacao.listaDinamica = response.data
          .filter((item) => !item.isPreenchidoProcessoCustomizado);
        this.parametrizacao.listaDinamica.forEach((l) => {
          l.nome = l.nomCampo;
          l.desAtributos = l.desAtributos && l.desAtributos.value
            ? JSON.parse(l.desAtributos.value) : {};
          l.idCampoDinamico = l.id;
          l.label = l.labelCampo;
          l.obrigatorio = l.vlrObrigatorio;
          l.edicaoAprovado = false;
          l.fluxos = [...this.fluxos];
          delete l.id;
        });
      } else {
        this.camposCustomizados = response.data;
      }
    },
    carregarCampoDinamicoEspecifico(id) {
      const param = { id, page: 1, size: 10 };
      return this.camposCustomizadosResources.listar(param).then((response) => {
        const resultado = response.data.resposta;
        if (resultado && resultado.length > 0) {
          const novoCampoDinamico = resultado[0];
          novoCampoDinamico.nome = novoCampoDinamico.nomCampo;
          novoCampoDinamico.desAtributos = novoCampoDinamico.desAtributos
            && novoCampoDinamico.desAtributos.value
            ? JSON.parse(novoCampoDinamico.desAtributos.value) : {};
          novoCampoDinamico.idCampoDinamico = novoCampoDinamico.id;
          novoCampoDinamico.label = novoCampoDinamico.labelCampo;
          novoCampoDinamico.obrigatorio = novoCampoDinamico.vlrObrigatorio;
          delete novoCampoDinamico.id;
          this.parametrizacao.listaDinamica.push(novoCampoDinamico);
          Passo3.setListaDinamica(this.parametrizacao.listaDinamica);
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    definirLista(listaPrincipal, lista) {
      lista.forEach((l) => {
        const original = listaPrincipal.filter((el) => el.label === l.label)[0];
        if (!original) {
          return;
        }
        original.habilitado = true;
        original.obrigatorio = l.obrigatorio;
        original.excecao = l.excecao;
        original.edicaoAprovado = l.edicaoAprovado;
        original.complementoInformacao = l.etapasFluxo && l.etapasFluxo.length;
        original.fluxos = l.fluxos;
        original.etapasFluxo = l.etapasFluxo;
        original.indHabilitaAprovacaoApuracao = l.indHabilitaAprovacaoApuracao;

        if (original.label === 'campo_cliente') {
          const [campo] = this.camposCliente.filter((c) => {
            const { id, indiceRecursao } = c;
            return id === l.idNivelExtensao
              && indiceRecursao === l.indiceRecursao;
          });
          original.campoCliente = campo;
        } else if (original.label === 'cliente_pagador') {
          original.indExibirTodosClientesAtivos = l.indExibirTodosClientesAtivos;
        }
      });
    },
    exibirPai(nivel) {
      if (nivel.indice == null || nivel.indice === this.maiorIndice) {
        return ' - ';
      }
      return this.tiposNivelProduto
        .filter((item) => item.indice === (nivel.indice + 1))[0].descricao;
    },
    getListaCliente() {
      return this.parametrizacao.listaCliente;
    },
    resetarValidacaoFocoAcao() {
      if (this.parametrizacao.listaCliente.filter((c) => c.habilitado).length > 0) {
        this.$refs.formLiquidacao.resetValidation();
        this.$refs.formLiquidacao.validate();
      }
    },
    resetarValidacaoOrigemAcao() {
      if (this.parametrizacao.listaFornecedor.filter((f) => f.habilitado).length > 0) {
        this.$refs.formLiquidacao.resetValidation();
        this.$refs.formLiquidacao.validate();
      }
    },
    alteraValidadeNoPasso4(item) {
      if (!item.obrigatorio && !item.excecao) {
        item.PLANEJAMENTO = false;
        item.fluxos.splice(item.fluxos.findIndex((fluxo) => fluxo === 'PLANEJAMENTO'), 1);
      }
    },
    novoCampoCustomizado() {
      if (!this.somenteLeitura) {
        this.dialogCampoCustomizado = true;
        setTimeout(() => {
          this.$refs.campoCustomizado.habilitarApenasUmaEntidade('ACAO');
        }, 5E2);
      }
    },
    definirListaDinamica(lista = []) {
      this.parametrizacao.listaDinamica = lista.map((el) => {
        const item = this.camposCustomizados.find((l) => l.labelCampo === el.label) || {};
        const obj = {
          ...el,
          ...item,
          habilitado: true,
          nome: item.nomCampo,
        };
        return obj;
      });
    },
    obter(id) {
      this.paramAcaoResources.obterPasso3({ id }, { id }).then((response) => {
        if (!this.modoVarejo) {
          this.definirLista(this.listaCliente, response.data.listaCliente);
        }
        this.definirLista(this.listaFornecedor, response.data.listaFornecedor);
        this.definirLista(this.listaProduto, response.data.listaProduto);
        this.definirLista(this.listaPadrao, response.data.listaPadrao);
        this.definirLista(this.listaPadraoLiquidacao, response.data.listaPadraoLiquidacao);
        this.definirLista(this.listaLiquidacaoManual, response.data.listaLiquidacaoManual);
        if (this.id) {
          this.definirListaDinamica(response.data.listaDinamica);
        }
        this.definirListaCarteira(response.data.listaCarteiraCliente, this.vinculoCarteira);

        // Valor
        let valor = 'N/A';
        const labelValor = response.data.listaPadrao.filter((p) => p.label === 'valor');
        if (labelValor && labelValor.length) {
          const [campoValorB] = labelValor;
          this.campoValorBanco = campoValorB;
          if (response.data.indCalculoAutomatico) {
            valor = 'AUTOMATICO';
          } else {
            valor = 'MANUAL';
          }
        }

        this.parametrizacao = new Passo3(this.id,
          this.listaCliente,
          this.listaFornecedor,
          this.listaProduto,
          this.listaPadrao,
          this.listaPadraoLiquidacao,
          this.listaLiquidacaoManual,
          this.parametrizacao.listaDinamica,
          this.vinculoCarteira,
          response.data.pagamentoAcao,
          response.data.indCalculoAutomatico,
          response.data.indDescricaoAutomatica,
          response.data.template,
          valor);

        this.render = true;
      }, (err) => {
        this.$error(this, err);
      });
    },
    definirListaCarteira(listaBanco, listaPadrao) {
      if (!listaBanco) return;
      listaBanco.forEach((v) => {
        const extCarteira = listaPadrao.find((e) => e.mnemonico === v.mnemonico);
        if (extCarteira) {
          extCarteira.fluxos = v.fluxos;
          extCarteira.habilitado = v.habilitado;
          extCarteira.id = v.id;
        }
      });
    },
    prepararPasso() {
      Promise.all([
        this.carregarCamposDinamicos(),
        this.buscarMetadadosNivelProduto(),
        this.buscarVinculosCarteira(),
        this.buscarTemplatesCalculo(),
      ])
        .then(() => {
          if (this.id) {
            this.obter(this.id);
          } else {
            this.parametrizacao = new Passo3(
              this.id,
              this.listaCliente,
              this.listaFornecedor,
              this.listaProduto,
              this.listaPadrao,
              this.listaPadraoLiquidacao,
              this.listaLiquidacaoManual,
              this.parametrizacao.listaDinamica,
              this.vinculoCarteira,
            );
            this.render = true;
          }
        });
    },
    buscarVinculosCarteira() {
      return this.paramAcaoResources.buscarVinculosCarteira()
        .then((resultado) => {
          this.vinculoCarteira = resultado.data;
          this.vinculoCarteira.forEach((v) => {
            v.fluxos = ['PLANEJAMENTO'];
            v.indCampoCarteira = true;
            v.nome = `${this.$tc('label.vinculo_industria', 1)} - ${v.descricao}`;
            if (v.mnemonico === 'UNIDADE_NEGOCIO') {
              v.habilitado = true;
            }
          });
        });
    },
    buscarTemplatesCalculo() {
      return this.paramAcaoResources.buscarTemplatesCalculo()
        .then((resultado) => {
          this.templates = resultado.data;
        });
    },
    proximoPasso() {
      if (this.validarPasso3()) {
        const metaNA = this.parametrizacao.valor === 'N/A';

        this.parametrizacao.indCalculoAutomatico = false;
        if (!metaNA) {
          if (this.parametrizacao.valor === 'AUTOMATICO') {
            this.parametrizacao.indCalculoAutomatico = true;
          }
          this.adicionaCampoValor();
        }

        this.$emit('PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
        this.$emit('PARAMETRIZACAO_ACAO_CONTINUAR', this.passo + 1, this.id);
      }
    },
    adicionaCampoValor() {
      const campoValor = this.parametrizacao.listaPadrao.find((p) => p.label === 'valor');
      if (campoValor) {
        return;
      }
      this.parametrizacao.listaPadrao.push(
        {
          nome: this.$tc('label.valor', 1),
          obrigatorio: true,
          label: 'valor',
          habilitaGrid: true,
          habilitaFiltro: false,
          desativaFiltro: true,
          desativaGrid: false,
          habilitaEdicao: false,
          edicaoAprovado: false,
          fluxos: this.campoValorBanco ? this.campoValorBanco.fluxos : [],
        },
      );
    },
    validarPasso3() {
      let tiposSelecionados = [];
      tiposSelecionados = this.parametrizacao.listaFornecedor.filter((a) => a.habilitado);
      if (!this.modoVarejo) {
        tiposSelecionados.push(this.parametrizacao.listaCliente.filter((a) => a.habilitado));
      }
      if (!tiposSelecionados.length) {
        this.$toast(this.modoVarejo ? this.$t('message.selecione_origem_acao')
          : this.$t('message.selecione_foco_acao'));
        return false;
      }
      if (!this.$refs.formLiquidacao.validate()) {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
        return false;
      }
      return true;
    },
    validarSalvarCamposCustomizados() {
      this.$refs.campoCustomizado.abrirDialogSalvar();
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_ACAO_VOLTAR', this.passo - 1);
    },
    alteraPagamentoAcao(item) {
      if (!item.habilitado) {
        item.indExibirTodosClientesAtivos = false;
      }
    },
  },
  mounted() {
    this.setClienteMetadado({
      resource: this.metadadoResource.listarCliente,
    });
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
