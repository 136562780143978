const RelatoriosIntegracao = () => import('@/spa/relatorios-integracao/RelatoriosIntegracao');
const RelatoriosIntegracaoLista = () => import('@/spa/relatorios-integracao/RelatoriosIntegracaoLista');

export default {
  path: 'relatorios-integracao',
  component: RelatoriosIntegracao,
  children: [
    {
      path: '',
      name: 'relatorios-integracao',
      component: RelatoriosIntegracaoLista,
    },
  ],
};
