<template lang="html">
  <v-data-table
    :headers="headers"
    :items="registros"
    :items-per-page="registros.length"
    hide-default-footer
    :no-data-text="$t('message.nao_ha_conteudo')">
    <template v-slot:item.linha="{ item }">
      {{ item.linha }}
    </template>
    <template v-slot:item.descricao="{ item }">
      {{ item.descricao }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: Array,
    registros: Array,
  },
  methods: {
    closeDialog() {
      this.$emit('IMPORTACAODIALOGERROS_CLOSE_DIALOG');
    },
  },
};
</script>

<style lang="css">
.IMPORTACAODIALOGERROS__Table {
  max-height: 90%;
}
</style>
