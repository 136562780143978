<template>
  <v-card style="margin-bottom:1em" class="RelatorioExportacaoPagamentoFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.relatorio_saldo_provisao', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :objeto="filtrosExportacaoPagamento"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { countRelatorioSaldoProvisao } from '@/common/resources/quantidade-registro-relatorio-suzano'; // eslint-disable-line
import { skipLoading } from '../../../produto/common/functions/loading';
import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoSaldoProvisaoFiltrosCampos from './RelatorioExportacaoSaldoProvisaoFiltrosCampos';

export default {
  name: 'RelatorioExportacaoAcaoPagamento',
  mixins: [
    RelatorioExportacaoSaldoProvisaoFiltrosCampos,
  ],
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      ordenacaoCampos: [
        'ano_mes',
        'tipo_acao',
        'divisao',
        'holding',
        'tipo_de_verba',
        'contrato',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosExportacaoPagamento: {},
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    resetaFiltros() {
      this.filtrosExportacaoPagamento = {};
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      const parametros = {
        ...params,
        ano_mes: params.ano_mes.replaceAll('-', ''),
      };

      let quantidadeRegistros = 0;

      countRelatorioSaldoProvisao(parametros, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data.count;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoSaldoProvisaoBloqueadas__AplicaFiltros',
              parametros,
              'saldo_provisao',
              'planejamento_acao');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-pagamento';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-pagamento';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosAjusteVerba;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
};
</script>
<style>
  .RelatorioExportacoesControleApFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
