export default class Passo6 {
  constructor(id, nomeTipoVerba, indLiquidacaoParcial = false, indIntegracaoEnvio = false, indIntegracaoRetorno = false, criacaoPagamento,
    indStatusCriacaoLiquidado = false, indStatusCriacaoPendente = false, indHabilitarEvidencia = false, indEvidencia = false,
    camposDinamicosPagamentoManual = []) {
    this.id = id;
    this.nomeTipoVerba = nomeTipoVerba;
    this.indLiquidacaoParcial = indLiquidacaoParcial;
    this.indIntegracaoEnvio = indIntegracaoEnvio;
    this.indIntegracaoRetorno = indIntegracaoRetorno;
    this.criacaoPagamento = criacaoPagamento;
    this.camposDinamicosPagamentoManual = camposDinamicosPagamentoManual;
    this.indStatusCriacaoLiquidado = indStatusCriacaoLiquidado;
    this.indStatusCriacaoPendente = indStatusCriacaoPendente;
    this.indHabilitarEvidencia = indHabilitarEvidencia;
    this.indEvidencia = indEvidencia;
  }
}
