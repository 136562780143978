var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialogCampoCustomizado),callback:function ($$v) {_vm.dialogCampoCustomizado=$$v},expression:"dialogCampoCustomizado"}},[_c('campos-customizados-form',{ref:"campoCustomizado",attrs:{"desabilitarSelecaoEntidade":true},on:{"atualizarGrid":_vm.atualizarGridCamposCustomizados,"cancelar":_vm.cancelarCamposCustomizados}},[_c('div',{attrs:{"slot":"button"},slot:"button"},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{nativeOn:{"click":function($event){return _vm.cancelarCamposCustomizados($event)}}},[_vm._v(_vm._s(_vm.$t('label.cancelar')))]),_c('v-btn',{attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.validarSalvarCamposCustomizados($event)}}},[_vm._v(_vm._s(_vm.$t('label.salvar')))])],1)],1)])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"card-header",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('title.campo_padrao', 2))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_orcamento.campo_padrao')))}})],1),_c('v-data-table',{attrs:{"id":"config_orcamento_tabela_lista_padrao","headers":_vm.headers,"items":_vm.parametrizacao.listaPadrao,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.contexto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc(("label." + (item.contexto.toLowerCase())), 1))+" ")]}},{key:"item.obrigatorio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.obrigatorio ? 'Sim' : 'Não')+" ")]}}])}),_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('label.campo_customizado', 2))+" "),_c('v-spacer'),_c('div',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"}},[_c('span',{domProps:{"innerHTML":_vm._s(("" + (_vm.$t('label.nao_encontrou_campo_clique_aqui'))))},on:{"click":function($event){$event.stopPropagation();return _vm.novoCampoCustomizado($event)}}})]),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_orcamento.campos_customizados')))}})],1),_c('v-data-table',{attrs:{"id":"config_orcamento_tabela_campos_customizados","headers":_vm.headersCamposDinamicos,"items":_vm.parametrizacao.listaDinamica,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.contexto",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":""},model:{value:(item.contexto),callback:function ($$v) {_vm.$set(item, "contexto", $$v)},expression:"item.contexto"}},[_c('v-radio',{staticClass:"margem",attrs:{"label":_vm.$t('label.nao_aplica'),"value":"N/A","disabled":_vm.somenteLeitura}}),_vm._l((_vm.contextos),function(contexto,indice){return (contexto.value)?_c('v-radio',{key:indice,staticClass:"margem",attrs:{"label":contexto.text,"value":contexto.value,"disabled":_vm.somenteLeitura}}):_vm._e()})],2)]}},{key:"item.obrigatorio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.obrigatorio ? 'Sim' : 'Não')+" ")]}}])}),_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('label.divisao', 2))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_orcamento.divisao')))}})],1),_c('v-row',{staticClass:"ml-4 mr-4 mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{attrs:{"hide-details":"","disabled":_vm.somenteLeitura,"label":_vm.$t('label.habilitar_divisao_orcamento')},model:{value:(_vm.divisaoHabilitada),callback:function ($$v) {_vm.divisaoHabilitada=$$v},expression:"divisaoHabilitada"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"id":"config_orcamento_divisao","name":"config_orcamento_divisao","items":_vm.listaDivisoes,"search-input":_vm.buscaListaDivisao,"no-data-text":_vm.$tc('message.nenhum_registro', 1),"item-text":"nomeDivisao","item-value":"idDivisao","label":("" + (_vm.$tc('label.divisao', 1))),"placeholder":_vm.$tc('label.digite_para_buscar', 1),"clearable":"","disabled":!_vm.divisaoHabilitada || _vm.somenteLeitura,"return-object":""},on:{"update:searchInput":function($event){_vm.buscaListaDivisao=$event},"update:search-input":function($event){_vm.buscaListaDivisao=$event},"change":_vm.setDivisao},model:{value:(_vm.nomeDivisao),callback:function ($$v) {_vm.nomeDivisao=$$v},expression:"nomeDivisao"}})],1)],1),_c('v-data-table',{attrs:{"id":"config_orcamento_tabela_divisao","headers":_vm.headersDivisao,"items":_vm.parametrizacao.listaDivisao,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.removerDivisaoPeloId(item.idDivisao)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.remover')))])])]}}])}),_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('title.cliente', 1))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_orcamento.cliente')))}})],1),_c('v-data-table',{attrs:{"id":"config_orcamento_tabela_cliente","headers":_vm.headersRecursivo,"items":_vm.parametrizacao.listaCliente,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.contexto",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":""},model:{value:(item.contexto),callback:function ($$v) {_vm.$set(item, "contexto", $$v)},expression:"item.contexto"}},[_c('v-radio',{staticClass:"margem",attrs:{"label":_vm.$t('label.nao_aplica'),"value":"N/A","disabled":_vm.somenteLeitura}}),_vm._l((_vm.contextos),function(contexto,indice){return (contexto.value)?_c('v-radio',{key:indice,staticClass:"margem",attrs:{"label":contexto.text,"value":contexto.value,"disabled":_vm.somenteLeitura}}):_vm._e()})],2)]}},{key:"item.aplicar_se_a",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.aplicarse ? item.aplicarse : ' - ')+" ")]}},{key:"item.obrigatorio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.obrigatorio ? 'Sim' : 'Não')+" ")]}}])}),_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('title.produto', 1))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_orcamento.produto')))}})],1),_c('v-data-table',{attrs:{"id":"config_orcamento_tabela_produto","headers":_vm.headersRecursivo,"items":_vm.parametrizacao.listaProduto,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.contexto",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":""},model:{value:(item.contexto),callback:function ($$v) {_vm.$set(item, "contexto", $$v)},expression:"item.contexto"}},[_c('v-radio',{staticClass:"margem",attrs:{"label":_vm.$t('label.nao_aplica'),"value":"N/A","disabled":_vm.somenteLeitura}}),_vm._l((_vm.contextos),function(contexto,indice){return (contexto.value)?_c('v-radio',{key:indice,staticClass:"margem",attrs:{"label":contexto.text,"value":contexto.value,"disabled":_vm.somenteLeitura}}):_vm._e()})],2)]}},{key:"item.aplicar_se_a",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.aplicarse ? item.aplicarse : ' - ')+" ")]}},{key:"item.obrigatorio",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","disabled":_vm.somenteLeitura},model:{value:(item.obrigatorio),callback:function ($$v) {_vm.$set(item, "obrigatorio", $$v)},expression:"item.obrigatorio"}})]}}])}),_c('v-card-title',{staticClass:"card-header mt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('title.campo_geral_planejamento_acao', 2))+" "),_c('v-spacer'),_c('tooltip-ajuda',{attrs:{"mensagem":("" + (_vm.$t('message.ajuda.parametrizacao_orcamento.campos_gerais')))}})],1),_c('v-data-table',{attrs:{"id":"config_orcamento_tabela_lista_outros","headers":_vm.headers,"items":_vm.parametrizacao.listaOutros,"hide-default-footer":"","disable-pagination":"","items-per-page":-1,"no-data-text":_vm.$t('message.nenhum_registro')},scopedSlots:_vm._u([{key:"item.contexto",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":""},model:{value:(item.contexto),callback:function ($$v) {_vm.$set(item, "contexto", $$v)},expression:"item.contexto"}},[_c('v-radio',{staticClass:"margem",attrs:{"label":_vm.$t('label.nao_aplica'),"value":"N/A","disabled":_vm.somenteLeitura}}),_vm._l((_vm.contextos),function(contexto,indice){return (contexto.value)?_c('v-radio',{key:indice,staticClass:"margem",attrs:{"label":contexto.text,"value":contexto.value,"disabled":_vm.somenteLeitura}}):_vm._e()})],2)]}},{key:"item.obrigatorio",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","disabled":_vm.somenteLeitura},model:{value:(item.obrigatorio),callback:function ($$v) {_vm.$set(item, "obrigatorio", $$v)},expression:"item.obrigatorio"}})]}}])}),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"id":"config_orcamento_passo2_botao_cancelar"},on:{"click":_vm.cancelar}},[_vm._v(_vm._s(_vm.$t('label.cancelar')))]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","id":"config_orcamento_passo2_botao_voltar"},on:{"click":_vm.voltarPasso}},[_vm._v(_vm._s(_vm.$t('label.voltar_passo')))]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","id":"config_orcamento_passo2_botao_proximo"},on:{"click":_vm.proximoPasso}},[_vm._v(_vm._s(_vm.$t('label.proximo_passo')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }