<template lang="html">
  <v-card
    style="width: 100%">

    <v-card-title primary-title>
      <h3 class="headline mb-0 text-center" style="width: 100%">{{tipoVerba.nome}}</h3>
    </v-card-title>

    <v-divider></v-divider>
    <v-container>
      <v-row>
        <v-btn text icon
               :disabled="!habilitarVoltar"
               @click="voltarAno">
          <v-icon>
            keyboard_arrow_left
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="mt-2 title">{{ anoFiscal.descricao | truncate(30) }}</span>
          </template>
        {{ anoFiscal.descricao }}
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn text icon :disabled="!habilitarProximo" @click="proximoAno"><v-icon>keyboard_arrow_right</v-icon></v-btn>
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <v-container grid-list-xl v-if="!exibirNovo">
      <v-row v-if="mensagem">
        <p class="text-center" style="color: #EF5350;">{{ mensagem }}</p>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5">{{$tc('label.data_inicio', 1)}}:</v-col>
        <v-col cols="12" sm="7">{{ formatarData(anoFiscal.dataInicio) }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5">{{$tc('label.data_prevista_fim', 1)}}:</v-col>
        <v-col cols="12" sm="7">{{ formatarData(anoFiscal.dataPrevistaFim)}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5">{{$tc('label.status', 1)}}:</v-col>
        <v-col cols="12" sm="7">{{ traduzStatus }}</v-col>
      </v-row>
    </v-container>

    <v-divider v-if="!exibirNovo"></v-divider>

    <v-card-actions v-if="exibirNovo">
      <v-row>
        <v-col cols="12">
          <v-row justify="center" class="pa-3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab @click="novo('novoAnoFiscal')" class="v-btn__fab" color="primary" v-on="on">
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>{{$t('label.novo')}}</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-card-actions v-if="!exibirNovo">
      <v-row justify="space-between">

        <v-col cols="3" class="text-center" v-if="habilitarBloqueio && anoFiscalBloqueado && anoFiscalVigente">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn fab @click="desbloquearAnoFiscal(tipoVerba.id, anoFiscal.id)" class="v-btn__fab" color="primary" v-on="on" :disabled="executandoProcedimento">
                <v-icon>lock</v-icon>
              </v-btn>
            </template>
            <span>{{$t('label.desbloquear')}}</span>
          </v-tooltip>
        </v-col>

        <v-col cols="3" class="text-center" v-if="habilitarBloqueio && !anoFiscalBloqueado && anoFiscalVigente">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn fab @click="bloquearAnoFiscal(tipoVerba.id, anoFiscal.id)" class="v-btn__fab" color="primary" v-on="on" :disabled="executandoProcedimento">
                <v-icon>lock_open</v-icon>
              </v-btn>
            </template>
            <span>{{$t('label.bloquear')}}</span>
          </v-tooltip>
        </v-col>

        <v-col cols="3" class="text-center" v-if="habilitarFechamento && anoFiscalVigente">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn fab @click="fecharConfirmacao(anoFiscal)" class="v-btn__fab" color="primary" v-on="on" :disabled="executandoProcedimento">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>{{$t('label.fechar')}}</span>
          </v-tooltip>
        </v-col>

        <v-col cols="3" class="text-center">
          <v-menu
            :close-on-content-click="false"
            :nudge-width="200"
            left>
            <template v-slot:activator="{ on: menu }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn fab class="v-btn__fab" color="primary" v-on="{ ...tooltip, ...menu }">
                    <v-icon>date_range</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('title.periodo_planejamento')}}</span>
              </v-tooltip>
            </template>
            <v-data-table
              :headers="headersPeriodo"
              :items="anoFiscal.periodos"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              class="elevation-1">
              <template v-slot:item.dataInicio="{ item }">
                {{ formatarData(item.dataInicio) }}
              </template>
              <template v-slot:item.dataFim="{ item }">
                {{ formatarData(item.dataFim) }}
              </template>
              <template v-slot:item.acoes="{ item }" v-if="canPeriodoFiscal">
                <v-tooltip
                    bottom
                    v-if="periodoBloqueado(item.status) && periodoVigente(item.status)">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip }" @click="desbloquearPeriodo(tipoVerba.id, item.id)" icon text color="accent" :disabled="executandoProcedimento">
                      <v-icon>lock</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.desbloquear') }}</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="!periodoBloqueado(item.status) && periodoVigente(item.status)">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip }" @click="bloquearPeriodo(tipoVerba.id, item.id)" icon text color="accent" :disabled="executandoProcedimento">
                      <v-icon>lock_open</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.bloquear') }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="periodoVigente(item.status)">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip }" @click="fecharPeriodoConfirmacao(tipoVerba.id, item.id)" icon text color="accent" :disabled="executandoProcedimento">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.fechar') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-menu>
        </v-col>

        <v-col cols="3" class="text-center" v-if="habilitarPeriodosOrcamento">
          <v-menu
            :close-on-content-click="false"
            :nudge-width="200"
            left>
            <template v-slot:activator="{ on: menu }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn fab class="v-btn__fab" color="primary" v-on="{ ...tooltip, ...menu }" :disabled="!anoFiscal.periodosOrcamento || anoFiscal.periodosOrcamento.length == 0">
                    <v-icon>attach_money</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('title.periodo_orcamento')}}</span>
              </v-tooltip>
            </template>
            <v-data-table
              :headers="headersPeriodo"
              :items="anoFiscal.periodosOrcamento"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              class="elevation-1">
              <template v-slot:item.dataInicio="{ item }">
                {{ formatarData(item.dataInicio) }}
              </template>
              <template v-slot:item.dataFim="{ item }">
                {{ formatarData(item.dataFim) }}
              </template>
              <template v-slot:item.acoes="{ item }" v-if="canPeriodoFiscal">
                <v-tooltip
                    bottom
                    v-if="periodoBloqueado(item.status) && periodoVigente(item.status)">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip }" @click="desbloquearPeriodo(tipoVerba.id, item.id)" icon text color="accent" :disabled="executandoProcedimento">
                      <v-icon>lock</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.desbloquear') }}</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="!periodoBloqueado(item.status) && periodoVigente(item.status)">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip }" @click="bloquearPeriodo(tipoVerba.id, item.id)" icon text color="accent" :disabled="executandoProcedimento">
                      <v-icon>lock_open</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.bloquear') }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="periodoVigente(item.status)">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip }" @click="fecharPeriodoConfirmacao(tipoVerba.id, item.id)" icon text color="accent" :disabled="executandoProcedimento">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.fechar') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-menu>
        </v-col>

      </v-row>
    </v-card-actions>

    <confirm
      ref="confirmDialog"
      :message="message"
      @agree="acaoFechar">
    </confirm>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import AnoFiscalTipoEvento from './AnoFiscalTipoEvento';

export default {
  name: 'AnoFiscalCard',
  mixins: [AnoFiscalTipoEvento],
  components: {
    Confirm,
  },
  data() {
    return {
      index: 1,
      message: '',
      ano: {},
      periodo: {},
      anos: [],
      acaoFechar: () => { },
      resourcesAnoFiscal: this.$api.anoFiscal(this.$resource),
      resourcesOrcamentoTipoVerba: this.$api.orcamentoTipoVerba(this.$resource),
      headers: [
        { text: this.$t('label.descricao'), value: 'descricao', sortable: false },
        { text: this.$t('label.data_inicio'), value: 'dataInicio', sortable: false },
        { text: this.$t('label.data_fim'), value: 'dataFim', sortable: false },
      ],
    };
  },
  props: {
    tipoVerba: null,
    habilitarBloqueio: {
      type: Boolean,
      default: false,
    },
    habilitarFechamento: {
      type: Boolean,
      default: false,
    },
    habilitarPeriodosOrcamento: {
      type: Boolean,
      default: true,
    },
  },
  filters: {
    truncate(value, limit) {
      if (value && value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canPeriodoFiscal() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'PERIODO_FISCAL').length;
    },
    headersPeriodo() {
      const headers = Object.assign([], this.headers);
      if (this.canPeriodoFiscal) {
        headers.push({ text: '', value: 'acoes', sortable: false });
      }
      return headers;
    },
    exibirNovo() {
      return this.index >= this.anos.length;
    },
    anoFiscal() {
      if (this.exibirNovo) return { descricao: this.$tc('label.novo', 1) };
      return this.anos[this.index];
    },
    anoFiscalBloqueado() {
      return this.anoFiscal.status === 'VIGENTE_COM_BLOQUEIO';
    },
    anoFiscalVigente() {
      return this.anoFiscal.status === 'VIGENTE_COM_BLOQUEIO'
        || this.anoFiscal.status === 'VIGENTE';
    },
    traduzStatus() {
      return this.$tc(`label.status_ano_fiscal.${this.anoFiscal.status}`, 1);
    },
    habilitarVoltar() {
      return this.index > 0 && !!this.anos.length;
    },
    habilitarProximo() {
      return this.index < this.anos.length;
    },
    temProximo() {
      return this.index < this.anos.length - 1;
    },
  },
  methods: {
    periodoVigente(status) {
      return status === 'VIGENTE_COM_BLOQUEIO' || status === 'VIGENTE';
    },
    periodoBloqueado(status) {
      return status === 'VIGENTE_COM_BLOQUEIO';
    },
    tratarStatus(status) {
      return this.$tc(`label.status_ano_fiscal.${status}`, 1);
    },
    voltarAno() {
      this.index -= 1;
    },
    proximoAno() {
      this.index += 1;
    },
    formatarData(valor) {
      return moment(valor).format('DD/MM/YYYY');
    },
    carregarAnoFiscal() {
      if (!this.tipoVerba) return;
      this.idTipoVerba = this.tipoVerba.id;
      if (!this.naoLimparMensagem) {
        this.mensagem = '';
      }
      this.naoLimparMensagem = false;
      this.resourcesAnoFiscal.pesquisarPorTipo({ idTipoVerba: this.tipoVerba.id })
        .then(this.tratarRetorno)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    tratarRetorno(res) {
      this.anos = res.body;
      this.anos.forEach((a, i) => {
        if (a.status === 'VIGENTE'
          || a.status === 'VIGENTE_COM_BLOQUEIO') this.index = i;
      });
    },
    fecharPeriodoAno() {
      this.fecharPeriodo(this.tipoVerba.id, this.periodo.id);
    },
    fecharPeriodoConfirmacao(item) {
      this.periodo = item;
      this.acaoFechar = this.fecharPeriodoAno;
      this.message = this.$t('message.fechamento_periodo_fiscal');
      this.$refs.confirmDialog.open();
    },
    fecharAno() {
      if (this.temProximo) {
        this.fecharAnoFiscal(this.tipoVerba.id, this.ano.id);
      } else {
        this.novo('novoFecharAnoFiscal', this.ano.id);
      }
    },
    fecharConfirmacao(item) {
      this.ano = item;
      this.acaoFechar = this.fecharAno;
      this.message = this.$t('message.fechamento_ano_fiscal');
      this.$refs.confirmDialog.open();
    },
    novo(rota, idAnoFechar) {
      const param = {
        name: rota,
        params: {
          tipo: this.tipoVerba.id,
        },
      };
      if (idAnoFechar) {
        param.params.idAnoFechar = idAnoFechar;
      }
      this.$router.push(param);
    },
  },
  mounted() {
    this.carregarAnoFiscal();
  },
};
</script>
<style>
  .AnoFiscalCard__periodos > ul {
    padding: 0px !important;
  }
</style>
