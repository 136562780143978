import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('liquidacao', 'suzano/pagamento/listagem');

const basePathJob = getBasePath('job', 'suzano/pagamento/listagem');

const pagamentoActions = {
  gerarAap: { method: 'POST', url: `${basePathJob}/gerarAap` },
};

export default (resource) => resource(`${basePath}`, {}, pagamentoActions);

export const listarPagamentosSuzano = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'em-lista', parametros).doGet();

export const listarPagamentosPendentesSuzano = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'em-lista-app', parametros).doGet();
