<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card class="elevation-1">
      <v-card-title>
        <h3 class="headline mb-0">{{ $t('title.cadastrar_ano_fiscal') }}</h3>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-form ref="formAnoFiscal">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                id="tipo"
                name="tipo"
                style="font-weight: bold"
                v-model="tipo.descricao"
                :label="$tc('label.verba', 1)"
                readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                id="descricao_ano"
                name="descricao_ano"
                v-model="anoFiscal.descricao"
                :label="`${$t('label.descricao')} *`"
                :readonly="somenteLeitura"
                :counter="200"
                maxlength="200"
                :rules="[rules.required]">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-menu
                ref="data_inicio_ano"
                :close-on-content-click="false"
                v-model="menuDataInicio"
                id="dp_data_inicio_ano"
                name="dp_data_inicio_ano"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    ref="dataInicioAno"
                    class="Form-text__invalid"
                    required
                    :return-masked-value="true"
                    v-mask="'##-##-####'"
                    :rules="[rules.required, rules.validDate]"
                    v-model="dataInicioFormatada"
                    :label="`${$t('label.data_inicio')} *`"
                    prepend-icon="event"
                    readonly>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  color="primary"
                  v-model="dataInicio"
                  @input="menuDataInicio = false">
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                disabled
                id="data_fim_ano"
                name="data_fim_ano"
                v-model="dataPrevistaFim"
                :label="$t('label.data_prevista_fim')"
                readonly>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-divider></v-divider>

        <ano-fiscal-periodos
          ref="periodos"
          :somenteLeitura="somenteLeitura"
          :periodos="periodos"
          :dataInicio="dataInicio"
          @PERIODO_INCLUIDO_OU_EXCLUIDO="tratarAlteracaoPeriodo">
        </ano-fiscal-periodos>

        <v-divider></v-divider>

        <ano-fiscal-periodos-orcamento
          ref="periodosOrcamento"
          :somenteLeitura="somenteLeitura"
          :periodos="periodosOrcamento"
          :dataInicio="dataInicio"
          @PERIODO_INCLUIDO_OU_EXCLUIDO="tratarAlteracaoPeriodo"
          @SINCRONIZACAO_PERIODOS="sincronizacaoPeriodos">
        </ano-fiscal-periodos-orcamento>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn :disabled="isSave" @click.native="validarAno" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="$t('message.salvar_ano_fiscal')"
      @agree="save"
      @disagree="isSave=false">
    </confirm>

  </v-form>
</template>

<script>
import moment from 'moment';
import {
  formatDateDDMMYYYY,
} from '../../common/functions/date-utils';
import AnoFiscalPeriodos from './AnoFiscalPeriodos';
import AnoFiscalPeriodosOrcamento from './AnoFiscalPeriodosOrcamento';
import AnoFiscalTipoEvento from './AnoFiscalTipoEvento';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'AnoFiscalForm',
  mixins: [AnoFiscalTipoEvento],
  data() {
    return {
      anoFiscal: {},
      periodos: [],
      isSave: false,
      periodosOrcamento: [],
      periodosSincronizados: false,
      tipo: {},
      tipoOrcamento: {},
      menuDataInicio: false,
      dataPrevistaFim: '',
      qtdDiasAddDataInicio: 0,
      dataInicio: {
        type: String,
        default: null,
      },
      formato: 'YYYY-MM-DD',
      formatoSalvar: 'YYYY-MM-DD',
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
      resourcesAnoFiscal: this.$api.anoFiscal(this.$resource),
      resourcesOrcamentoTipoVerba: this.$api.orcamentoTipoVerba(this.$resource),
    };
  },
  computed: {
    dataInicioFormatada: {
      get() {
        return formatDateDDMMYYYY(this.dataInicio);
      },
      set(newValue) {
        this.dataInicio = parseDateYYYYMMDD(newValue);
      },
    },
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    fecharDepoisSalvar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    tratarAlteracaoPeriodo(dataFim) {
      if (dataFim > this.anoFiscal.dataPrevistaFim) {
        this.anoFiscal.dataPrevistaFim = dataFim;
        this.dataPrevistaFim = formatDateDDMMYYYY(this.anoFiscal.dataPrevistaFim);
        if (this.periodosSincronizados) {
          this.periodosOrcamento = [...this.$refs.periodos.periodos];
        }
      }
    },
    sincronizacaoPeriodos(sincronizarPeriodos) {
      if (sincronizarPeriodos) {
        this.periodosOrcamento = [...this.$refs.periodos.periodos];
      }
      this.periodosSincronizados = sincronizarPeriodos;
    },
    openSaveDialog() {
      this.$refs.confirmDialog.open();
      this.isSave = true;
    },
    openDialog(ref) {
      this.$refs[ref].open();
    },
    validarAno() {
      if (this.$refs.formAnoFiscal.validate()) {
        const periodoPreenchido = this.$refs.periodos.getPeriodos().length > 0;
        if (!periodoPreenchido) {
          this.$refs.periodos.validar();
          return;
        }
        if (!this.periodosSincronizados) {
          const periodoOrcamentarioPreenchido = this.$refs.periodosOrcamento.getPeriodos().length > 0;

          if (!periodoOrcamentarioPreenchido) {
            this.$refs.periodosOrcamento.validar();
            return;
          }
        }
        this.openSaveDialog();
      }
    },
    montarAno() {
      this.idOrigemVerba = this.$route.params.tipo;
      this.mensagem = '';
      this.anoFiscal.idTipoVerba = this.$route.params.tipo;
      this.anoFiscal.descricao = `${this.$tc('label.ano_fiscal')} ${this.anoAtual()}`;
      this.anoFiscal.dataInicio = this.hoje();
      this.dataInicio = this.anoFiscal.dataInicio;
      this.anoFiscal.dataPrevistaFim = '';
      this.buscarTipo();
    },
    hoje() {
      return moment().format(this.formatoSalvar);
    },
    plusOneDay(data) {
      return this.plusNDay(data, 1);
    },
    plusNDay(data, qtd) {
      return moment(data, this.formatoSalvar).add(qtd, 'day').add(1, 'hour').format(this.formatoSalvar);
    },
    anoAtual() {
      return moment().format('YYYY');
    },
    cancel() {
      this.$router.push({ name: 'ano-fiscal' });
    },
    save() {
      this.resourcesAnoFiscal.gravar(this.prepararAnoSalvar())
        .then(() => {
          if (this.fecharDepoisSalvar) {
            this.fecharAnoFiscal(this.$route.params.tipo, this.$route.params.idAnoFechar);
          }
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$router.push({ name: 'ano-fiscal' });
          this.isSave = false;
        })
        .catch((err) => {
          this.$error(this, err);
          this.isSave = false;
        });
    },
    alterarFormatoSalvar(data) {
      return moment(data, this.formato).format(this.formatoSalvar);
    },
    prepararAnoSalvar() {
      const anoSalvar = {};
      anoSalvar.idTipoVerba = this.anoFiscal.idTipoVerba;
      anoSalvar.descricao = this.anoFiscal.descricao;
      anoSalvar.dataInicio = this.alterarFormatoSalvar(this.dataInicio);
      anoSalvar.dataPrevistaFim = this.alterarFormatoSalvar(this.anoFiscal.dataPrevistaFim);
      anoSalvar.periodos = [...this.$refs.periodos.getPeriodos()];
      anoSalvar.periodosOrcamento = [...this.$refs.periodosOrcamento.getPeriodos()];
      return anoSalvar;
    },
    buscarTipo() {
      this.resourcesOrcamentoTipoVerba.buscarTipoVerba({ id: this.anoFiscal.idTipoVerba })
        .then((res) => {
          this.tipo = res.data;
        }, (err) => {
          this.$error(this, err);
          this.cancel();
        });
    },
    preencherDataInicio() {
      this.anoFiscal.dataInicio = this.plusNDay(this.hoje());
      this.dataInicio = this.anoFiscal.dataInicio;
      this.$forceUpdate();
    },
  },
  components: {
    Confirm,
    AnoFiscalPeriodos,
    AnoFiscalPeriodosOrcamento,
  },
  beforeMount() {
    this.montarAno();
  },
  mounted() {
    this.buscarTipo();
    this.preencherDataInicio();
  },
};
</script>
