export default {
  data() {
    return {
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'tipo_integracao',
            nomCampoId: 'tipo_integracao',
            tipoCampo: 'LISTA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { id: 1, nome: 'SELLIN' },
                  { id: 2, nome: 'PRODUTO' },
                  { id: 3, nome: 'CUTOFF' },
                  { id: 4, nome: 'PAGAMENTO_LIQUIDADO' },
                  { id: 5, nome: 'CLIENTE' },
                  { id: 6, nome: 'PRE_CADASTRO_USUARIO' },
                ],
              })),
              itemValue: 'nome',
              itemText: 'nome',
            },
          },
        ],
      },
    };
  },
};
