const PlanejamentoAcao = () => import('../../spa/planejamento-acao-massivo/PlanejamentoAcao');
const PlanejamentoAcaoList = () => import('../../spa/planejamento-acao-massivo/list/PlanejamentoAcaoList');
const PlanejamentoAcaoForm = () => import('../../spa/planejamento-acao-massivo/form/PlanejamentoAcaoForm');
const PlanejamentoEdicaoAcaoForm = () => import('../../spa/planejamento-acao-massivo/form/PlanejamentoEdicaoAcaoForm');

export default {
  path: 'planejamento-acao-massivo',
  component: PlanejamentoAcao,
  children: [
    {
      path: '',
      name: 'planejamento-acao-massivo',
      component: PlanejamentoAcaoList,
    },
    {
      path: 'nova',
      name: 'novaAcaoMassiva',
      component: PlanejamentoAcaoForm,
      props: {
        default: false,
        somenteLeitura: false,
        copia: false,
        edicao: false,
        novo: true,
      },
    },
    {
      path: ':idAcao/editar',
      name: 'editarAcaoMassiva',
      component: PlanejamentoEdicaoAcaoForm,
      props: {
        somenteLeitura: false,
        edicao: true,
        novo: false,
      },
    },
  ],
};
