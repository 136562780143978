<template lang="html">
  <div>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" :editable="e1 > 1" edit-icon="$vuetify.icons.complete" step="1">{{ $t('label.geral') }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" :editable="e1 > 2" edit-icon="$vuetify.icons.complete" step="2">{{ $t('label.visibilidade') }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" :editable="e1 > 3" edit-icon="$vuetify.icons.complete" step="3">{{ $tc('label.cadastro', 1) }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 4" :editable="e1 > 4" edit-icon="$vuetify.icons.complete" step="4">{{ $tc('label.configuracao', 2) }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step v-if="fluxoApuracao || isFluxoApenasPlanejamento" :complete="e1 > passoApuracao" :editable="e1 > passoApuracao" edit-icon="$vuetify.icons.complete" :step="passoApuracao">{{ $tc('label.apuracao', 1) }}</v-stepper-step>

        <v-divider v-if="fluxoApuracao || isFluxoApenasPlanejamento"></v-divider>

        <v-stepper-step v-if="fluxoLiquidacao" :complete="e1 > passoLiquidacao" :editable="e1 > passoLiquidacao" edit-icon="$vuetify.icons.complete" :step="passoLiquidacao">{{ $tc('label.liquidacao', 1) }}</v-stepper-step>

        <v-divider v-if="fluxoLiquidacao"></v-divider>

        <v-stepper-step :complete="e1 > passoOrdenacao" :editable="e1 > passoOrdenacao" edit-icon="$vuetify.icons.complete" :step="passoOrdenacao">{{ $tc('label.ordenacao', 1) }}</v-stepper-step>

        <v-divider ></v-divider>

        <v-stepper-step :complete="e1 > passoGrid" :editable="e1 > passoGrid" edit-icon="$vuetify.icons.complete" :step="passoGrid">{{ $tc('label.grid_filtros', 1) }}</v-stepper-step>

        <v-divider ></v-divider>

      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <parametrizacao-acao-passo-1
            :id="id"
            :modoVarejo="modoVarejo"
            @PARAMETRIZACAO_ACAO_CANCELAR="cancelar"
            @PARAMETRIZACAO_ACAO_CONTINUAR="preencherInvestimentoDebito"
            @PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO="atualizarObjeto"
            @PARAMETRIZACAO_ACAO_DEFINIR_PASSOS="definirPassoFluxoTrade">
          </parametrizacao-acao-passo-1>
        </v-stepper-content>

        <v-stepper-content step="2">
          <parametrizacao-acao-passo-2
            :id="id"
            :modoVarejo="modoVarejo"
            @PARAMETRIZACAO_ACAO_CANCELAR="cancelar"
            @PARAMETRIZACAO_ACAO_CONTINUAR="continuar"
            @PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO="atualizarObjeto"
            @PARAMETRIZACAO_ACAO_VOLTAR="voltar"
          >
          </parametrizacao-acao-passo-2>
        </v-stepper-content>

        <v-stepper-content step="3">
          <parametrizacao-acao-passo-3
            :id="id"
            :modoVarejo="modoVarejo"
            ref="passo3"
            :ind-invetimento-debito="indInvestimentoDebito"
            @PARAMETRIZACAO_ACAO_CANCELAR="cancelar"
            @PARAMETRIZACAO_ACAO_CONTINUAR="continuar"
            @PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO="atualizarObjeto"
            @PARAMETRIZACAO_ACAO_VOLTAR="voltar"
          >
          </parametrizacao-acao-passo-3>
        </v-stepper-content>

        <v-stepper-content step="4">
          <parametrizacao-acao-passo-4
            :id="id"
            :modoVarejo="modoVarejo"
            ref="passo4"
            @PARAMETRIZACAO_ACAO_CANCELAR="cancelar"
            @PARAMETRIZACAO_ACAO_CONTINUAR="continuar"
            @PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO="atualizarObjeto"
            @PARAMETRIZACAO_ACAO_VOLTAR="voltar"
          >
          </parametrizacao-acao-passo-4>
        </v-stepper-content>

        <v-stepper-content :step="passoApuracao" v-if="fluxoApuracao">
          <parametrizacao-acao-passo-5
            :id="id"
            :modoVarejo="modoVarejo"
            ref="passo5"
            :passo="passoApuracao"
            :fluxo-liquidacao="fluxoLiquidacao"
            @PARAMETRIZACAO_ACAO_CANCELAR="cancelar"
            @PARAMETRIZACAO_ACAO_CONTINUAR="continuar"
            @PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO="atualizarObjeto"
            @PARAMETRIZACAO_ACAO_VOLTAR="voltar"
          >
          </parametrizacao-acao-passo-5>
        </v-stepper-content>

        <v-stepper-content :step="passoApuracao" v-if="isFluxoApenasPlanejamento">
          <parametrizacao-acao-passo-5-heranca-config
            :id="id"
            :modoVarejo="modoVarejo"
            ref="passo5Heranca"
            :passo="passoApuracao"
            @PARAMETRIZACAO_ACAO_CANCELAR="cancelar"
            @PARAMETRIZACAO_ACAO_CONTINUAR="continuar"
            @PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO="atualizarObjeto"
            @PARAMETRIZACAO_ACAO_VOLTAR="voltar"
          >
          </parametrizacao-acao-passo-5-heranca-config>
        </v-stepper-content>

        <v-stepper-content :step="passoLiquidacao" v-if="fluxoLiquidacao">
          <parametrizacao-acao-passo-6
            :id="id"
            :modoVarejo="modoVarejo"
            :passo="passoLiquidacao"
            ref="passo6"
            :fluxo="fluxo"
            @PARAMETRIZACAO_ACAO_CANCELAR="cancelar"
            @PARAMETRIZACAO_ACAO_CONTINUAR="continuar"
            @PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO="atualizarObjeto"
            @PARAMETRIZACAO_ACAO_VOLTAR="voltar"
            @PARAMETRIZACAO_LIQ_MANUAL_ALTERADA="atualizarLiquidacaoManual"
          >
          </parametrizacao-acao-passo-6>
        </v-stepper-content>

        <v-stepper-content :step="passoOrdenacao">
          <parametrizacao-acao-passo-7
            :id="id"
            :passo="passoOrdenacao"
            ref="passo7"
            @PARAMETRIZACAO_ACAO_CANCELAR="cancelar"
            @PARAMETRIZACAO_ACAO_CONTINUAR="continuar"
            @PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO="atualizarObjeto"
            @PARAMETRIZACAO_ACAO_VOLTAR="voltar"
          >
          </parametrizacao-acao-passo-7>
        </v-stepper-content>

        <v-stepper-content :step="passoGrid">
          <parametrizacao-acao-passo-8
            :id="id"
            :passo="passoGrid"
            ref="passo8"
            @PARAMETRIZACAO_ACAO_CANCELAR="cancelar"
            @PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO="atualizarObjeto"
            @PARAMETRIZACAO_ACAO_VOLTAR="voltar"
            @PARAMETRIZACAO_ACAO_CONCLUIR="validaConcluirPassos"
          >
          </parametrizacao-acao-passo-8>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <confirm
      ref="confirmacaoHerancaConfigDialog"
      max-width="500"
      :persistent="true"/>

    <confirm
      ref="impossibilitadoAtivarInativarDialog"
      max-width="500"
      :agree-label="$t('label.ok')"
      :hide-disagree="true"
      :persistent="true"/>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';
import Confirm from '@/produto/shared-components/vuetify/dialog/Confirm';
import ParametrizacaoAcaoPasso1 from '@/produto/spa/parametrizacao/acao/ParametrizacaoAcaoPasso1';
import ParametrizacaoAcaoPasso2 from '@/produto/spa/parametrizacao/acao/ParametrizacaoAcaoPasso2';
import ParametrizacaoAcaoPasso3 from '@/produto/spa/parametrizacao/acao/ParametrizacaoAcaoPasso3';
import ParametrizacaoAcaoPasso4 from '@/spa/parametrizacao/acao/ParametrizacaoAcaoPasso4';
import ParametrizacaoAcaoPasso5 from '@/produto/spa/parametrizacao/acao/ParametrizacaoAcaoPasso5';
import ParametrizacaoAcaoPasso5HerancaConfig from '@/produto/spa/parametrizacao/acao/ParametrizacaoAcaoPasso5HerancaConfig';
import ParametrizacaoAcaoPasso6 from '@/spa/parametrizacao/acao/ParametrizacaoAcaoPasso6';
import ParametrizacaoAcaoPasso7 from '@/produto/spa/parametrizacao/acao/ParametrizacaoAcaoPasso7';
import ParametrizacaoAcaoPasso8 from '@/produto/spa/parametrizacao/acao/ParametrizacaoAcaoPasso8';

export default {
  name: 'ParametrizacaoAcaoForm',
  data() {
    return {
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),
      fluxo: '',
      e1: 1,
      id: null,
      objetoAcao: {},
      fluxoApuracao: false,
      fluxoLiquidacao: false,
      passoApuracao: 5,
      passoLiquidacao: 6,
      passoOrdenacao: 5,
      passoGrid: 6,
      indInvestimentoDebito: false,
      tokenEtapaParalela: '_E_',
      isTipoAcaoAgrupadoraEmContrato: false,
      contratosTipoAcaoAgrupadora: null,
    };
  },
  components: {
    ParametrizacaoAcaoPasso1,
    ParametrizacaoAcaoPasso2,
    ParametrizacaoAcaoPasso3,
    ParametrizacaoAcaoPasso4,
    ParametrizacaoAcaoPasso5,
    ParametrizacaoAcaoPasso5HerancaConfig,
    ParametrizacaoAcaoPasso6,
    ParametrizacaoAcaoPasso7,
    ParametrizacaoAcaoPasso8,
    Confirm,
  },
  computed: {
    ...generateComputed('Param Acao', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
    ...mapGetters('implantacao', [
      'implantacao',
    ]),
    modoVarejo() {
      if (this.implantacao && this.implantacao.modo === 'VAREJO') {
        return true;
      }
      return false;
    },
    isFluxoApenasPlanejamento() {
      return !this.fluxoApuracao && !this.fluxoLiquidacao;
    },
  },
  methods: {
    preencherInvestimentoDebito(proximoPasso, id) {
      this.indInvestimentoDebito = false;
      if (this.objetoAcao && this.objetoAcao.passo1) {
        if (this.objetoAcao.passo1.investimentos && this.objetoAcao.passo1.investimentos.length) {
          for (let i = 0; i < this.objetoAcao.passo1.investimentos.length; i += 1) {
            if (this.objetoAcao.passo1.investimentos[i].habilita
              && this.objetoAcao.passo1.investimentos[i].operacao === 'DEBITO') {
              this.indInvestimentoDebito = true;
              i = this.objetoAcao.passo1.investimentos.length;
            }
          }
        }
        this.fluxo = this.objetoAcao.passo1.fluxo;
      }
      this.continuar(proximoPasso, id);
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    cancelar() {
      this.$router.push({ name: 'parametrizacao-acao' });
    },
    continuar(proximoPasso, id) {
      this.e1 = proximoPasso;

      if (id) {
        this.id = id;
      }

      switch (proximoPasso) {
        case 2: {
          this.definirPassoFluxoTrade();
          break;
        }
        case 3: {
          this.$refs.passo3.setFluxos(this.objetoAcao.passo1.fluxo);
          break;
        }
        case 4: {
          this.$refs.passo4.setTiposInvestimento(
            this.objetoAcao.passo1.investimentos,
            this.objetoAcao.passo3.listaProduto,
          );

          const todosCampos = [
            ...this.objetoAcao.passo3.listaPadrao,
            ...this.objetoAcao.passo3.listaCliente
              .filter((campo) => campo.habilitado),
            ...this.objetoAcao.passo3.listaCarteiraCliente
              .filter((campo) => campo.mnemonico !== 'UNIDADE_NEGOCIO' && campo.habilitado),
            ...this.objetoAcao.passo3.listaDinamica,
            ...this.objetoAcao.passo3.listaFornecedor
              .filter((campo) => campo.habilitado),
            ...this.objetoAcao.passo3.listaProduto,
          ];

          const fluxos = this.objetoAcao.passo1.fluxo,
            camposDinamicos = this.objetoAcao.passo3.listaDinamica;

          const listaFluxos = this.objetoAcao.passo1.fluxo.split(',');
          for (let i = 0; i < listaFluxos.length; i += 1) {
            if (listaFluxos[i].match('LIQUIDACAO')) {
              todosCampos.push(...this.objetoAcao.passo3.listaPadraoLiquidacao);
              todosCampos.push(...this.objetoAcao.passo3.listaLiquidacaoManual);
              break;
            }
          }

          this.$refs.passo4.setCamposConfiguracao(todosCampos, fluxos);
          this.$refs.passo4.setCamposDinamicos(camposDinamicos);
          this.$refs.passo4.setFluxosAprovacao();
          break;
        }
        case this.passoApuracao: {
          const fluxos = this.objetoAcao.passo1.fluxo;
          const { investimentos } = this.objetoAcao.passo1;
          if (this.isFluxoApenasPlanejamento) {
            this.$refs.passo5Heranca.setFluxos(fluxos);
            this.$refs.passo5Heranca.setInvestimentos(investimentos);
          } else {
            this.$refs.passo5.setFluxos(fluxos);
            this.$refs.passo5.setInvestimentos(investimentos);
          }
          break;
        }
        case this.passoLiquidacao: {
          const campos = this.$refs.passo3.camposPagamentoHabilitados();
          this.$refs.passo6.setCamposUnicoPagador(campos);
          this.$refs.passo6
            .setHabilitacaoFluxoApuracao(this.$refs.passo4.isFluxoApuracaoHabilitado);
          this.$refs.passo6.ajustarCamposDinamicos();
          break;
        }
        case this.passoOrdenacao: {
          if (this.$refs.passo6 && this.$refs.passo6.isLiquidacaoManual) {
            if (this.isFluxoApenasPlanejamento) {
              this.$refs.passo5Heranca.indEdicaoValorPagamento = false;
              this.$refs.passo5Heranca.indJustificativaAlterarValor = false;
            } else {
              this.$refs.passo5.indEdicaoValorPagamento = false;
              this.$refs.passo5.indJustificativaAlterarValor = false;
            }
            this.$refs.passo7.setListaLiquidacaoManual(this.montarListaLiquidacaoManual());
          }
          const isPasso7 = true;
          this.$refs.passo7.setLista(this.montarLista(isPasso7));
          break;
        }
        case this.passoGrid: {
          if (this.$refs.passo6 && this.$refs.passo6.isLiquidacaoManual) {
            this.$refs.passo8.setListaLiquidacaoManual(this.montarListaLiquidacaoManual());
          }
          this.$refs.passo8.setListaPlanejamento(this.montarLista());
          break;
        }
        // no default
      }
    },
    definirPassoFluxoTrade() {
      let fluxos = this.objetoAcao.passo1.fluxo.split(',');
      const possuiEtapaParalela = fluxos
        .filter((f) => f.indexOf(this.tokenEtapaParalela) >= 0).length > 0;

      if (possuiEtapaParalela) {
        const etapaParalela = fluxos.splice(fluxos.length - 1, 1)[0];
        fluxos = [...fluxos, ...etapaParalela.split(this.tokenEtapaParalela)];
      }

      const qtdePassosCadastrais = 4;
      if (fluxos.indexOf('APURACAO') !== -1) {
        this.fluxoApuracao = true;
        this.passoApuracao = qtdePassosCadastrais + 1;
      } else {
        this.passoApuracao = -1;
        this.fluxoApuracao = false;
      }

      if (fluxos.indexOf('LIQUIDACAO') !== -1) {
        this.fluxoLiquidacao = true;
        this.passoLiquidacao = qtdePassosCadastrais + (this.fluxoApuracao ? 2 : 1);
      } else {
        this.passoLiquidacao = -1;
        this.fluxoLiquidacao = false;
      }

      if (this.isFluxoApenasPlanejamento) {
        this.passoApuracao = qtdePassosCadastrais + 1;
      }

      this.passoOrdenacao = qtdePassosCadastrais + 1
        + (this.fluxoApuracao || this.isFluxoApenasPlanejamento ? 1 : 0)
        + (this.fluxoLiquidacao ? 1 : 0);
      this.passoGrid = qtdePassosCadastrais + 2
        + (this.fluxoApuracao || this.isFluxoApenasPlanejamento ? 1 : 0)
        + (this.fluxoLiquidacao ? 1 : 0);
      this.$forceUpdate();
    },
    montarLista(isPasso7 = false) {
      let lista = [];
      lista = lista.concat(this.objetoAcao.passo3.listaPadrao)
        .filter((item) => item.PLANEJAMENTO);
      lista = lista.concat(this.objetoAcao.passo3.listaCliente
        .filter((item) => item.habilitado));
      lista = lista.concat(this.objetoAcao.passo3.listaCarteiraCliente
        .filter((item) => item.mnemonico !== 'UNIDADE_NEGOCIO' && item.habilitado));
      lista = lista.concat(this.objetoAcao.passo3.listaFornecedor
        .filter((item) => item.habilitado));
      lista = lista.concat(this.objetoAcao.passo3.listaProduto
        .filter((item) => item.PLANEJAMENTO && !item.excecao));
      if (isPasso7) {
        lista = lista.concat(this.objetoAcao.passo3.listaDinamica
          .filter((item) => item.PLANEJAMENTO || (item.etapasFluxo && item.etapasFluxo.length)));
      } else {
        lista = lista.concat(this.objetoAcao.passo3.listaDinamica
          .filter((item) => item.PLANEJAMENTO));
      }

      const { categoriaAcao } = this.objetoAcao.passo1;
      if (categoriaAcao && categoriaAcao !== '') {
        lista = lista.concat(
          this.criarItemLista(
            this.$tc('label.categoria_acao', 1),
            'categoria_acao',
            lista.length + 1,
          ),
        );
      }

      if (this.objetoAcao.passo5) {
        const { apuracao } = this.objetoAcao.passo5;
        if (apuracao) {
          if (apuracao.periodos.length > 0) {
            lista = lista.concat(
              this.criarItemLista(
                this.$tc('label.periodo_apuracao', 1),
                'periodo_apuracao',
                lista.length + 1,
              ),
            );
          }
          if (apuracao.calculosApuracao && apuracao.calculosApuracao.length > 0) {
            lista = lista.concat(
              this.criarItemLista(
                this.$tc('label.calculo_apuracao', 2),
                'calculo_apuracao',
                lista.length + 1,
              ),
            );
          }
          if (apuracao.bases && apuracao.bases.length > 0) {
            lista = lista.concat(
              this.criarItemLista(
                this.$tc('label.base_calculo', 1),
                'base_calculo',
                lista.length + 1,
              ),
            );
          }
          if (apuracao.bonificacoes && apuracao.bonificacoes.length > 0) {
            lista = lista.concat(
              this.criarItemLista(
                this.$tc('label.forma_bonificacao', 1),
                'forma_bonificacao',
                lista.length + 1,
              ),
            );
          }
          if (apuracao.pagamentos.length > 0) {
            lista = lista.concat(
              this.criarItemLista(
                this.$tc('label.tipo_pagamento', 1),
                'tipo_pagamento',
                lista.length + 1,
              ),
            );
          }
          if (apuracao.basesVendas && apuracao.basesVendas.length > 0) {
            lista = lista.concat(
              this.criarItemLista(
                this.$tc('label.investimento_venda', 1),
                'investimento_venda',
                lista.length + 1,
              ),
            );
          }
        }
      }

      if (this.objetoAcao.passo1.fluxo.indexOf('PLANEJAMENTO') !== -1) {
        lista = lista.concat(
          this.criarItemLista(
            this.$tc('label.usuario', 1),
            'usuario',
            lista.length + 1,
            true,
            true,
            true,
          ),
        );
      }

      return lista;
    },
    montarListaLiquidacaoManual() {
      let lista = [];
      lista = lista.concat(this.objetoAcao.passo3.listaLiquidacaoManual)
        .filter((item) => item.LIQUIDACAO);
      lista = lista.concat(this.objetoAcao.passo6.camposDinamicosPagamentoManual);
      return lista;
    },
    criarItemLista(nome, label, ordem, habilitaFiltro = false, desativaGrid = false, desativaFiltro = false) {
      return {
        nome,
        label,
        apenas_ordenacao: false,
        habilitaFiltro,
        habilitaGrid: false,
        desativaFiltro,
        desativaGrid,
        ordenacao: ordem,
      };
    },
    atualizarLiquidacaoManual(liqManualHabilitado) {
      if (this.$refs.passo5) {
        this.$refs.passo5.setLiquidacaoManual(liqManualHabilitado);
      }
      if (this.$refs.passo5Heranca) {
        this.$refs.passo5Heranca.setLiquidacaoManual(liqManualHabilitado);
      }
    },
    atualizarObjeto(passo, objeto) {
      if (passo === this.passoApuracao && this.fluxoApuracao) {
        this.objetoAcao.passo5 = { ...objeto };
      } else if (passo === this.passoLiquidacao && this.fluxoLiquidacao) {
        this.objetoAcao.passo6 = { ...objeto };
      } else if (passo === this.passoOrdenacao) {
        this.objetoAcao.passo7 = { ...objeto };
      } else if (passo === this.passoGrid) {
        this.objetoAcao.passo8 = { ...objeto };
      } else {
        this.objetoAcao[`passo${passo}`] = { ...objeto };
      }
    },
    voltar(passo) {
      this.e1 = passo;
    },
    validaConcluirPassos(passo, objeto) {
      if (this.id !== null) {
        if (this.isTipoAcaoAgrupadoraEmContrato) {
          const content = this.$t('message.tipo_acao_agrupadora_alterar_fluxo',
            { contratosTipoAcaoAgrupadora: this.contratosTipoAcaoAgrupadora });
          setTimeout(() => this.$refs.impossibilitadoAtivarInativarDialog
            .openWithParams(
              '',
              content,
              null,
            ));
        } else if (!this.isFluxoApenasPlanejamento) {
          this.validaHerancaDeConfiguracao(passo, objeto);
        } else {
          this.concluirPassos(passo, objeto);
        }
      } else {
        this.concluirPassos(passo, objeto);
      }
    },
    validaHerancaDeConfiguracao(passo, objeto) {
      const { id } = this;
      this.paramAcaoResources.verificarSeConfiguracaoHerdada({ id }, { id }).then((response) => {
        if (response.data && response.data.idsTiposAcaoDependentes) {
          const content = this.$t('message.tipo_acao_agrupadora_alterada',
              { tipoAcaoHerancaConfig: response.data.tiposAcaoDependentes }),
            idsTiposAcaoDependentes = response.data.idsTiposAcaoDependentes.split(',').map(Number);
          this.objetoAcao.idsConfiguracoesDependentes = idsTiposAcaoDependentes;
          setTimeout(() => this.$refs.confirmacaoHerancaConfigDialog
            .openWithParams(
              '',
              content,
              (ok) => {
                if (ok) {
                  this.concluirPassos(passo, objeto);
                }
              },
            ));
        } else {
          this.concluirPassos(passo, objeto);
        }
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificaSeTipoAcaoAgrupadoraEmContrato() {
      const { id } = this;
      this.paramAcaoResources.verificarSeTipoAcaoAgrupadoraEmContrato({ id }, { id })
        .then((response) => {
          if (response.data && response.data.isTipoAcaoAgrupadoraEmContrato) {
            this.isTipoAcaoAgrupadoraEmContrato = true;
            this.contratosTipoAcaoAgrupadora = response.data.contratosTipoAcaoAgrupadora;
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    concluirPassos(passo, objeto) {
      this.atualizarObjeto(passo, objeto);
      if (this.id) {
        this.paramAcaoResources.atualizar(this.objetoAcao).then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$router.push({ name: 'parametrizacao-acao' });
        })
          .catch((err) => {
            this.$error(this, err);
          });
      } else {
        this.paramAcaoResources.gravar(this.objetoAcao).then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$router.push({ name: 'parametrizacao-acao' });
        })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    } else if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.verificaSeTipoAcaoAgrupadoraEmContrato();
    }
  },
};
</script>
