<template lang="html">
  <div>
    <v-card-title primary-title class="card-header mt-4">
      {{ $tc('label.grid_filtros', 1) }}
      <v-spacer />
      <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.grid_filtros')}`" />
    </v-card-title>
    <v-row>
      <v-col cols="12" sm="6" class="pr-2">
        <v-data-table id="config_contrato_grid_filtros"
          :headers="headers"
          :items="parametrizacao.lista"
          hide-default-footer
          disable-pagination
          :items-per-page="-1"
          :no-data-text="$t('message.nenhum_registro')">
          <template v-slot:item.habilitaGrid="{ item }">
            <v-switch
                v-if="!item.desativaGrid"
                color="primary"
                id="config_contrato_passo6_grid"
                :disabled="item.desativaGrid || somenteLeitura"
                :label="`${$t('label.grid')}`"
                v-model="item.habilitaGrid" hide-details/>
              <v-label v-if="item.desativaGrid">{{ item.habilitaGrid ? $t('label.sim') : $t('label.nao') }}</v-label>
          </template>
          <template v-slot:item.habilitaFiltro="{ item }">
            <v-switch
                v-if="!item.desativaFiltro"
                color="primary"
                id="config_contrato_passo6_filtro"
                :disabled="item.desativaFiltro || somenteLeitura"
                :label="`${$tc('label.filtro', 1)}`"
                v-model="item.habilitaFiltro" hide-details/>
              <v-label v-if="item.desativaFiltro">{{ item.habilitaFiltro ? $t('label.sim') : $t('label.nao') }}</v-label>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="end" class="my-4">
      <v-btn id="config_contrato_passo6_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
      <v-btn id="config_contrato_passo6_botao_voltar" color="primary" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
      <v-btn id="config_contrato_passo6_botao_proximo" color="error" @click="concluir" :disabled="somenteLeitura" class="mr-3">{{ $t('label.concluir') }}</v-btn>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import Passo6 from './Passo6';
import TooltipAjuda from '../TooltipAjuda';
import { generateComputed } from '../../../common/functions/roles-computed-generator';

export default {
  name: 'ParametrizacaoContratoPasso6',
  data() {
    return {
      paramContratoResources: this.$api.parametrizacaoContrato(this.$resource),

      headers: [
        {
          text: this.$tc('label.campo', 1), width: '30%', value: 'nome', sortable: false,
        },
        {
          text: this.$tc('label.grid', 1), width: '30%', value: 'habilitaGrid', sortable: false,
        },
        {
          text: this.$tc('label.filtro', 1), width: '30%', value: 'habilitaFiltro', sortable: false,
        },
      ],
      parametrizacao: new Passo6(),
      lista: [],
    };
  },
  props: {
    id: Number,
    passo: Number,
  },
  components: {
    TooltipAjuda,
  },
  methods: {
    cancelar() {
      this.$emit('PARAMETRIZACAO_CONTRATO_CANCELAR');
    },
    concluir() {
      this.$emit('PARAMETRIZACAO_CONTRATO_CONCLUIR', this.passo, this.parametrizacao);
    },
    definirLista(listaOriginal) {
      listaOriginal.forEach((item) => {
        if (this.isDataHora(item) || this.isNumerico(item) || this.isTexto(item)) {
          item.habilitaFiltro = false;
          item.desativaFiltro = true;
        }
      });
    },
    isDataHora(item) {
      return item.tipoCampo && (item.tipoCampo === 'DATA' || item.tipoCampo === 'HORA');
    },
    isNumerico(item) {
      return item.tipoCampo && (item.tipoCampo === 'NUMERICO' || item.tipoCampo === 'PERCENTUAL');
    },
    isTexto(item) {
      return item.tipoCampo && item.tipoCampo === 'TEXTO';
    },
    obterValoresDoBanco(objetoBanco, lista) {
      objetoBanco.forEach((itemBanco) => {
        lista.forEach((item) => {
          if (item.label === itemBanco.label) {
            item.habilitaFiltro = item.habilitaFiltro
              ? item.habilitaFiltro : itemBanco.habilitaFiltro;
            item.habilitaGrid = item.habilitaGrid ? item.habilitaGrid : itemBanco.habilitaGrid;
          }
        });
      });
      return lista;
    },
    prepararPasso() {
      if (this.id) {
        this.parametrizacao.id = this.id;
        const params = { id: this.id };
        this.paramContratoResources.obterPasso6(params)
          .then((response) => {
            this.lista = Object.assign([], response.data.lista);
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    setLista(lista) {
      if (!lista) return;
      this.parametrizacao.lista = Object.assign([], lista);
      this.parametrizacao.lista = this.obterValoresDoBanco(this.lista, this.parametrizacao.lista);
      this.definirLista(this.parametrizacao.lista);
      this.$forceUpdate();
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_CONTRATO_VOLTAR', this.passo - 1);
    },
  },
  computed: {
    ...generateComputed('param_contrato', [
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
