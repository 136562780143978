export const selecionado = (filtro, min = 0) => {
  if (!filtro) {
    return false;
  }

  const values = Object.keys(filtro).filter((key) => {
    const value = filtro[key];
    return Array.isArray(value) ? value.length > 0 : value;
  });
  if (Array.isArray(values) && values.length > min) {
    return true;
  }

  return false;
};

export const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return value;
};
