<template lang="html">
  <div>
    <v-form ref="formLiquidacao" lazy-validation autocomplete="off">
      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.operacao', 2) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.liquidacao')}`" />
      </v-card-title>
      <v-row class="mx-4 mt-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_parcial"
            :label="`${$t('message.habilitar_liquidacao_parcial')}`"
            :disabled="somenteLeitura"
            v-model="parametrizacao.liquidacao.indLiquidacaoParcial" />
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.integracao_software_terceiro') }}
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_integracao_envio" :disabled="somenteLeitura"
            :label="`${modoVarejo ? $t('message.habilitar_integracao_envio_recebimento') : $t('message.habilitar_integracao_envio_pagamento')}`"
            v-model="parametrizacao.liquidacao.indIntegracaoEnvio" />
        </v-col>
      </v-row>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_integracao_retorno" :disabled="somenteLeitura"
            :label="`${modoVarejo ? $t('message.habilitar_integracao_retorno_recebimento') : $t('message.habilitar_integracao_retorno_pagamento')}`"
            v-model="parametrizacao.liquidacao.indIntegracaoRetorno" />
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{  `${$t('title.liquidacao')} *` }}
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_unica_liquidacao"
            :label="$t('label.unica_liquidacao')"
            :rules="[rules.liquidacaoNaoSelecionada]"
            @change="resetarValidacaoLiquidacao('indUnicaLiquidacao')"
            v-model="parametrizacao.liquidacao.indUnicaLiquidacao"
            :disabled="apuracaoParalelaLiquidacao || somenteLeitura" />
        </v-col>
      </v-row>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_multiplas_liquidacao"
            :label="$t('label.multiplas_liquidacoes')"
            :rules="[rules.liquidacaoNaoSelecionada]"
            @change="resetarValidacaoLiquidacao('indMultiplasLiquidacoes')"
            v-model="parametrizacao.liquidacao.indMultiplasLiquidacoes"
            :disabled="unicoPagador || apuracaoParalelaLiquidacao || somenteLeitura" />
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
            :label="$t('label.template')"
            v-if="parametrizacao.liquidacao.indMultiplasLiquidacoes"
            id="config_acao_apuracao_template"
            class="Form-text__invalid"
            name="config_acao_liquidacao_template"
            item-text="text"
            item-value="value"
            v-model="parametrizacao.liquidacao.templateCalculo"
            :items="templates"
            :rules="[rules.required]"
            required
            :disabled="somenteLeitura"
            clearable>
          </v-select>
        </v-col>
      </v-row>

      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_liquidacao_manual"
            :label="$t('label.liquidacao_manual')"
            v-model="parametrizacao.liquidacao.indLiquidacaoManual"
            :rules="[rules.liquidacaoNaoSelecionada]"
            @change="resetarValidacaoLiquidacao('indLiquidacaoManual')"
            :disabled="apuracaoParalelaLiquidacao || isFluxoApuracaoHabilitado || somenteLeitura" />
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="config_acao_apuracao_liq_template"
            name="config_acao_liquidacao_liq_template"
            :label="$tc('label.campo_customizado', 1)"
            class="Form-text__invalid"
            v-if="parametrizacao.liquidacao.indLiquidacaoManual"
            item-text="nomeLabel"
            item-value="idCampoDinamico"
            v-model="parametrizacao.camposDinamicosPagamentoManual"
            :items="listaCamposDinamicos"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            clearable
            chips
            multiple
            deletable-chips
            return-object
            :disabled="somenteLeitura"
            @click.native="buscarCamposDinamicos">
          </v-autocomplete>
        </v-col>
      </v-row>

      <div v-if="!fluxoComApuracao || apuracaoParalelaLiquidacao">
        <v-card-title primary-title class="card-header mt-4">
          {{  `${$t('title.criacao_pagamento')} *` }}
        </v-card-title>
        <v-row class="mx-4">
          <v-col id="criacao_pagamento" cols="12" sm="6" md="4">
            <v-radio-group
                v-model="parametrizacao.liquidacao.criacaoPagamento"
                :disabled="somenteLeitura"
                :rules="[rules.required]">
              <v-radio
                  id="criacao_pagamento_acao"
                  :label="$t('label.pagamento_a_partir_aprovacao_acao')"
                  :disabled="somenteLeitura"
                  value="APROVACAO_ACAO">
              </v-radio>
              <v-radio
                  id="criacao_pagamento_ini_vigencia"
                  :label="$t('label.pagamento_a_partir_inicio_vigencia')"
                  :disabled="somenteLeitura"
                  value="INICIO_VIGENCIA">
              </v-radio>
              <v-radio
                  id="criacao_pagamento_vigencia_concluida"
                  :label="$t('label.pagamento_a_partir_vigencia_concluida')"
                  :disabled="somenteLeitura"
                  value="VIGENCIA_CONCLUIDA">
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>

      <div>
        <v-card-title primary-title class="card-header mt-4">
          {{ $t('title.vencimento_liquidacao_acao') }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.vencimento_pagamento_acao')}`" />
        </v-card-title>
        <v-row class="mx-4 mt-4">
          <v-col cols="12">
            <v-switch
              id="config_acao_liquidacao_habilitar_vencimento"
              :label="`${$t('label.habilitar_vencimento_liquidacao_acao')}`"
              v-if="parametrizacao.liquidacaoVencimento.vencimento"
              v-model="parametrizacao.liquidacaoVencimento.vencimento.valor"
              :disabled="somenteLeitura"
              @change="alterandoIndVencimento()"/>
          </v-col>
        </v-row>
        <v-row dense class="mx-8 px-8" v-if="isVencimentoHabilitado">
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="config_acao_liquidacao_vencimento_tipo_verba"
              name="config_acao_liquidacao_vencimento_tipo_verba"
              v-model="parametrizacao.liquidacaoVencimento.tiposVerba"
              :items="listaTiposVerba"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nome"
              item-value="id"
              :label="`${$tc('label.tipo_verba', 1)}`"
              clearable
              multiple
              chips
              deletable-chips
              return-object
              :disabled="somenteLeitura"
              @click.native="buscarTiposVerba">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense class="mx-8 px-8" v-if="isVencimentoHabilitado">
          <v-col cols="12" sm="4">
            <input-number
              id="config_acao_liquidacao_vencimento_prazo"
              :label="`${$t('label.prazo_vencimento_dias')} *`"
              v-if="parametrizacao.liquidacaoVencimento.prazoVencimento"
              :disabled="somenteLeitura"
              v-model="parametrizacao.liquidacaoVencimento.prazoVencimento.valor"/>
          </v-col>
        </v-row>
        <v-row dense class="mx-8 px-8" v-if="isVencimentoHabilitado">
          <v-col cols="12" sm="12">
            <v-switch
              id="config_acao_liquidacao_vencimento_ind_notificacao"
              :label="`${$t('label.habilitar_notificacao_vencimento')}`"
              v-if="parametrizacao.liquidacaoVencimento.notificacaoVencimento"
              v-model="parametrizacao.liquidacaoVencimento.notificacaoVencimento.valor"
              :disabled="somenteLeitura"
              @change="alterandoIndNotificacaoVencimento()"/>
          </v-col>
        </v-row>
        <v-row dense class="mx-12 px-12" v-if="isVencimentoHabilitado">
          <v-col cols="12" sm="6" md="4" v-if="isNotificacaoVencimentoHabilitada">
            <input-number
              id="config_acao_liquidacao_notificacao_prazo"
              :label="`${$t('label.prazo_notificacao_dias')} *`"
              v-if="parametrizacao.liquidacaoVencimento.prazoNotificacao"
              :disabled="somenteLeitura"
              v-model="parametrizacao.liquidacaoVencimento.prazoNotificacao.valor"/>
          </v-col>
        </v-row>
      </div>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.status_criacao_pagamento') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.status_criacao_pagamento')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_status_criacao_pagamento"
            :label="`${modoVarejo ? $t('label.criar_recebimento_status_liquidado') : $t('label.criar_pagamento_status_liquidado')}`"
            :disabled="somenteLeitura || parametrizacao.liquidacao.indStatusCriacaoPendente"
            v-model="parametrizacao.liquidacao.indStatusCriacaoLiquidado" />
        </v-col>
      </v-row>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4" v-if="fluxoPermitidosPagamentoPendente">
          <v-switch
              id="config_acao_liquidacao_status_criacao_pagamento_pendente"
              :label="$t('label.criar_pagamento_status_pendente')"
              :disabled="somenteLeitura || parametrizacao.liquidacao.indStatusCriacaoLiquidado"
              v-model="parametrizacao.liquidacao.indStatusCriacaoPendente"
              @change="alterarIndStatusCriacaoPendente"/>
          <v-switch style="padding-left:5%"
                    id="justificativa_liquidacao_valor_pagamento"
                    :label="`${$t('message.habilitar_upload_evidencia')}`"
                    v-model="parametrizacao.liquidacao.indHabilitarEvidencia"
                    :disabled="!parametrizacao.liquidacao.indStatusCriacaoPendente || somenteLeitura"
                    @change="alterarIndHabilitarEvidencia"/>
          <v-switch style="padding-left:5%"
                    id="justificativa_liquidacao_valor_pagamento"
                    :label="`${$t('message.upload_evidencia_obrigatoria')}`"
                    v-model="parametrizacao.liquidacao.indEvidencia"
                    :disabled="!parametrizacao.liquidacao.indStatusCriacaoPendente ||
                    !parametrizacao.liquidacao.indHabilitarEvidencia || somenteLeitura" />
        </v-col>
      </v-row>
      <v-row justify="end" class="my-4">
        <v-btn id="config_acao_passo6_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo6_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo6_botao_proximo" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';
import InputNumber from '@/produto/shared-components/inputs/InputNumber';
import TooltipAjuda from '@/produto/spa/parametrizacao/TooltipAjuda';
import Passo6 from './Passo6';
import Passo6Vencimento from './Passo6Vencimento';

export default {
  name: 'ParametrizacaoAcaoPasso6',
  props: {
    id: Number,
    modoVarejo: Boolean,
    passo: Number,
    fluxo: String,
  },
  components: {
    TooltipAjuda,
    InputNumber,
  },
  data() {
    return {
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),
      acaoConfiguracaoCamposResources: this.$api.acaoConfiguracaoCampos(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      camposCustomizadosResources: this.$api.camposCustomizados(this.$resource),
      rules: {
        required: (value) => !!value || '',
        liquidacaoNaoSelecionada: () => (this.parametrizacao.liquidacao.indUnicaLiquidacao
          || this.parametrizacao.liquidacao.indMultiplasLiquidacoes
          || this.parametrizacao.liquidacao.indLiquidacaoManual)
          || '',

      },
      templates: [
        { text: this.$tc('label.rateio_proporcional_fornecedor', 1), value: 'PROPORCIONAL_POR_FORNECEDOR' },
        { text: this.$tc('label.rateio_proporcional_cliente', 1), value: 'PROPORCIONAL_POR_CLIENTE' },
      ],
      listaCamposDinamicos: [],
      parametrizacao: {
        liquidacao: new Passo6(this.id, ''),
        liquidacaoVencimento: new Passo6Vencimento(this.id),
      },
      liquidacaoBanco: [],
      tipoVerba: false,
      activeBtn: 0,
      unicoPagador: false,
      isFluxoApuracaoHabilitado: false,
      investimentos: [],
      listaTiposVerba: [],
      msgValidacao: this.$t('errors.campos_obrigatorios.nao_informados'),
    };
  },
  watch: {
    fluxoPermitidosPagamentoPendente(novoValor) {
      if (!novoValor) {
        this.parametrizacao.liquidacao.indStatusCriacaoPendente = false;
        this.parametrizacao.liquidacao.indHabilitarEvidencia = false;
        this.parametrizacao.liquidacao.indEvidencia = false;
      }
    },
  },
  computed: {
    fluxoComApuracao() {
      return !!this.fluxo && this.fluxo.includes('APURACAO');
    },
    apuracaoParalelaLiquidacao() {
      return !!this.fluxo && this.fluxo.split(',').includes('APURACAO_E_LIQUIDACAO');
    },
    isLiquidacaoManual() {
      return this.parametrizacao.liquidacao
          && this.parametrizacao.liquidacao.indLiquidacaoManual;
    },
    ...generateComputed('param_acao', [
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
    fluxoPermitidosPagamentoPendente() {
      return !!this.fluxo && (this.fluxo === 'PLANEJAMENTO,APURACAO,LIQUIDACAO' || this.fluxo === 'PLANEJAMENTO,LIQUIDACAO');
    },
    isVencimentoHabilitado() {
      return this.parametrizacao
        && this.parametrizacao.liquidacaoVencimento
        && this.parametrizacao.liquidacaoVencimento.vencimento
        && this.parametrizacao.liquidacaoVencimento.vencimento.valor;
    },
    isNotificacaoVencimentoHabilitada() {
      return this.parametrizacao
        && this.parametrizacao.liquidacaoVencimento
        && this.parametrizacao.liquidacaoVencimento.notificacaoVencimento
        && this.parametrizacao.liquidacaoVencimento.notificacaoVencimento.valor;
    },
  },
  methods: {
    setCamposUnicoPagador(campos) {
      this.unicoPagador = campos.length !== 0;
      if ((this.unicoPagador || this.apuracaoParalelaLiquidacao)
          && !this.isLiquidacaoManual) {
        this.parametrizacao.liquidacao.indUnicaLiquidacao = true;
        this.parametrizacao.liquidacao.indMultiplasLiquidacoes = false;
      }
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_ACAO_CANCELAR');
    },
    resetarValidacaoLiquidacao(campo) {
      if (campo === 'indLiquidacaoManual') {
        if (this.parametrizacao.liquidacao.indLiquidacaoManual) {
          this.parametrizacao.liquidacao.indUnicaLiquidacao = false;
          this.parametrizacao.liquidacao.indMultiplasLiquidacoes = false;
        }
        this.$emit('PARAMETRIZACAO_LIQ_MANUAL_ALTERADA', this.parametrizacao.liquidacao.indLiquidacaoManual);
      } else {
        if (this.parametrizacao.liquidacao.indUnicaLiquidacao
          || this.parametrizacao.liquidacao.indMultiplasLiquidacoes) {
          this.parametrizacao.liquidacao.indLiquidacaoManual = false;
        }
        if (this.parametrizacao.liquidacao.indMultiplasLiquidacoes) {
          this.$refs.formLiquidacao.resetValidation();
          this.$refs.formLiquidacao.validate();
        }
      }
    },
    validarPrazoVencimento() {
      if (this.isVencimentoHabilitado) {
        const prazoVencimento = this.parametrizacao.liquidacaoVencimento.prazoVencimento.valor;
        if (prazoVencimento <= 0) {
          this.msgValidacao = this.$t('errors.valor.maior_zero_inteiro');
        }
        return prazoVencimento && prazoVencimento > 0;
      }
      return true;
    },
    validarPrazoNotificacao() {
      if (this.isNotificacaoVencimentoHabilitada) {
        const prazoNotificacao = this.parametrizacao.liquidacaoVencimento.prazoNotificacao.valor;
        const prazoVencimento = this.parametrizacao.liquidacaoVencimento.prazoVencimento.valor;
        const isPrazoNotificacaoValido = prazoNotificacao && prazoNotificacao > 0
          && prazoNotificacao < prazoVencimento;
        if (prazoNotificacao <= 0) {
          this.msgValidacao = this.$t('errors.valor.maior_zero_inteiro');
        } else if (!isPrazoNotificacaoValido) {
          this.msgValidacao = this.$t('errors.vencimento.prazo_notificacao_maior_vencimento');
        }
        return isPrazoNotificacaoValido;
      }
      return true;
    },
    proximoPasso() {
      this.msgValidacao = this.$t('errors.campos_obrigatorios.nao_informados');
      if (this.$refs.formLiquidacao.validate()
          && this.validarPrazoVencimento() && this.validarPrazoNotificacao()) {
        if (!this.parametrizacao.liquidacao.indMultiplasLiquidacoes) {
          this.parametrizacao.liquidacao.templateCalculo = null;
        }
        this.$emit('PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
        this.$emit('PARAMETRIZACAO_ACAO_CONTINUAR', this.passo + 1, this.id);
      } else {
        this.$toast(this.msgValidacao);
      }
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_ACAO_VOLTAR', this.passo - 1);
    },
    prepararPasso() {
      if (this.id) {
        this.obter(this.id);
      }
      this.buscarCamposDinamicos();
      this.buscarTemplateMuiltiLiquidacao();
    },
    obter(id) {
      this.paramAcaoResources.obterPasso6({ id }, { id }).then((response) => {
        if (response.data && response.data.liquidacao) {
          this.liquidacaoBanco = response.data.liquidacao;
          this.parametrizacao.liquidacao = this.liquidacaoBanco;
          this.liquidacaoVencimentoBanco = response.data.liquidacaoVencimento;
          this.preencherDadosBase();
          this.parametrizacao.camposDinamicosPagamentoManual = response
            .data.camposDinamicosPagamentoManual;
          this.listaTiposVerba = [...this.parametrizacao.liquidacaoVencimento.tiposVerba || []];
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    preencherDadosBase() {
      this.parametrizacao.liquidacaoVencimento = new Passo6Vencimento(this.liquidacaoVencimentoBanco.id,
        this.liquidacaoVencimentoBanco.vencimento, this.liquidacaoVencimentoBanco.referenciaVencimento,
        this.liquidacaoVencimentoBanco.prazoVencimento,
        this.liquidacaoVencimentoBanco.notificacaoVencimento,
        this.liquidacaoVencimentoBanco.prazoNotificacao, this.liquidacaoVencimentoBanco.destinatarios,
        this.liquidacaoVencimentoBanco.tiposVerba);

      this.$forceUpdate();
    },
    setHabilitacaoFluxoApuracao(isFluxoApuracaoHabilitado = false) {
      this.isFluxoApuracaoHabilitado = isFluxoApuracaoHabilitado;
    },
    buscarCamposDinamicos() {
      return this.camposCustomizadosResources.listarPorEntidadeAcao()
        .then((response) => {
          this.listaCamposDinamicos = response
            .data.filter((item) => !item.isPreenchidoProcessoCustomizado);
          this.listaCamposDinamicos.forEach((l) => {
            l.nomeLabel = l.labelCampo.concat(' - ').concat(l.nomCampo);
            l.nome = l.nomCampo;
            l.desAtributos = l.desAtributos && l.desAtributos.value
              ? JSON.parse(l.desAtributos.value) : {};
            l.idCampoDinamico = l.id;
            l.label = l.labelCampo;
            l.obrigatorio = l.vlrObrigatorio;
            l.edicaoAprovado = false;
            delete l.id;
          });
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarTemplateMuiltiLiquidacao() {
      return this.acaoConfiguracaoCamposResources.buscarTemplateMuiltiLiquidacao()
        .then((response) => {
          this.templates = response.data.map((t) => ({ text: this.$tc(t.descricao, 1), value: t.template }));
        }, (err) => {
          this.$error(this, err);
        });
    },
    ajustarCamposDinamicos() {
      if (this.parametrizacao.camposDinamicosPagamentoManual
        && this.parametrizacao.camposDinamicosPagamentoManual.length) {
        this.parametrizacao.camposDinamicosPagamentoManual.forEach((c) => {
          delete c.id;
          this.listaCamposDinamicos.forEach((l) => {
            if (c.label === l.labelCampo) {
              c.nome = l.nomCampo;
              c.nomeLabel = l.labelCampo.concat(' - ').concat(l.nomCampo);
            }
          });
        });
      }
    },
    alterarIndStatusCriacaoPendente() {
      if (!this.parametrizacao.liquidacao.indStatusCriacaoPendente) {
        this.parametrizacao.liquidacao.indHabilitarEvidencia = false;
        this.parametrizacao.liquidacao.indEvidencia = false;
      }
    },
    alterarIndHabilitarEvidencia() {
      if (!this.parametrizacao.liquidacao.indHabilitarEvidencia) {
        this.parametrizacao.liquidacao.indEvidencia = false;
      }
    },
    alterandoIndVencimento() {
      if (!this.isVencimentoHabilitado) {
        this.parametrizacao.liquidacaoVencimento = new Passo6Vencimento(this.id);
      }
    },
    alterandoIndNotificacaoVencimento() {
      if (!this.parametrizacao.liquidacaoVencimento.notificacaoVencimento.valor) {
        this.parametrizacao.liquidacaoVencimento.prazoNotificacao.valor = 0;
        this.parametrizacao.liquidacaoVencimento.destinatarios = [];
      }
    },
    setInvestimentos(listaInvestimentos) {
      this.investimentos = listaInvestimentos;
      this.buscarTiposVerba();
    },
    buscarTiposVerba() {
      return this.orcamentoTipoVerbaResource.buscarAtivos()
        .then((res) => {
          this.listaTiposVerba = [];
          res.data.forEach((item) => {
            const tipoVerba = {
              id: item.id,
              nome: item.nome,
            };
            this.listaTiposVerba.push(tipoVerba);
          }, this);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    this.tipoVerba = false;
    const verba = new Passo6(this.id, '');
    this.parametrizacao.liquidacao = verba;
    this.parametrizacao.liquidacaoVencimento = new Passo6Vencimento(this.id);
    this.prepararPasso();
    this.buscarTiposVerba();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
