import { buscarHoldings } from '@/produto/common/resources/liquidacao/pagamento';
import { buscarAtivosSuzano } from '@/common/resources/orcamento-tipo-verba';

export default {
  data() {
    return {
      extensaoResource: this.$api.extensao(this.$resource),
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            filter: () => true,
            async: {
              fetchFunction: (autocomplete) => buscarHoldings({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'tipo_de_verba',
            nomCampoId: 'id_tipo_verba',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const focoRateio = 'CLIENTE';
                const composicaoRateio = 'CUSTOMERGROUP';
                return buscarAtivosSuzano({ autocomplete, focoRateio, composicaoRateio }, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nome',
            },
          },
        ],
      },
    };
  },
};
