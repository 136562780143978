import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'contrato-suzano');

const planejamentoContratoActions = {
  buscarContrato: { methods: 'GET', url: `${basePath}/carregamento/{idContrato}` },
  vericaUnicoTipo: { methods: 'GET', url: `${basePath}/listagem/tipo_contrato/unico` },
  solicitarContratoAcaoMassiva: { methods: 'POST', url: `${basePath}/workflow/solicitar_acoes` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoContratoActions);

export const buscarTiposContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipo_contrato', parametros).doGet();

export const buscarContratos = (parametros, resource) => resourceBuilder
  .buildGET(resource, basePath, 'listagem/tipo_contrato/contratos', parametros)
  .doGet();
